import React, { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import botBlueImage from '../HumBot.png';
import st from './BotAnswer.module.css';
import GraphDetails from "./GraphDetails";
import IconButton from '@mui/material/IconButton';
import { RiRefreshLine } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
import { exportToExcel } from 'react-json-to-excel';
import { cloneDeep } from 'lodash';
import { delay, formatHeader } from '../../../BaseModels/utility';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';
import { GlobalContext } from '../../store';


// when the entire message is empty! IMPLIES BACKEND ERROR
const EMPTY_MESSAGE = 'Please try again.'

const removeAllEmptyArray = (arr) => {
    if (arr === undefined || arr === null) return arr;
    if (arr.length > 0) {
        let newArr = JSON.parse(JSON.stringify(arr));
        let cols = Object.keys(newArr[0]);
        newArr.forEach((myObj) => {
            cols.forEach((col) => {
                if (myObj[col] === "") myObj[col] = null;
            });
        });
        return newArr;
    } else {
        return arr;
    }
}

const isWhitespace = (char) => {
    return /\s/.test(char);
}

const findCommonLength = (str1, str2) => {
    const strLen = Math.min(str1.length, str2.length);
    if (!strLen) return 0;
    if (str1.slice(0, strLen) === str2.slice(0, strLen)) return strLen;
    let i = 0;
    for (i = 0; i < strLen; i++)
        if (str1[i] !== str2[i]) return i;
    return strLen;
}

const roundByProbability = (number) => {
    if (number < 1) return 1;
    const randomValue = Math.random();
    const result = randomValue < number - Math.floor(number) ?
        Math.floor(number) : Math.ceil(number);
    return Math.max(result, 1);
}

const CustomAnchorElement = ({ href, children }) => (
    <a target="_blank" rel="noreferrer" href={href}>
        {children}
    </a>
)


const BotAnswer = ({ message, scrollChatToBottom }) => {
    const { setStoreData } = useContext(GlobalContext);
    const hasSomeData = message['answer'] || message['sqlQuery'] || message['tableData'];
    const targetText = message['answer'] || (hasSomeData ? '' : EMPTY_MESSAGE);
    const displayText = (message['animate'] ? message['displayText'] : targetText) || '';
    const displayTextFormatted = displayText.replaceAll('\\[', '$$').replaceAll('\\]', '$$');
    const sources = message['sources'];
    const query = message['sqlQuery'];
    const tableData = useMemo(() => removeAllEmptyArray(message['tableData']), [message['tableData']]);

    const animateRef = useRef({
        animate: message['animate'],
        pauseDuration: 70, // number of ms to wait before next word
        frameCount: 0, // for debugging
    });


    const updateTextData = async () => {
        if (!animateRef.current.animate) return;
        setStoreData((_state) => {
            const _aiChats = cloneDeep(_state.aiChats);
            const conversationObj = _aiChats.conversations[message['sessionId']];
            const messageIndex = conversationObj.messages.findIndex(x => x.messageId === message['messageId']);
            const messageObj = conversationObj.messages[messageIndex];
            const _hasSomeData = messageObj['answer'] || messageObj['sqlQuery'] || messageObj['tableData'];
            const _targetText = messageObj['answer'] ? messageObj['answer'] :
                (messageObj['completed'] ? (_hasSomeData ? '' : EMPTY_MESSAGE) : 'Thinking...');
            const _displayText = messageObj['displayText'] || '';
            if (_displayText !== _targetText) {
                let _tempString = _displayText.slice(0, findCommonLength(_displayText, _targetText));
                if (_tempString.length === _displayText.length) {
                    // how many words to add in a single animation frame -> text_length / <constant>
                    const _wordCount = roundByProbability(_targetText.length / 250);
                    for (let k = 0; k < _wordCount; k++) {
                        // add whole math section in a single animation frame
                        let mathSection = '';
                        const mathOpeningTag = _targetText.slice(_tempString.length, _tempString.length + 2);
                        if (mathOpeningTag === '\\[') {
                            /**
                             * Example:
                             * Inline math (not implemented)-> \(\frac{1}{2}\)
                             * Math block->  \[\frac{1}{2}\]
                             */
                            const endsAt = mathOpeningTag === '\\(' ? '\\)' : '\\]';
                            for (let i = _tempString.length; i < _targetText.length; i++) {
                                mathSection += _targetText[i];
                                if (_targetText.slice(i, i + 2) === endsAt) {
                                    mathSection += _targetText[i + 1];
                                    break;
                                }
                            }
                            if (!mathSection.endsWith(endsAt))
                                mathSection = '';
                            else
                                _tempString += mathSection;
                        }
                        if (!mathSection)   // find a non-empty word
                            for (let i = _tempString.length; i < _targetText.length; i++) {
                                if (isWhitespace(_targetText[i])) break;
                                _tempString += _targetText[i];
                            }
                        // find the whitespace after a word
                        for (let i = _tempString.length; i < _targetText.length; i++) {
                            if (!isWhitespace(_targetText[i])) break;
                            _tempString += _targetText[i];
                        }
                        if (_tempString.length === _targetText.length || mathSection) break;
                    }
                }
                animateRef.current.frameCount += 1;
                messageObj['displayText'] = _tempString;
                scrollChatToBottom();
            }
            else {
                if (messageObj['completed']) {
                    // console.log('Animation frames:', animateRef.current.frameCount);
                    animateRef.current.animate = false;
                    messageObj['animate'] = false;
                }
                else
                    return _state;
            }
            return { ..._state, aiChats: _aiChats };
        })
        await delay(animateRef.current.pauseDuration);
        updateTextData();
    }

    useEffect(() => {
        if (message['animate'])
            updateTextData();
    }, [message['animate']]);

    const [graphData, setGraphData] = useState(tableData);
    const [IsDownloadLoading, setIsDownloadLoading] = React.useState(false);

    const btnDownload_onClick = () => {
        setIsDownloadLoading(true);
        exportToExcel(tableData, 'Table-Data');
        setTimeout(() => {
            setIsDownloadLoading(false);
        }, 3000);
    }

    const extractDataFromGrid = useCallback(({ api }) => {
        const _rowData = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            _rowData.push(cloneDeep(node.data));
        });
        setGraphData(_rowData);
    }, [setGraphData])

    const emptyColDefs = useMemo(() => [
        {
            field: "Header",
            filter: false,
            editable: false,
            sortable: false,
            resizable: false
        },
    ], []);
    const emptyRows = useMemo(() => [], []);

    const tableColDefs = useMemo(() => {
        if (!Array.isArray(tableData) || !tableData.length) return [];
        return Object.keys(tableData[0])
            .map(k => ({ field: k, headerName: formatHeader(k) }));
    }, [tableData]);
    const tableDefaultColDef = useMemo(() => {
        if (!Array.isArray(tableData) || !tableData.length) return {};
        return Object.keys(tableData[0]).length > 2 ?
            {
                width: 170,
                filter: true,
                editable: false,
                sortable: true,
            } :
            {
                width: 170,
                filter: true,
                editable: false,
                sortable: true,
                flex: 1,
            };
    }, [tableData]);

    return (
        <div className={st.sharonMessage}>
            <div className={st.sharonImgContainer}>
                <img src={botBlueImage} alt="Bot" className={st.sharonMessageImage} />
            </div>
            <div className={st.sharonMessageItem}>
                <div className={st.sharonMessageContent}>
                    <Markdown
                        remarkPlugins={[remarkGfm, remarkMath]}
                        rehypePlugins={[rehypeMathjax]}
                        components={{
                            a: CustomAnchorElement,
                        }}
                    >
                        {displayTextFormatted}
                    </Markdown>
                </div>
                {/* {message['animate'] &&
                    <span className={st.blinking_cursor} >|</span>
                } */}
                {
                    !message['animate'] && query &&
                    <details>
                        <summary>Query</summary>
                        <div className={st.query}>
                            {query}
                        </div>
                    </details>
                }
                {
                    !message['animate'] && Array.isArray(tableData) &&
                    (
                        tableData.length > 0 ?
                            <>
                                <div className={st.tableDataContainer}>
                                    <div style={{ height: 71 + (tableData.length * 42 > 200 ? 200 : tableData.length * 42) + "px" }} className='ag-theme-quartz w-100'>
                                        <AgGridReact
                                            columnDefs={tableColDefs}
                                            rowData={tableData}
                                            rowHeight={42}
                                            defaultColDef={tableDefaultColDef}
                                            onFirstDataRendered={extractDataFromGrid}
                                            onSortChanged={extractDataFromGrid}
                                            onFilterChanged={extractDataFromGrid}
                                        />
                                    </div>
                                    <div className={st.tableDataActionButtonContainer}>
                                        {
                                            tableData.length > 0 &&
                                            <IconButton color="primary" onClick={btnDownload_onClick} disabled={IsDownloadLoading} size="small">
                                                {IsDownloadLoading ? <RiRefreshLine className='rotate-center' /> : <FaDownload />}
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                                <GraphDetails data={graphData} msg_id={message.messageId} />
                            </>
                            :
                            <div className={st.tableDataContainer}>
                                <div style={{ height: "100px" }} className='ag-theme-quartz w-100'>
                                    <AgGridReact
                                        rowData={emptyRows}
                                        columnDefs={emptyColDefs}
                                    />
                                </div>
                            </div>
                    )
                }
                {
                    !message['animate'] && Array.isArray(sources) &&
                    <div className={`d-flex align-items-center flex-wrap gap-1 ${st.chatSourceContainer}`}>
                        <span className={st.chatSourceTitle}>Sources:</span>
                        {
                            sources.map((item, idx) => {
                                return (
                                    item.url ?
                                        <a target="_blank" rel="noreferrer" href={item['url']} key={idx} className={st.chatSourceItem}>
                                            <span>{item.name}</span>
                                        </a> :
                                        <span key={idx} className={st.chatSourceItem}>{item.name}</span>
                                );
                            })
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default BotAnswer;
