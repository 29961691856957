import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl, getData, getDataP, MatomoSiteId, postData, postDataP, getRaw } from './APICAllModel';
import { getAIAssistantGraph } from './utility';
let BaseUrlAPI = baseUrl + "api/";
let Matomo_SiteId = MatomoSiteId;

function GeneratePublicKey() {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await getData("login/public_key");
            resolve({ status: true, message: "success", data: response.public_key });
        } catch (error) {
            resolve({ status: false, message: "failed to fetch public key", data: error });
        }
    });
}
function getTokenByPublicKey(public_key) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await getData("login/privatekey?public_key=" + public_key);
            resolve({ status: true, message: "success", data: response });
        } catch (error) {
            resolve({ status: false, message: "failed to fetch token", data: error });
        }
    });
}
function getTokenRefresh(public_key) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await getData("login/refresh?public_key=" + public_key);
            resolve({ status: true, message: "success", data: response });
        } catch (error) {
            resolve({ status: false, message: "failed to fetch refresh token data", data: error });
        }
    });
}
function getValidateToken(token) {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await getData("login/token/validate?token=" + token);
            resolve({ status: true, message: "success", data: response });
        } catch (error) {
            resolve({ status: false, message: "failed to fetch validate token", data: error });
        }
    });
}

async function IsTokenValid(token) {
    // let valid = false;
    // let response = await getData("login/token/validate?token=" + token);
    // if (response.hasOwnProperty('code')) {
    //     valid = false;
    // } else {
    //     valid = true;
    // }
    // return await valid;
    return await true;
}

async function IsTokenExpired(data) {
    let IsExpired = false;
    if (typeof data === 'object') {
        if (data.length === undefined) {
            if (data.hasOwnProperty('code') || data.hasOwnProperty('Code')) {
                if (data.code === '401' || data.Code === '401') {
                    IsExpired = true;
                    toast.error("Session Timeout, Please reload the page");
                    setTimeout(() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('public_key');
                        window.location.reload();
                    }, 1000 * 5);
                }
            }
        }
    }
    return await IsExpired;
}

const getBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = reject;
    });
};


function getUserAccessInfo() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/useraccessinfo?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch user data", data: error });
        }
    });
}

const getReportAccessInfo = async (abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                };
                let response = await getDataP(`reportaccess/reportaccess`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                if (response.status === 'failed')
                    return { status: false, message: response.message };
                return { status: true, data: response.data };
            } else {
                return { status: false, message: "session timeout", data: null };
            }
        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }
    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const setReportAccessInfo = async (requestBody) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                let response = await postData("reportaccess/userreportmappingupdate?token=" + token, requestBody);
                let _IsTokenExpired = await IsTokenExpired(response);
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                if (response.status === 'failed')
                    return { status: false, message: response.message };
                return { status: true, message: response.message };
            } else {
                return { status: false, message: "session timeout", data: null };
            }
        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }
    } catch (error) {
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getUserSearch = async (name, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token
                };
                let response = await getDataP(`fetch/search/${encodeURIComponent(name)}`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to fetch search data", data: null };
    }
}

function getSearchNameByUserId(UserId) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("ldap/user?user=" + UserId + "&token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch user data by name", data: error });
        }
    });
}

function getUserInfo() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("login/user?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch user data", data: error });
        }
    });
}

function getUserDataByUser(user) {
    return new Promise(async (resolve, reject) => {
        try {
            if (await IsTokenValid(user.token)) {
                localStorage.setItem('token', user.token);
                let userData = {};
                let domain = window.location.origin + "/";
                userData.user = user;
                userData.user.user_id = user.email.replace("@amgen.com", "");
                userData.user.firstChar = user.given_name.split(' ').map(word => word[0]);
                userData.user.firstChar = Array.from(userData.user.firstChar).reverse().join("");
                userData.user.MatomoSiteId = MatomoSiteId;
                let response_getTabInfo = await getTabInfo();
                let _IsTokenExpired_getTabInfo = await IsTokenExpired(response_getTabInfo);
                let AllUrls = [];
                if (_IsTokenExpired_getTabInfo) {
                    userData.AllMenu = [];
                    userData.AllDashboardData = [];
                } else {
                    if (response_getTabInfo.status) {
                        let AllNavigationData = response_getTabInfo.data;
                        AllNavigationData = AllNavigationData.filter(x => x.page_name !== "Home");
                        let AllMenu = [];
                        let AllDashboardData = [];
                        AllNavigationData.forEach(pages => {
                            let obgPage = {
                                "page_id": pages.page_id,
                                "pageName": pages.page_name,
                                "home_flag": pages.home_flag,
                                "slug": pages.slug,
                                "page_icon": pages.icon,
                                "data": []
                            }
                            AllUrls.push({ name: pages.page_name, url: domain + "page/" + pages.slug });
                            AllUrls.push({ name: "Admin/" + pages.page_name, url: domain + "backend/pageList/" + pages.page_id });
                            pages.section.forEach(sections => {
                                let obgSection = {
                                    "section_id": sections.section_id,
                                    "page_id": sections.page_id,
                                    "sectionName": sections.section_name,
                                    "icon": sections.icon,
                                    "seq": sections.seq,
                                    "data": []
                                }
                                sections.report.forEach(reports => {
                                    let hashtags = "";
                                    // hashtags += ((pages.page_name).match(/\b(\w)/g)).join('')+","
                                    // hashtags += (sections.section_name.match(/\b(\w)/g)).join('')+","
                                    hashtags += ((reports?.title?.replace(/[^a-zA-Z0-9 ]/g, "")).match(/\b(\w)/g)).join('');

                                    let obgDashboard = {
                                        "dashboardid": reports.report_id,
                                        "page_id": reports.page_id,
                                        "page": pages.page_name,
                                        "section_id": reports.section_id,
                                        "section": sections.section_name,
                                        "title": reports.title,
                                        "slug": reports.slug,
                                        "description": reports.description,
                                        "type": reports.report_type,
                                        "url": reports.url,
                                        "shared_url": reports.shared_url,
                                        "priority_feature": false,
                                        "height": reports.report_height,
                                        "top_tool_bar_visible": reports.top_tool_bar,
                                        "bottom_tool_bar_visible": reports.bottom_tool_bar_visible,
                                        "share_tableau_viz_visible": reports.share_tableau_viz_visible,
                                        "custom_views_visible": reports.custom_views_visible,
                                        "download_visible": reports.download_visible,
                                        "download_csv_visible": reports.download_csv_visible,
                                        "download_image_visible": reports.download_image_visible,
                                        "download_pdf_visible": reports.download_pdf_visible,
                                        "download_excel_visible": reports.download_excel_visible,
                                        "download_ppt_visible": reports.download_ppt_visible,
                                        "report_builder_visible": reports.report_builder_visible,
                                        "navigation_order": reports.seq,
                                        "report_image": reports.report_image,
                                        "report_video": reports.report_video,
                                        "tutorial_text": reports.tutorial_text,
                                        "hashtags": hashtags
                                    }
                                    obgSection.data.push(obgDashboard);
                                    AllDashboardData.push(obgDashboard)
                                    AllUrls.push({ name: reports.title, url: domain + "dashboard/" + reports.slug });
                                    AllUrls.push({ name: "Admin/" + reports.title, url: domain + "backend/dashboards/" + reports.report_id });
                                });

                                obgPage.data.push(obgSection);
                            });
                            AllMenu.push(obgPage);
                        });
                        AllUrls.push({ name: "E2EViz Home", url: domain });
                        AllUrls.push({ name: "Page Not Found", url: domain + "404" });
                        AllUrls.push({ name: "Authentication (User Not Found)", url: domain + "error" });
                        AllUrls.push({ name: "Report Builder Export", url: domain + "ReportBuilderDataList" });
                        AllUrls.push({ name: "Admin Home", url: domain + "backend" });
                        AllUrls.push({ name: "Reports", url: domain + "backend/dashboards", });
                        AllUrls.push({ name: "Navigation", url: domain + "backend/navigationOrder", });
                        AllUrls.push({ name: "News", url: domain + "backend/news", });
                        AllUrls.push({ name: "Admin Access", url: domain + "backend/adminUser", });
                        AllUrls.push({ name: "Admin/User Access", url: domain + "backend/pageList" });
                        AllUrls.push({ name: "Admin/Traffic View", url: domain + "backend/trafficView" });
                        AllUrls.push({ name: "Admin/Reports/New", url: domain + "backend/dashboards/0" });
                        AllUrls.push({ name: "Admin/Report Access", url: domain + "backend/reportAccess" });

                        userData.AllMenu = AllMenu;
                        userData.AllDashboardData = AllDashboardData;
                    } else {
                        userData.AllMenu = [];
                        userData.AllDashboardData = [];
                    }
                }

                let response_getNewsInfo = await getNewsInfo();
                let _IsTokenExpired_getNewsInfo = await IsTokenExpired(response_getNewsInfo);
                if (_IsTokenExpired_getNewsInfo) {
                    userData.AllNews = [];
                } else {
                    if (response_getNewsInfo.status) {
                        let _AllNewsResponse = response_getNewsInfo.data;
                        userData.AllNews = _AllNewsResponse.filter((news) => news.public === true);
                        _AllNewsResponse.forEach(news => {
                            AllUrls.push({ name: "Announcement/" + news.title, url: domain + "newsletter/" + news.news_id });
                            AllUrls.push({ name: "Admin/Announcement/" + news.title, url: domain + "backend/news/" + news.news_id });
                        });
                    } else {
                        userData.AllNews = [];
                    }
                }
                AllUrls.push({ name: "Announcement", url: domain + "newsletter/0" });
                AllUrls.push({ name: "Admin/Announcement/New", url: domain + "backend/news/0" });


                let response_getFavorite = await getFavorite();
                let _IsTokenExpired_getFavorite = await IsTokenExpired(response_getFavorite);
                if (_IsTokenExpired_getFavorite) {
                    userData.AllFavorites = [];
                } else {
                    if (response_getFavorite.status) {
                        let FavoritesData = response_getFavorite.data;
                        let AllFavorites = [];
                        FavoritesData.forEach(reports => {
                            let obgDashboard = {
                                "dashboardid": reports.report_id,
                                "page": reports.page_id,
                                "section": reports.section_id,
                                "title": reports.title,
                                "slug": reports.slug,
                                "description": reports.description,
                                "type": reports.report_type,
                                "url": reports.url,
                                "shared_url": reports.shared_url,
                                "priority_feature": false,
                                "height": reports.report_height,
                                "top_tool_bar_visible": reports.top_tool_bar,
                                "bottom_tool_bar_visible": reports.bottom_tool_bar_visible,
                                "share_tableau_viz_visible": reports.share_tableau_viz_visible,
                                "custom_views_visible": reports.custom_views_visible,
                                "download_visible": reports.download_visible,
                                "download_csv_visible": reports.download_csv_visible,
                                "download_image_visible": reports.download_image_visible,
                                "download_pdf_visible": reports.download_pdf_visible,
                                "download_excel_visible": reports.download_excel_visible,
                                "download_ppt_visible": reports.download_ppt_visible,
                                "report_builder_visible": reports.report_builder_visible,
                                "navigation_order": reports.seq,
                            }
                            AllFavorites.push(obgDashboard);
                        });
                        userData.AllFavorites = AllFavorites;
                    } else {
                        userData.AllFavorites = [];
                    }
                }
                userData.AllUrls = AllUrls;

                resolve({ status: true, message: "success", data: userData });
            } else {
                resolve({ status: false, message: "session timeout", data: {} });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to load navigation data", data: error });
        }
    });
}

function getTabInfo() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("tab/tabinfo?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch navigation data", data: error });
        }
    });
}


const getAllReports = async () => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    try {
        const response = await getDataP(`fetch/reports`, { queryParams });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


/**
 * 
 * @param {number} report_id 
 * @param {boolean} publish 
 * @returns 
 */
const updateReportPublish = async (report_id, publish) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    const payload = { report_id, publish };
    try {
        const response = await postDataP(`insert/update-report-publish`, { queryParams, data: payload });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


/**
 * 
 * @param {number} report_id 
 * @param {boolean} default_access 
 * @returns 
 */
const updateReportDefaultAccess = async (report_id, default_access) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    const payload = { report_id, default_access };
    try {
        const response = await postDataP(`insert/update-report-default-access`, { queryParams, data: payload });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


function getReportUsage(topNumber) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/usage/" + topNumber + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch report usage data", data: error });
        }
    });
}

function getPageDesign(page_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/pagedesign/" + page_id + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch page design data", data: error });
        }
    });
}

function getDescription(div_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/description/" + div_id + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else {
                        if (response.length > 0) {
                            resolve({ status: true, message: "success", data: response[0] });
                        } else {
                            resolve({ status: false, message: "failed to fetch description data", data: {} });
                        }
                    }
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch description data", data: error });
        }
    });
}

function getCarosel(div_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/carosel/" + div_id + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch carosel data", data: error });
        }
    });
}

function getFavorite() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/userfav?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch favorite data", data: error });
        }
    });
}

function getHighlightedAdminFeature(page_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/highlightedadminfeature/" + page_id + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch admin feature data", data: error });
        }
    });
}

function getReportBuilderImages() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("images/getreportimage?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch report builder data", data: error });
        }
    });
}

function getReportBuilder_PPT_PDF(formatType) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    const queryParams = {
                        token,
                        format_type: formatType,
                    };
                    let response = await getRaw("images/reportimage_convert_ppt", { queryParams });
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: null });
                    else {
                        const blobData = await response.blob();
                        resolve({
                            status: true,
                            message: "success",
                            data: blobData,
                            type: response.headers['content-type'],
                            filename: response.headers['content-disposition'].match(/filename="([^"]+)"/)[1]
                        });
                    }
                } else {
                    resolve({ status: false, message: "session timeout", data: null });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch report builder ppt/pdf data", data: error });
        }
    });
}

const downloadReportBuilderDocument = async (formatType) => {
    const token = localStorage.getItem('token');
    if (token) {
        if (await IsTokenValid(token)) {
            const url = `${BaseUrlAPI}images/reportimage_convert_ppt?format_type=${formatType}&token=${token}`;
            const el = document.createElement('a');
            el.href = url;
            el.click();
            toast.success("Download will begin soon");
        } else {
            toast.error("Session timeout");
        }
    } else {
        toast.error("Token not found");
    }
}

function getCommentInfo(report_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/commentinfo/" + report_id + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch comment data", data: error });
        }
    });
}

function getTutorialVideoViewer(report_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/tutorialvideoviewer/" + report_id + "?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch comment data", data: error });
        }
    });
}

function getNewsInfo() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/newsinfo?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch favorite data", data: error });
        }
    });
}






function postUserAccessUpdate(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/useraccessupdate?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save user data", data: error });
        }
    });
}

function postUserAccessAdminUpdate(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/useraccessadminupdate?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save user data", data: error });
        }
    });
}

function postPageUpdate(page_id, paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData('update/pageupdate?page_id=' + page_id + '&token=' + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save page data", data: error });
        }
    });
}
function postPageSequence(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/pagesequpdate?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save sequence data", data: error });
        }
    });
}
function postSectionUpdate(section_id, paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData('update/sectionupdate?section_id=' + section_id + '&token=' + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save section data", data: error });
        }
    });
}
function postSectionSequence(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/sectionsequpdate?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to fetch section sequence data", data: error });
        }
    });
}
function postPageDesignSequence(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/pagedesignsequpdate?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save page design sequence data", data: error });
        }
    });
}

function postPageDesignActivate(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/pagedesignactivate?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save page design activate data", data: error });
        }
    });
}
function postPageDesignUpdate(div_id, paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/pagedesignupdate?div_id=" + div_id + "&token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save page design data", data: error });
        }
    });
}
function postDescriptionUpdate(description_id, paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/descriptionupdate?description_id=" + description_id + "&token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save description data", data: error });
        }
    });
}
function postReportUpdate(report_id, paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/reportupdate?report_id=" + report_id + "&token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save report data", data: error });
        }
    });
}
function postReportSequenceUpdate(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/modifyrequest?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to save page sequence data", data: error });
        }
    });
}
function postNewsUpdate(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/newsupdate?news_id=" + paramsData.news_id + "&token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save news data", data: error });
        }
    });
}




function postUserAccessDelete(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/useraccessdelete/" + id + "?token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }
        } catch (error) {
            resolve({ status: false, message: "failed to delete access data", data: error });
        }
    });
}
function postPageInfoDelete(page_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/pageinfo/" + page_id + "?token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete page info data", data: error });
        }
    });
}
function postSectionInfoDelete(section_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/sectioninfo/" + section_id + "?token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete section data", data: error });
        }
    });
}
function postCaroselInfoDelete(carosel_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/caroselinfo/" + carosel_id + "?token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else {
                        if (response.status === "failed") {
                            resolve({ status: false, message: "failed", data: response.message });
                        } else {
                            resolve({ status: true, message: "success", data: response });
                        }
                    }
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete carosel data", data: error });
        }
    });
}
function postFavReportDelete(report_id, user_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/favreport?report_id=" + report_id + "&user=" + user_id + "&token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete fav report data", data: error });
        }
    });
}
function deleteReportBuilderImages(params) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("images/deletereportimage?token=" + token, params);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete fav report data", data: error });
        }
    });
}
function postNewsInfoDelete(news_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/newsinfo/" + news_id + "?token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else {
                        if (response.status === "failed") {
                            resolve({ status: false, message: "failed", data: response.message });
                        } else {
                            resolve({ status: true, message: "success", data: response.message });
                        }
                    }
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete news data", data: error });
        }
    });
}
function postReportInfoDelete(report_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/reportinfo/" + report_id + "?token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete report data", data: error });
        }
    });
}



function postUserAccessInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/useraccessinsert?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch page design info data", data: error });
        }
    });
}
function postPageInformationInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/pageinformation?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch page design info data", data: error });
        }
    });
}
function postSectioninfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/sectioninfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch page design info data", data: error });
        }
    });
}
function postPageDesignInfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/pagedesigninfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch page design info data", data: error });
        }
    });
}
function postDescriptionInfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/descriptioninfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save description data", data: error });
        }
    });
}
function postCaroselInfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/caroselinfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save carosel data", data: error });
        }
    });
}
function postCommentInformationInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/commentinformation?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save comment data", data: error });
        }
    });
}
function postTutorialVideoViewerInsert(report_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData('insert/tutorialvideoviewer?report_id=' + report_id + '&token=' + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save video data", data: error });
        }
    });
}
function postFavReportInsert(report_id, user_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/favreport?report_id=" + report_id + "&user=" + user_id + "&token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save fav report data", data: error });
        }
    });
}
function postUsageReportInsert(report_id, user_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/usagereport?report_id=" + report_id + "&user=" + user_id + "&token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch usage report data", data: error });
        }
    });
}
function uploadReportBuilderImageTableau(custom_view_link) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("images/uploadreportimage?custom_view_link=" + custom_view_link + "&token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to upload tableau custom view report", data: error });
        }
    });
}
function uploadReportBuilderImageCustom(file, reportURL) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!file || !reportURL) {
                reject('Invalid data!');
                return;
            }
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('report_url', reportURL);
                    const _res = await fetch(`${BaseUrlAPI}images/upload-custom-report?token=${token}`,
                        {
                            method: 'POST',
                            body: formData
                        }
                    );
                    const response = await _res.json();
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to upload custom report image", data: error });
        }
    });
}
function postReportInformationInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/reportinformation?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save report data", data: error });
        }
    });
}
function postNewsInfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/newsinfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save news data", data: error });
        }
    });
}






function ImageUpload(file) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    const formData = new FormData();
                    formData.append('file', file);
                    const results = await fetch(BaseUrlAPI + "images/uploadimage?token=" + token,
                        {
                            method: 'POST',
                            body: formData
                        }).then(r => r.json());
                    let _IsTokenExpired = await IsTokenExpired(results);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else {
                        if (results.status === 200) {
                            resolve({ status: true, message: "success", data: results.link });
                        } else {
                            resolve({ status: false, message: "error while uploading image", data: results.message });
                        }
                    }
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save image data", data: error });
        }
    });
}
function VideoUpload(file) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    const formData = new FormData();
                    formData.append('video', file);
                    const results = await fetch(BaseUrlAPI + "videos/uploadvideo?token=" + token,
                        {
                            method: 'POST',
                            body: formData
                        }).then(r => r.json());
                    let _IsTokenExpired = await IsTokenExpired(results);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else {
                        if (results.status === 200) {
                            resolve({ status: true, message: "success", data: results.link });
                        } else {
                            resolve({ status: false, message: "error while uploading image", data: results.message });
                        }
                    }
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to upload video data", data: error });
        }
    });
}






function getDRT_UserProject() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/drtinfo?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch user projects data", data: error });
        }
    });
}
function getDRT_ProjectList() {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await getData("fetch/drtprojects?token=" + token);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: [] });
                    else resolve({ status: true, message: "success", data: response });
                } else {
                    resolve({ status: false, message: "session timeout", data: [] });
                }
            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to fetch user projects data", data: error });
        }
    });
}
function postDRT_ProjectInfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/projinfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save project data", data: error });
        }
    });
}
function postDRT_ProjectInfoUpdate(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("update/projinfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save project data", data: error });
        }
    });
}
function postDRT_ProjectInfoDelete(project_id) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("delete/drtprojinfo?project_id=" + project_id + "&token=" + token, {});
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to delete project data", data: error });
        }
    });
}
function postDRT_UserInfoInsert(paramsData) {
    return new Promise(async (resolve, reject) => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                if (await IsTokenValid(token)) {
                    let response = await postData("insert/projaccessinfo?token=" + token, paramsData);
                    let _IsTokenExpired = await IsTokenExpired(response);
                    if (_IsTokenExpired) resolve({ status: false, message: "session timeout", data: {} });
                    else resolve({ status: true, message: "success", data: response.message });
                } else {
                    resolve({ status: false, message: "session timeout", data: {} });
                }

            } else {
                toast.error("Token not found");
                resolve({ status: false, message: "Token not found", data: "Token not found" });
            }

        } catch (error) {
            resolve({ status: false, message: "failed to save user data", data: error });
        }
    });
}

const getMatomoRefreshDate = async (abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token
                };
                let response = await getDataP(`matomo/matomorefreshdate`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get refresh date", data: null };
    }
}

const getMatomoTopUsers = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/top10users`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMatomoPauseUse = async (role, day, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    day,
                };
                let response = await getDataP(`matomo/pauseuse`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getPageWiseUsage = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/pagewiseusage`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMatomoHourlyTraffic = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/hourlytraffic`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMatomoActiveUser = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/activeuser`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMatomoDeviceStat = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/devicestat`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMatomoTopReport = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/topreport`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMatomoDeviceKPI = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/devicekpi`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getUserSpecificReportVisit = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/userspecificreportvisit`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const getMonthlyTraffic6Month = async (start_date, end_date, role, abortSignal) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    role,
                    start_date,
                    end_date,
                };
                let response = await getDataP(`matomo/monthlytraffic6month`, { queryParams, signal: abortSignal });
                let _IsTokenExpired = await IsTokenExpired(response);
                if (abortSignal.aborted)
                    return { status: false, message: "aborted", data: null };
                if (_IsTokenExpired)
                    return { status: false, message: "session timeout", data: null };
                else
                    return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }

        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }

    } catch (error) {
        if (abortSignal.aborted)
            return { status: false, message: "aborted", data: null };
        return { status: false, message: "failed to get top users", data: null };
    }
}

const AIDashboardSave = async (_data) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token
    }
    try {
        const response = await postDataP(`openai/save_report`, { queryParams, data: _data });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}
const AIDashboardUpdate = async (_data) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token
    }
    try {
        const response = await postDataP(`openai/update_report`, { queryParams, data: _data });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}

const AIDashboardDeactivateReport = async (unique_id) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
        unique_id,
    }
    try {
        const response = await getDataP(`openai/deactivate_report`, { queryParams });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}

const getAIDashboard = async () => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                };
                let response = await getDataP(`openai/dashboard`, { queryParams });
                return { status: true, message: "success", data: response };
            } else {
                return { status: false, message: "session timeout", data: null };
            }
        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }
    } catch (error) {
        return { status: false, message: "failed to get API response", data: null };
    }
}

const getAIDashboardData = async (conference_id, message_id, unique_id) => {
    try {
        const token = localStorage.getItem('token');
        if (token) {
            if (await IsTokenValid(token)) {
                const queryParams = {
                    token,
                    conference_id,
                    message_id,
                    unique_id
                };
                let response = await getDataP(`openai/dashboard_data`, { queryParams });
                if (response?.data?.status === "failed") {
                    return { status: false, message: response?.data?.message, data: response?.data?.message };
                } else {
                    return { status: true, message: "success", data: response?.data };
                }
            } else {
                return { status: false, message: "session timeout", data: null };
            }
        } else {
            toast.error("Token not found");
            return { status: false, message: "Token not found", data: null };
        }
    } catch (error) {
        return { status: false, message: "failed to get API response", data: null };
    }
}


const openAIRefresh = async () => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found", data: null };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout", data: null };
    const queryParams = {
        token
    }
    try {
        const response = await postDataP(`ai-assistant/keep-alive`, { queryParams });
        return { status: true, message: "success", data: response.data };
    }
    catch { }
    return { status: false, message: 'API failed', data: null };
}


const postAIAssistant = async (query, classify_engine, runtime_engine, session_id) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token
    }
    const payload = {
        query, classify_engine, runtime_engine, session_id
    }
    try {
        const response = await postDataP(`ai-assistant/query`, { queryParams, data: payload });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


const getAIAssistantChatById = async (message_id) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
        message_id
    }
    try {
        const response = await getDataP(`ai-assistant/query`, { queryParams });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


const getAIAssistantChats = async () => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    try {
        const response = await getDataP(`ai-assistant/history`, { queryParams });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


const getAIAssistantChatsAdmin = async () => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    try {
        const response = await getDataP(`ai-assistant/history-feedback`, { queryParams });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


const updateAIAssistantFeedback = async (message_id, feedback_flag, feedback_msg) => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token
    }
    const payload = {
        message_id, feedback_flag, feedback_msg
    }
    try {
        const response = await postDataP(`ai-assistant/update-feedback`, { queryParams, data: payload });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


const shareAIAssistantChats = async (recipients, message_ids, subject = '', body = '') => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token
    }
    const image_data = {};
    message_ids.forEach(id => {
        const _image = getAIAssistantGraph(id);
        if (_image)
            image_data[id] = _image;
    })
    const payload = {
        recipients, message_ids, image_data, subject, body
    }
    try {
        const response = await postDataP(`ai-assistant/share-chats`, { queryParams, data: payload });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


const getAIAssistantSamplePrompts = async () => {
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    try {
        const response = await getDataP(`ai-assistant/sample-queries`, { queryParams });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


/**
 * 
 * @param {Array<string>} queries 
 * @returns 
 */
const updateAIAssistantSamplePrompts = async (queries) => {
    if (!Array.isArray(queries) || queries.some(x => !x || typeof (x) !== 'string'))
        return { status: false, message: "Invalid API inputs" };
    const token = localStorage.getItem('token');
    if (!token)
        return { status: false, message: "Token not found" };
    if (!(await IsTokenValid(token)))
        return { status: false, message: "Session timeout" };
    const queryParams = {
        token,
    }
    try {
        const response = await postDataP(`ai-assistant/sample-queries`, { queryParams, data: { queries } });
        return { status: true, message: 'success', data: response };
    }
    catch { }
    return { status: false, message: 'API failed' };
}


export {
    baseUrl, BaseUrlAPI, deleteReportBuilderImages, GeneratePublicKey, getBase64, getCarosel, getCommentInfo, getDescription, getDRT_ProjectList, getDRT_UserProject, getFavorite, getHighlightedAdminFeature, getMatomoActiveUser, getMatomoDeviceKPI, getMatomoDeviceStat, getMatomoHourlyTraffic, getMatomoPauseUse, getMatomoRefreshDate, getMatomoTopReport, getMatomoTopUsers, getMonthlyTraffic6Month, getNewsInfo, getPageDesign, getPageWiseUsage, getReportAccessInfo, getReportBuilder_PPT_PDF, getReportBuilderImages, getReportUsage, getSearchNameByUserId, getTabInfo, getAllReports, updateReportPublish, updateReportDefaultAccess, getTokenByPublicKey, getTokenRefresh, getTutorialVideoViewer, getUserAccessInfo, getUserDataByUser, getUserInfo, getUserSearch, getUserSpecificReportVisit, getValidateToken, ImageUpload, IsTokenExpired, IsTokenValid, Matomo_SiteId, postCaroselInfoDelete, postCaroselInfoInsert, postCommentInformationInsert, postDescriptionInfoInsert, postDescriptionUpdate, postDRT_ProjectInfoDelete, postDRT_ProjectInfoInsert,
    postDRT_ProjectInfoUpdate, postDRT_UserInfoInsert, postFavReportDelete, postFavReportInsert, postNewsInfoDelete, postNewsInfoInsert, postNewsUpdate, postPageDesignActivate, postPageDesignInfoInsert, postPageDesignSequence, postPageDesignUpdate, postPageInfoDelete, postPageInformationInsert, postPageSequence, postPageUpdate, postReportInfoDelete, postReportInformationInsert, postReportSequenceUpdate, postReportUpdate, postSectionInfoDelete, postSectioninfoInsert, postSectionSequence, postSectionUpdate, postTutorialVideoViewerInsert, postUsageReportInsert, postUserAccessAdminUpdate, postUserAccessDelete, postUserAccessInsert, postUserAccessUpdate, setReportAccessInfo, uploadReportBuilderImageTableau, uploadReportBuilderImageCustom, VideoUpload, downloadReportBuilderDocument,
    AIDashboardSave, AIDashboardUpdate, getAIDashboard, getAIDashboardData, AIDashboardDeactivateReport, openAIRefresh,
    postAIAssistant, getAIAssistantChatById, getAIAssistantChats, getAIAssistantChatsAdmin, updateAIAssistantFeedback, shareAIAssistantChats, getAIAssistantSamplePrompts, updateAIAssistantSamplePrompts
};