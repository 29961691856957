import React, { useContext } from 'react';
import TopMenu from '../../TopMenu/TopMenu';
import DashboardDetail from './DashboardDetail/DashboardDetail';
import { GlobalContext } from '../../store';
import st from './AIDashboard.module.css';

const AIDashboard = () => {
    const { storeData } = useContext(GlobalContext);
    return (
        <>
            <TopMenu />
            <div className="mainContainer">
                <br />
                <div className={'row'}>
                    {
                        storeData.aiDashboardReports.map((item) => {
                            return (
                                <div key={item.unique_id} className={`${'col-sm-12 col-lg-6 p-2'}`}>
                                    <DashboardDetail detail={item} />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </>
    );
};

export default AIDashboard;