import CampaignIcon from '@mui/icons-material/Campaign';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VideocamIcon from '@mui/icons-material/Videocam';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ModalPopup from '../../../../CustomElements/ModalPopup/ModalPopup';
import SearchBoxFullInput from '../../DashboardSearchBox/SearchBoxFullInput/SearchBoxFullInput';
import adminIcon from './../../../../../images/admin.png';
import logo from './../../../../../images/e2eviz-header-image.png';
import playVideoImage from './../../../../../images/playVideoImage.png';
import tutorial_video from './../../../../../videos/Platform_Overview.mp4';
import { BaseUrlAPI } from './../../../../BaseModels/MasterData';
import { MdDashboardCustomize } from "react-icons/md";
import st from './DesktopNav12.module.css';
import { GlobalContext } from '../../../store';

function DesktopNav12(props) {
    const { storeData } = useContext(GlobalContext);

    const ref = useRef(null)
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [UserDetailData, setUserDetailData] = useState({});
    const [AllDashboardData, setAllDashboardData] = useState([]);
    const [MenuData, setMenuData] = useState([]);
    const [IsSectionsVisible, setIsSectionsVisible] = useState(false);
    const [SectionData, setSectionData] = useState([]);
    const [PageName, setPageName] = useState('');
    const [TopMenuSectionTab, setTopMenuSectionTab] = useState('0');

    const [IsUserMenuVisible, setIsUserMenuVisible] = useState(false);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })



    const btnLogOut_onClick = () => {
        setIsUserMenuVisible(false);
        localStorage.removeItem('token');
        localStorage.removeItem('public_key');
        window.location.reload(false);
    }


    const [IsNewsMenuVisible, setIsNewsMenuVisible] = useState(false);
    const [IsTutorialVideoVisible, setIsTutorialVideoVisible] = useState(false);

    const [AllNewsData, setAllNewsData] = useState([]);
    const [AIDashboardList, setAIDashboardList] = useState([]);


    const btnTopMenuSectionTab_onChange = (index) => {
        setTopMenuSectionTab(index);
    };

    const btnPage_onMouseEnter = (page) => {
        setPageName(page.pageName);
        setIsSectionsVisible(true);
        setTopMenuSectionTab('0');
        const chunkSize = 5;
        page.data.forEach(section => {
            section.chunkData = [];
            for (let i = 0; i < section.data.length; i += chunkSize) {
                const chunk = section.data.slice(i, i + chunkSize);
                section.chunkData.push(chunk);
            }
        });
        setSectionData(page.data);
    }
    const btnHomePage_onClick = () => {
        navigate("/");
        window.location.reload();
    }
    const btnPage_onClick = (page) => {
        navigate("/page/" + page.slug);
        // window.location.reload();
    }

    useEffect(() => {
        if (IsSectionsVisible === false) {
            setPageName('');
        } else {
            setIsNewsMenuVisible(false);
        }
    }, [IsSectionsVisible]);

    useEffect(() => {
        if (props.AllDashboardData.length > 0) {
            setAllDashboardData(props.AllDashboardData);
        }
        if (props.MenuData.length > 0) {
            setMenuData(props.MenuData);
        }
        if (props.NewsData.length > 0) {
            if (props.NewsData.length > 5) {
                setAllNewsData(props.NewsData.slice(0, 5));
            } else {
                setAllNewsData(props.NewsData);
            }
        }
        if (props.UserDetailData !== null) {
            setUserDetailData(props.UserDetailData);
        }
    }, [props.AllDashboardData, props.MenuData, props.NewsData, props.UserDetailData]);

    useEffect(() => {
        setAIDashboardList(props.AIDashboard);
    }, [props.AIDashboard]);



    const redirectToDashboard = (dashboard) => {
        setIsSectionsVisible(false);
        navigate(`/dashboard/${dashboard.slug}`);
    }



    const btnUserMenuOptionVisibleOpen_onClick = (event) => {
        setIsNewsMenuVisible(false);
        setIsSectionsVisible(false);
    }
    const btnUserMenuOptionVisibleClose_onClick = () => {
        setIsNewsMenuVisible(false);
        setIsSectionsVisible(false);
    }
    const btnLogout_onClick = () => {
        window.location.href = "https://www.google.com/";
    }

    const btnNewsMenuOptionVisibleToggle_onClick = () => {
        setIsNewsMenuVisible(!IsNewsMenuVisible);
        setIsSectionsVisible(false);
    }
    const btnNewsMenuOptionVisibleOpen_onClick = () => {
        setIsNewsMenuVisible(true);
        setIsSectionsVisible(false);
    }
    const btnNewsMenuOptionVisibleClose_onClick = (news) => {
        setIsNewsMenuVisible(false);
        setIsSectionsVisible(false);
        navigate("/newsletter/" + news.news_id);
    }

    const btnTutoraiVideo_onClose = () => {
        setIsTutorialVideoVisible(false);
    }



    return (
        <>
            <div className={st.AppBar} ref={ref} id='divTopMenu'>
                <div className={`${st.AppBarTopContainer} ${'mainContainer'}`} onMouseEnter={() => { setIsSectionsVisible(false) }}>
                    <div className={st.logoLink} onClick={btnHomePage_onClick}>
                        <img src={logo} alt="logo" className={st.logo} />
                    </div>
                    <div className={st.searchBoxContainer}>
                        <SearchBoxFullInput />
                    </div>
                    <div className={st.actionBarRootContainer}>
                        <Button size="small" startIcon={<CampaignIcon />} sx={{ color: "#ffffff", textTransform: 'capitalize' }} onClick={() => navigate("/newsletter/0")} className={st.topRightButton}>
                            Announcement
                        </Button>
                        <span className={st.actionBarSaparator}>|</span>
                        <Button size="small" startIcon={<VideocamIcon />} sx={{ color: "#ffffff", textTransform: 'capitalize' }} onClick={() => setIsTutorialVideoVisible(true)} className={st.topRightButton}>
                            Take a tour
                        </Button>
                        <ModalPopup IsVisible={IsTutorialVideoVisible} onClose={btnTutoraiVideo_onClose}>
                            <div className={st.tutorialVideoContainer}>
                                <div className={st.tutorialVideoHeader}>
                                    <div className={st.tutorialVideoTitle}>E2EViz Platform Tutorial</div>
                                    <HighlightOffIcon className={st.tutorialVideoClose} onClick={btnTutoraiVideo_onClose} />
                                </div>
                                <div className={st.tutorialVideoBody}>
                                    <video className={st.tutorialVideo} poster={playVideoImage} controls>
                                        <source src={tutorial_video} type="video/mp4" />
                                    </video>
                                </div>
                                <div className={st.tutorialVideoFooter}>
                                    <Button variant="contained" size="small" onClick={btnTutoraiVideo_onClose}>
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </ModalPopup>
                        <span className={st.actionBarSaparator}>|</span>
                        <div className={st.userDrailContainer}>
                            <Avatar sx={{ height: 25, width: 25 }} alt={UserDetailData?.given_name} className={`${st.userAvatar} ${st.smallScreenHide}`}>{UserDetailData?.firstChar}</Avatar>
                            <div className={st.userName}>{UserDetailData?.user_id}</div>
                        </div>
                    </div>
                </div>
                <div className={`${st.pagesRootContainer}`}>
                    <div className={`${st.pagesContainer} ${'mainContainer'}`}>
                        {
                            MenuData.map((page, index) => (
                                <div key={page.pageName} className={st.pageWrapper}>
                                    <div className={`${st.pageContainer} ${page.pageName === PageName ? st.ActivePageContainer : ''}`}
                                        onClick={() => { btnPage_onClick(page) }}
                                        onMouseEnter={() => { btnPage_onMouseEnter(page) }}>
                                        <div className={st.pageIcon}>
                                            {/* <i className={page.page_icon}></i> */}
                                            <img src={BaseUrlAPI + "images" + page.page_icon + "?token=" + UserDetailData?.token}
                                                alt={page.pageName + " icon"} className={st.pageIconImage} />

                                        </div>
                                        <div className={st.pageName}>
                                            {page.pageName}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            storeData.aiDashboardReports.length > 0 &&
                            <div className={st.pageWrapper}
                                onMouseEnter={() => { setIsSectionsVisible(false) }}>
                                <div className={st.pageContainer} onClick={() => { navigate('/AIDashboard'); }}>
                                    <div className={st.pageIcon}>
                                        <MdDashboardCustomize className={st.pageIconImage} />
                                    </div>
                                    <div className={st.pageName}>
                                        AI Dashboard
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={st.pageWrapper} style={{ display: UserDetailData?.admin ? '' : 'none' }}
                            onMouseEnter={() => { setIsSectionsVisible(false) }}>
                            <div className={st.pageContainer} onClick={() => { navigate('/backend'); }}>
                                <div className={st.pageIcon}>
                                    <img src={adminIcon} alt="admin" className={st.pageIconImage} />
                                </div>
                                <div className={st.pageName}>
                                    Admin
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className={`${st.menuSectionsContainer} ${'swing-in-top-fwd'}`}
                style={{ display: IsSectionsVisible ? 'block' : 'none', top: height }}>
                <div className={`${st.menuSectionsTabsDetailContainer} ${'mainContainer'}`}>
                    <div className={st.menuSectionsTabsDetailContainer2} onMouseLeave={() => { setIsSectionsVisible(false) }}>
                        <div className={st.menuSectionsTabs}>
                            {
                                SectionData.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <div key={index} onMouseEnter={() => btnTopMenuSectionTab_onChange(index)} className={`${st.menuSectionsTab} ${String(index) === String(TopMenuSectionTab) ? st.tabActive : ''}`}>
                                            <div>{section.sectionName}</div>
                                            <KeyboardArrowRightIcon />
                                            {/* {(TopMenuSectionTab) ? <EastIcon />: <KeyboardArrowRightIcon />} */}
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        {
                            SectionData.map((section, index) => (
                                <div key={index} className={st.menuSectionsDataContainer} style={{ display: String(TopMenuSectionTab) === String(index) ? 'block' : 'none' }}>
                                    <div className={st.menuSectionsDataWrapper}>
                                        {
                                            section.chunkData.map((data, indexChunk) => (
                                                <div key={indexChunk} className={st.newdivFromTop}>
                                                    {
                                                        data.map((dataSection, indexData) => (
                                                            <div key={indexData} className={`${"/dashboard/" + dataSection.slug === location.pathname ? st.menuItemDetailContainerActive : st.menuItemDetailContainer} ${st.menuItemDetailContainer}`}
                                                                onClick={() => { redirectToDashboard(dataSection) }}>
                                                                <Link to={"/dashboard/" + dataSection.slug} className={st.menuItemLink}>
                                                                    {dataSection.title}
                                                                </Link>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div style={{ height: height - 5 }}></div>
        </>
    );
}

export default DesktopNav12;

