import React, { useEffect, useState, useMemo } from 'react';
import { FaChartBar, FaChartPie, FaTable } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa6";
import { LuScatterChart } from "react-icons/lu";
import { AiOutlineRadarChart } from "react-icons/ai";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { formatHeader } from '../../../BaseModels/utility';
import st from './GraphDetails.module.css';
import BarGraph from './Graphs/BarGraph';
import LineGraph from './Graphs/LineGraph';
import PieGraph from './Graphs/PieGraph';
import ScatterGraph from './Graphs/ScatterGraph';
import RadarGraph from './Graphs/RadarGraph';

const GraphDetails = ({ data, msg_id }) => {
    const [IsGraphVisible, setIsGraphVisible] = useState(false);
    const [IsEditGraphVisible, setIsEditGraphVisible] = useState(true);
    const [IsGraphChartVisible, setIsGraphChartVisible] = useState(false);

    const [XAxisList, setXAxisList] = useState([]);
    const [XAxisSelectedValue, setXAxisSelectedValue] = useState(null);
    const [YAxisList, setYAxisList] = useState([]);
    const [YAxisSelectedList, setYAxisSelectedList] = useState([]);
    const AggregateTypeList = useMemo(() => [
        { value: 'sum', label: 'Sum' },
        { value: 'avg', label: 'Average' },
        { value: 'min', label: 'Minimum' },
        { value: 'max', label: 'Maximum' },
        { value: 'count', label: 'Count' }
    ], []);
    const [IsAggregate, setIsAggregate] = useState(false);
    const [AggregateType, setAggregateType] = useState({ value: 'sum', label: 'Sum' });
    const [ChartTypeList, setChartTypeList] = useState([
        { value: 'line', label: 'line', position: 0, disabled: false, icon: <FaChartLine /> },
        { value: 'bar', label: 'bar', position: 1, disabled: false, icon: <FaChartBar /> },
        { value: 'pie', label: 'pie', position: 2, disabled: false, icon: <FaChartPie /> },
        { value: 'scatter', label: 'scatter', position: 3, disabled: false, icon: <LuScatterChart /> },
        // { value: 'radar', label: 'radar', position: 4, disabled: false, icon: <AiOutlineRadarChart /> }
    ]);
    const [ChartTypeSelectedValue, setChartTypeSelectedValue] = useState(null);
    const [ChartData, setChartData] = useState(null);

    useEffect(() => {
        try {
            setIsGraphVisible(false);
            if (data) {
                if (data.length > 0) {
                    const keys = Object.keys(data[0]);
                    let _XAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                    setXAxisList(_XAxisList);
                    let _XAxisSelectedValueCol = defaultXAxisCalculate(data);
                    let _XAxisSelectedIndex = _XAxisList.findIndex((x) => x.value === _XAxisSelectedValueCol);
                    setXAxisSelectedValue(_XAxisList[_XAxisSelectedIndex]);
                    let _YAxisList = [];
                    let _ChartTypeSelectedValue = defaultChartTypeCalculate(data, _XAxisSelectedValueCol);
                    setChartTypeSelectedValue(_ChartTypeSelectedValue);
                    let _ChartTypeList = [...ChartTypeList];
                    if (data.length === 1) {
                        _ChartTypeList.find(x => x.value === 'line').disabled = true;
                    }
                    if (data.length > 20 && keys.length > 1) {
                        _ChartTypeList.find(x => x.value === 'pie').disabled = true;
                    }
                    if (keys.length === 1) {
                        setIsEditGraphVisible(false);
                        _ChartTypeList.forEach(_ChartType => {
                            if (_ChartType.value !== 'pie') {
                                _ChartType.disabled = true;
                            }
                        });
                    }
                    _YAxisList = defaultYAxisListCalculate(data, _XAxisSelectedValueCol);
                    setChartTypeList(_ChartTypeList);
                    setYAxisList(_YAxisList);
                    let _YAxisSelectedValue = defaultYAxisCalculate(data, _XAxisSelectedValueCol, _YAxisList);
                    let _YAxisSelectedList = _YAxisList.filter((x) => x.value === _YAxisSelectedValue);
                    setYAxisSelectedList(_YAxisSelectedList);
                    setIsGraphVisible(true);
                    let _IsAggregate = IsAggregate;
                    let _AggregateType = AggregateType;
                    prepareDataForGraph(_XAxisList, _XAxisList[_XAxisSelectedIndex], _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType);
                }
            }
        } catch (error) {
            toast.error("Error in loading graph details");
        }
    }, [data]);

    function isNumber(value) {
        return typeof value === 'number';
    }

    const defaultXAxisCalculate = (_DataRows) => {
        let _XAxisSelectedValue = '';
        try {
            let keys = Object.keys(_DataRows[0]);
            keys.forEach(col => {
                if (_XAxisSelectedValue === '') {
                    let array = _DataRows.map(item => item[col]);
                    let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                    let IsNumberArray = _dataRowremovedNull.filter(Boolean).every(isNumber);
                    if (IsNumberArray) {

                    } else {
                        let FisrtCharList = _dataRowremovedNull.map((x) => x[0]);
                        let LastCharList = _dataRowremovedNull.map((x) => x[x.length - 1]);
                        if (FisrtCharList.includes('$') ||
                            LastCharList.includes('%') ||
                            LastCharList.includes('B') ||
                            LastCharList.includes('M') ||
                            LastCharList.includes('K')
                        ) {

                        } else {
                            if (_XAxisSelectedValue === '') {
                                _XAxisSelectedValue = col;
                            }
                        }
                    }
                }
            });
            if (_XAxisSelectedValue === '') {
                keys.forEach(col => {
                    if (_XAxisSelectedValue === '') {
                        let array = _DataRows.map(item => item[col]);
                        let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                        let IsNumberArray = _dataRowremovedNull.filter(Boolean).every(isNumber);
                        if (IsNumberArray) {
                            if (String(col).toLowerCase().includes("month") || String(col).toLowerCase().includes("year")) {
                                let lengthOfEachValue = _dataRowremovedNull.map((item, index) => { return String(item).length });
                                let uniqueLength = [...new Set(lengthOfEachValue)];
                                if (uniqueLength.length === 1) {
                                    if (_XAxisSelectedValue === '') {
                                        _XAxisSelectedValue = col;
                                    }
                                } else if (uniqueLength.length === 2) {
                                    if (array.includes(1) && array.includes(2)) {
                                        if (_XAxisSelectedValue === '') {
                                            _XAxisSelectedValue = col;
                                        }
                                    }
                                } else if (uniqueLength[0] === 4) {
                                    if (_XAxisSelectedValue === '') {
                                        _XAxisSelectedValue = col;
                                    }
                                }
                            }
                        }
                    }
                });
            }
            if (_XAxisSelectedValue === '') {
                _XAxisSelectedValue = keys[0];
            }
        } catch (error) {
            toast.error("Error in default x-axis calculate");
        }
        return _XAxisSelectedValue;
    }

    const defaultChartTypeCalculate = (_DataRows, _XAxisSelectedValue) => {
        let _ChartTypeSelectedValue = '';
        try {
            let keys = Object.keys(_DataRows[0]);
            if (keys.length === 1 || _DataRows.length === 1) {
                _ChartTypeSelectedValue = 'pie';
            } else {
                let array = _DataRows.map(item => item[_XAxisSelectedValue]);
                let uniqueValuesList = [...new Set(array)];
                if (array.length === uniqueValuesList.length && _DataRows.length > 1) {
                    _ChartTypeSelectedValue = 'line';
                } else {
                    _ChartTypeSelectedValue = 'bar';
                }
            }
        } catch (error) {
            toast.error("Error in default chart type calculate");
        }
        return _ChartTypeSelectedValue;
    }

    const defaultYAxisCalculate = (_DataRows, _XAxisSelectedValue, _YAxisList) => {
        let _YAxisSelectedValue = '';
        let keys = Object.keys(_DataRows[0]);
        try {
            if (keys.length === 1) {
                _YAxisSelectedValue = keys[0];
            } else {
                let Enabled_YAxisList = _YAxisList.filter((x) => x.isDisabled === false);
                keys = Enabled_YAxisList.map((x) => x.value);
                for (let index = keys.length - 1; index >= 0; index--) {
                    let col = keys[index];
                    if (_YAxisSelectedValue === '') {
                        let array = _DataRows.map(item => item[col]);
                        let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                        let IsNumberArray = _dataRowremovedNull.filter(Boolean).every(isNumber);
                        if (IsNumberArray) {
                            if (_YAxisSelectedValue === '') {
                                _YAxisSelectedValue = col;
                            }
                        } else {
                            let FisrtCharList = _dataRowremovedNull.map((x) => x[0]);
                            let LastCharList = _dataRowremovedNull.map((x) => x[x.length - 1]);
                            if (FisrtCharList.includes('$') ||
                                LastCharList.includes('%') ||
                                LastCharList.includes('B') ||
                                LastCharList.includes('M') ||
                                LastCharList.includes('K')
                            ) {
                                if (_YAxisSelectedValue === '') {
                                    _YAxisSelectedValue = col;
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in default y-axis calculate");
        }
        if (_YAxisSelectedValue === '') {
            _YAxisSelectedValue = keys[keys.length - 1];
        }
        return _YAxisSelectedValue;
    }

    const defaultYAxisListCalculate = (_DataRows, _XAxisSelectedValue) => {
        let _YAxisList = [];
        let objYAxis = {};
        let All_keys = Object.keys(_DataRows[0]);
        let keys = All_keys.filter(x => x !== _XAxisSelectedValue);
        try {
            if (keys.length > 0) {
                for (let index = keys.length - 1; index >= 0; index--) {
                    let col = keys[index];
                    let array = _DataRows.map(item => item[col]);
                    let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                    let IsNumberArray = _dataRowremovedNull.filter(Boolean).every(isNumber);
                    if (IsNumberArray) {
                        objYAxis = { value: col, label: formatHeader(col), position: (keys.length - 1) - index, isDisabled: false };
                    } else {
                        objYAxis = { value: col, label: formatHeader(col), position: (keys.length - 1) - index, isDisabled: true };
                    }
                    _YAxisList.push(objYAxis);
                }
            }
            if (_YAxisList.length > 0) {
                if (_YAxisList.filter((x) => x.isDisabled === false).length > 0) {

                } else {
                    _YAxisList[0].isDisabled = false;
                }
                _YAxisList.sort((a, b) => b.position - a.position);
            } else {
                _YAxisList.push({ value: All_keys[0], label: formatHeader(All_keys[0]), position: 0, isDisabled: false });
            }
        } catch (error) {
            toast.error("Error in default y-axis calculate");
        }
        return _YAxisList;
    }

    const ddlChartTypeList_onChange = (item) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = { ...ChartTypeSelectedValue };

            _ChartTypeSelectedValue = item.value;
            setChartTypeSelectedValue(_ChartTypeSelectedValue);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType);
        } catch (error) {
            toast.error("Error in ddl Chart Type List");
        }
    }

    const ddlXAxisList_onChange = (item) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = { ...ChartTypeSelectedValue };

            _XAxisSelectedValue = item;
            _YAxisList = defaultYAxisListCalculate(data, item.value);
            setYAxisList(_YAxisList);
            let _YAxisSelectedValue = defaultYAxisCalculate(data, item.value, _YAxisList);
            _YAxisSelectedList = _YAxisList.filter((x) => x.value === _YAxisSelectedValue);
            setYAxisSelectedList(_YAxisSelectedList);

            setXAxisSelectedValue(_XAxisSelectedValue);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType);
        } catch (error) {
            toast.error("Error in ddl x-axis selection");
        }
    }

    const ddlYAxisList_onChange = (item) => {
        try {
            if (item.length === 0) {
                toast.info("Please select at least one Y-Axis");
                return;
            }
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = { ...ChartTypeSelectedValue };

            _YAxisSelectedList = item;
            setYAxisSelectedList(item);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType);
        } catch (error) {
            toast.error("Error in y-axis selection");
        }
    }

    const IsAggregate_onChange = (e) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = { ...ChartTypeSelectedValue };

            _IsAggregate = e.target.checked;
            setIsAggregate(_IsAggregate);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType);
        } catch (error) {
            toast.error("Error in aggregate selection");
        }
    }

    const ddlAggregateList_onChange = (item) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = { ...ChartTypeSelectedValue };
            _AggregateType = item;
            setAggregateType(_AggregateType);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType);
        } catch (error) {
            toast.error("Error in aggregate selection");
        }
    }

    const prepareDataForGraph = (
        _XAxisList,
        _XAxisSelectedValue,
        _YAxisList,
        _YAxisSelectedList,
        _ChartTypeList,
        _ChartTypeSelectedValue,
        _IsAggregate,
        _AggregateType
    ) => {
        try {
            let _IsGraphChartVisibleCount = 0;
            _YAxisSelectedList.forEach(_YAxisSelected => {
                try {
                    let _YAxisSelectedValue = _YAxisSelected.value;
                    let array = data.map(item => item[_YAxisSelectedValue]);
                    let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                    let uniqueValue = [...new Set(_dataRowremovedNull)];
                    if (uniqueValue.length > 0) {
                        _IsGraphChartVisibleCount++;
                    } else {
                        if (uniqueValue[0]) {
                            _IsGraphChartVisibleCount++;
                        }
                    }
                } catch (error) {

                }
            });
            if (_IsGraphChartVisibleCount > 0) {
                setIsGraphChartVisible(true);
            } else {
                setIsGraphChartVisible(false);
            }
            let obj = {
                XAxisList: _XAxisList,
                XAxisSelectedValue: _XAxisSelectedValue,
                YAxisList: _YAxisList,
                YAxisSelectedList: _YAxisSelectedList,
                IsAggregate: _IsAggregate,
                AggregateType: _AggregateType?.value,
                ChartTypeList: _ChartTypeList,
                ChartTypeSelectedValue: _ChartTypeSelectedValue,
                RowData: data,
                ScreenCellLength: 4,
                msg_id: msg_id
            }
            setChartData(obj);
        } catch (error) {
            toast.error("Error in binding all details");
        }
    }

    const randerGraph = (_ChartTypeSelectedValue, _ChartData) => {
        try {
            return (
                <div style={{ height: '250px', width: '100%', position: 'absolute', left: 0 }}>
                    {_ChartTypeSelectedValue === 'bar' ? <BarGraph ChartData={_ChartData} /> : null}
                    {_ChartTypeSelectedValue === 'line' ? <LineGraph ChartData={_ChartData} /> : null}
                    {_ChartTypeSelectedValue === 'pie' ? <PieGraph ChartData={_ChartData} /> : null}
                    {_ChartTypeSelectedValue === 'scatter' ? <ScatterGraph ChartData={_ChartData} /> : null}
                    {_ChartTypeSelectedValue === 'radar' ? <RadarGraph ChartData={_ChartData} /> : null}
                </div>
            );
        } catch (error) {
            toast.error("Error in rendering graph");
        }
    }

    return (
        IsGraphVisible &&
        <>
            <br />
            <div style={{ display: 'flex', gap: '10px' }}>
                {
                    ChartTypeList.map((item, index) => {
                        return (
                            <button key={index}
                                className={item.value === ChartTypeSelectedValue ? st.btnChartTypeActive : !item.disabled ? st.btnChartType : st.btnChartTypeDisabled}
                                disabled={item.disabled}
                                onClick={() => ddlChartTypeList_onChange(item)}>
                                {item.icon} {item.label}
                            </button>
                        );
                    })
                }
            </div>
            {
                IsEditGraphVisible &&
                <details>
                    <summary>Edit Graph</summary>
                    <div className={st.editGraphContainer}>
                        {
                            XAxisList.length > 0 && XAxisSelectedValue !== null &&
                            <div className={st.editGraphItem}>
                                <strong>X-Axis</strong>
                                <Select
                                    className="Select-X-Axis"
                                    classNamePrefix="select"
                                    value={XAxisSelectedValue}
                                    isClearable={false}
                                    isSearchable={false}
                                    name="Select-X-Axis"
                                    options={XAxisList}
                                    onChange={ddlXAxisList_onChange}
                                />
                            </div>
                        }
                        {
                            YAxisList.length > 0 && YAxisSelectedList.length > 0 &&
                            <div className={st.editGraphItem}>
                                <strong>Y-Axis</strong>
                                <Select
                                    className="Select-Y-Axis"
                                    classNamePrefix="select"
                                    isMulti
                                    value={YAxisSelectedList}
                                    isClearable={false}
                                    isSearchable={false}
                                    name="Select-Y-Axis"
                                    options={YAxisList}
                                    onChange={ddlYAxisList_onChange}
                                />
                            </div>
                        }
                        {
                            YAxisSelectedList.length &&
                            <div className={st.editGraphItem}>
                                <strong>
                                    <label>
                                        Aggregate &nbsp;
                                        <input type="checkbox" checked={IsAggregate} onChange={IsAggregate_onChange} />
                                    </label>
                                </strong>
                                {
                                    IsAggregate && <Select
                                        className="Select-Aggregate"
                                        classNamePrefix="select aggregate"
                                        value={AggregateType}
                                        isClearable={false}
                                        isSearchable={false}
                                        name="Select-Aggregate"
                                        options={AggregateTypeList}
                                        onChange={ddlAggregateList_onChange}
                                    />
                                }
                            </div>
                        }
                    </div>
                </details>
            }
            {
                IsGraphChartVisible && ChartData &&
                ChartTypeList.length > 0 &&
                XAxisList.length > 0 && XAxisSelectedValue !== null &&
                YAxisList.length > 0 && YAxisSelectedList.length > 0 &&
                <div style={{ height: '250px', width: '100%' }}>
                    {randerGraph(ChartTypeSelectedValue, ChartData)}
                </div>
            }
            {!IsGraphChartVisible && <div>No data available for graph</div>}
        </>
    );
};
export default GraphDetails;