import { Button } from '@mui/material';
import React, { useState, useContext } from 'react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa6';
import { IoSend } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { toast } from 'react-toastify';
import st from './FeedbackForm.module.css';
import { updateAIAssistantFeedback } from '../../../BaseModels/MasterData';
import { GlobalContext } from '../../store';
import { cloneDeep } from 'lodash';



const FeedbackForm = ({ messageObj }) => {
    const initialFeedbackStatus = messageObj.feedbackFlag;
    const initialFeedbackText = messageObj.feedbackMessage;
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [_feedbackStatus, setFeedbackStatus] = useState(initialFeedbackStatus);
    const [_feedbackText, setFeedbackText] = useState(initialFeedbackText);
    const [requestSending, setRequestSending] = useState(false);
    const { setStoreData } = useContext(GlobalContext);

    const btnFeedback_onClick = (feedback) => {
        if (showFeedbackForm) {
            if (_feedbackStatus === feedback) {
                setFeedbackStatus(initialFeedbackStatus);
                setShowFeedbackForm(false);
            } else {
                setFeedbackStatus(feedback);
                setShowFeedbackForm(true);
            }
        }
        else {
            setFeedbackStatus(feedback);
            setShowFeedbackForm(true);
        }
    }

    const btnCancel_onClick = () => {
        setShowFeedbackForm(false);
        setFeedbackStatus(initialFeedbackStatus);
        setFeedbackText(initialFeedbackText);
    }

    const btnSend_onClick = () => {
        setRequestSending(true);
        updateAIAssistantFeedback(messageObj.messageId, _feedbackStatus, _feedbackText)
            .then((data) => {
                if (!data.status) {
                    toast.error('Error! Could not save feedback!', { autoClose: 3000 });
                    return;
                }
                setShowFeedbackForm(false);
                setStoreData((_state) => {
                    const _aiChats = cloneDeep(_state.aiChats);
                    const conversationObj = _aiChats.conversations[messageObj.sessionId];
                    const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageObj.messageId);
                    const _msgObj = conversationObj.messages[messageIndex];
                    _msgObj.feedbackFlag = _feedbackStatus;
                    _msgObj.feedbackMessage = _feedbackText;
                    return { ..._state, aiChats: _aiChats };
                });
                toast.success('Feedback saved successfully!', {autoClose: 2000});
            })
            .finally(() => {
                setRequestSending(false);
            });
    }

    return (
        <div className={st.feedbackRootContainer}>
            <div className={st.feedbackButtonContainer}>
                <div className={`${st.feedbackButtonItemContainer} ${_feedbackStatus === "Like" ? st.feedbackButtonActive : ''}`} onClick={() => btnFeedback_onClick('Like')} >
                    <FaThumbsUp className={`${st.feedbackButton}`} />
                </div>
                <div className={`${st.feedbackButtonItemContainer} ${_feedbackStatus === "Dislike" ? st.feedbackButtonActive : ''}`} onClick={() => btnFeedback_onClick('Dislike')} >
                    <FaThumbsDown className={`${st.feedbackButton}`} />
                </div>
            </div>

            {showFeedbackForm && (
                <div className={st.textareaContainer}>
                    <textarea className={st.textArea} value={_feedbackText} 
                        onChange={(event) => { setFeedbackText(event.target.value) }}
                        disabled={requestSending}
                    />
                    <div className={st.formButtonContainer}>
                        <Button variant="outlined" size="small" startIcon={<MdCancel />} 
                            onClick={btnCancel_onClick} disabled={requestSending}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" size="small" startIcon={<IoSend />}
                            onClick={btnSend_onClick} disabled={requestSending}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbackForm;