import React, { useState } from 'react';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Breadcrumbs, Box, Tabs, Tab } from '@mui/material'
import { Link } from "react-router-dom";
import { format as _formatDate } from 'date-fns';
import AIAssistantPrompt from './AIAssistantPromt/AIAssistantPromt';
import AIAssistantFeedback from './AIAssistantFeedback/AIAssistantFeedback';
import st from './AIAssistantAdmin.module.css';


const TABS = {
    FEEDBACKS: 'feedback',
    PROMPTS: 'prompts',
}



const AIAssistantAdmin = () => {
    const [tabValue, setTabValue] = useState(TABS.FEEDBACKS);
    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/ai-assistant"}>AI Assistant</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row">
                <Box sx={{ width: '100%' }}>
                    <Tabs value={tabValue} aria-label="Select tab"
                        onChange={(_, value) => setTabValue(value)}
                    >
                        <Tab label="Feedbacks" value={TABS.FEEDBACKS} />
                        <Tab label="Default Prompts" value={TABS.PROMPTS} />
                    </Tabs>
                </Box>
                </div>
                <div className="row">
                    <div className="col-12 pt-3">
                        {
                            tabValue === TABS.FEEDBACKS && (
                                <AIAssistantFeedback />
                            )
                        }
                        {
                            tabValue === TABS.PROMPTS && (
                                <>
                                    <AIAssistantPrompt />
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AIAssistantAdmin;