// import FormControlLabel from '@mui/material/FormControlLabel';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import * as _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import InfoButton from '../InfoButton';
import { getMatomoPauseUse } from '../../../BaseModels/MasterData';
import { format as _formatDate } from 'date-fns';
import styles from './PauseUse.module.css';




const PauseUse = ({ roles }) => {
    const fontSizeLegends = 12;
    const colorLegends = 'black';
    const colorBarFill = ['#82C3A8', '#BCA034', '#A16666', '#669BAF', '#789682'];
    const colorBarFillCount = colorBarFill.length;
    const [GraphType, setGraphType] = useState('Table');

    const durations = [
        { name: '7 Days', value: 7 },
        { name: '30 Days', value: 30 },
        { name: '3 Months', value: 90 },
        { name: '6 Months', value: 180 },
    ];

    const [filterDuration, setFilterDuration] = useState(30);
    const columnDefs = useMemo(() => ([
        {
            field: 'userid', headerName: 'Id', cellRenderer:
                function (params) {
                    return (
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-envelope' style={{ color: '#FEBE10' }}></i>
                                <span>{params.value}</span>
                            </span>
                        </>
                    )
                },
            width: 120
        },
        {
            field: 'fullname', headerName: 'Name', cellRenderer:
                function (params) {
                    return (
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-user' style={{ color: '#318CE7' }}></i>
                                <span>{params.value}</span>
                            </span>
                        </>
                    )
                },
            width: 150
        },
        {
            headerName: 'First Visit Date',
            valueGetter: ({ data }) => new Date(data.first_visit_date),
            filter: 'agDateColumnFilter',
            cellRenderer:
                function ({ value }) {
                    return (
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-calendar-plus' style={{ color: '#17B169' }}></i>
                                <span>{_formatDate(value, 'MM/dd/yyyy')}</span>
                            </span>
                        </>
                    )
                },
            width: 150
        },
        {
            headerName: 'Last Visit Date',
            valueGetter: ({ data }) => new Date(data.last_visit_date),
            filter: 'agDateColumnFilter',
            cellRenderer:
                function ({ value }) {
                    return (
                        <>
                            <span className={`${styles.cell}`}>
                                <i className='fas fa-calendar-minus' style={{ color: '#E34234' }}></i>
                                <span>{_formatDate(value, 'MM/dd/yyyy')}</span>
                            </span>
                        </>
                    )
                },
            width: 150
        }
    ]), []);

    const [topUsers, setTopUsers] = useState(null);

    useEffect(() => {
        const ac = new AbortController();
        getMatomoPauseUse(roles, filterDuration, ac.signal)
            .then((response) => {
                if (response.status) {
                    let data = _.orderBy(response.data, ['visitcount'], ['desc']);
                    setTopUsers(data);
                }
            });
        return () => ac.abort();
    }, [roles, filterDuration]);

    const CustomizedAxisLabel = ({ x, y, payload }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0}
                    dy={payload.index % 2 === 0 ? 10 : 24}
                    fill={colorLegends}
                    fontSize={fontSizeLegends}
                    fontWeight='bold'
                    textAnchor='middle'
                >
                    {payload.value}
                </text>
            </g>
        );
    }


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={styles.header}>
                    <div className={styles.title}>Inactive User</div>
                    <InfoButton
                        content={'This section highlights the inactive Users who has lack of engagement'}
                    />
                </div>
                <div>
                    <select value={filterDuration} onChange={(e) => {
                        setFilterDuration(e.target.value);
                    }} className={styles.selectMonth}>
                        {durations.map((x) => (
                            <option key={x.value} value={x.value}>
                                {x.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div>
                    <RadioGroup row
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={GraphType}
                        onChange={(event) => setGraphType(event.target.value)}
                        size='small'
                    >
                        <FormControlLabel value='Bar' control={<Radio size='small' />} label='Bar' />
                        <FormControlLabel value='Table' control={<Radio size='small' />} label='Table' />
                    </RadioGroup>
                </div> */}
            </div>
            <div className={styles.wrapper}>
                <div className={styles.chart_container}>
                    {
                        GraphType === 'Bar' && Array.isArray(topUsers) ? <>
                            <ResponsiveContainer width='100%' height='100%'>
                                <BarChart
                                    data={topUsers}
                                    margin={{
                                        top: 0,
                                        right: 10,
                                        left: 10,
                                        bottom: 10,
                                    }}
                                >
                                    <XAxis
                                        dataKey='userid'
                                        type='category'
                                        interval={0}
                                        tick={<CustomizedAxisLabel />}
                                    />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Bar dataKey='visitcount'>
                                        {
                                            topUsers.map((_, idx) => (
                                                <Cell key={`cell-tu-${idx}`} fill={colorBarFill[idx % colorBarFillCount]} />
                                            ))
                                        }
                                        <LabelList
                                            dataKey='visitcount'
                                            position='top'
                                            fontSize={fontSizeLegends}
                                            fontWeight='bold'
                                            fill={colorLegends}
                                        />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </> : <></>
                    }
                    {
                        GraphType === 'Table' ? <>
                            <div className='aggrid-header'>
                                <div style={{ height: '390px', width: '100%' }} className='ag-theme-quartz'>
                                    <AgGridReact
                                        rowData={topUsers}
                                        columnDefs={columnDefs}
                                        defaultColDef={{
                                            filter: true,
                                            editable: false,
                                            sortable: true
                                        }}
                                    />
                                </div>
                            </div>
                        </> : <></>
                    }
                </div>
            </div>
        </>
    );
};

export default PauseUse;
