import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPageDesign } from '../../BaseModels/MasterData';
import CarouselNews from '../../CustomElements/CarouselNews/CarouselNews';
import Carousels from '../../CustomElements/Carousels/Carousels';
import DashboardPageItem from '../../CustomElements/DashboardPageItem/DashboardPageItem';
import FavoriteDashboard from '../../CustomElements/FavoriteDashboard/FavoriteDashboard';
import FeaturedDashboard from '../../CustomElements/FeaturedDashboard/FeaturedDashboard';
import LoadingFullScreen from '../../CustomElements/LoadingFullScreen/LoadingFullScreen';
import SummaryDetails from '../../CustomElements/SummaryDetails/SummaryDetails';
import TopMenu from '../TopMenu/TopMenu';
import { UDContext } from './../../../App';




const LandingPage = () => {
    const UserDetail = useContext(UDContext);
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [DesignList, setDesignList] = useState([]);
    const [IsPageLoading, setIsPageLoading] = useState(false);

    const _HOME_PAGE_ID = 1;

    useEffect(() => {
        if (!UserDetail) return;
        if (['/', '/ai-assistant', '/ai-assistant-lg-insight'].includes(location.pathname)) {
            getPageDesignData(_HOME_PAGE_ID);
            return;
        }
        const selectedPage = UserDetail?.AllMenu?.find(x => x.slug === slug);
        if (selectedPage) {
            const page_id = selectedPage.page_id;
            getPageDesignData(page_id);
        } else {
            navigate('/404');
        }
    }, [UserDetail, location.pathname, slug]);




    const getPageDesignData = async (page_id) => {
        try {
            setIsPageLoading(true);
            setDesignList([]);
            getPageDesign(page_id).then((response) => {
                if (response.status) {
                    setDesignList(response.data);
                } else {
                    toast.error("Error! Could not get page design!");
                }
                setIsPageLoading(false);
            });
        } catch (error) {
            toast.error("Error! Could not get page design!");
        }
    }



    const renderData = (item, index) => {
        if (item.activate && item.design_type === "description") {
            return <SummaryDetails key={index} div_id={item.div_id} selected_design_id={item.selected_design_id} />
        } else if (item.activate && item.design_type === "carosel") {
            return <Carousels key={index} div_id={item.div_id} selected_design_id={item.selected_design_id} />
        } else if (item.activate && item.design_type === "announcement") {
            return <CarouselNews key={index} div_id={item.div_id} selected_design_id={item.selected_design_id} />
        } else if (item.activate && item.design_type === "favorite") {
            return <FavoriteDashboard key={index} div_id={item.div_id} selected_design_id={item.selected_design_id} />
        } else if (item.activate && item.design_type === "usage_feature") {
            return <FeaturedDashboard key={index} div_id={item.div_id} selected_design_id={item.selected_design_id} />
        } else if (item.activate && item.design_type === "priority_feature") {
            return <DashboardPageItem key={index} page_id={item.page_id} selected_design_id={item.selected_design_id} />
        } else {
            return <React.Fragment key={index}></React.Fragment>
        }
    };





    return (
        <>
            {
                !IsPageLoading ? <>
                    <TopMenu />
                    {
                        DesignList.map(renderData)
                    }
                    <br /></> : <><LoadingFullScreen visible={IsPageLoading} /></>
            }
        </>
    );
};

export default LandingPage;
