import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload } from "react-icons/fa";
import { Brush, CartesianGrid, LabelList, ResponsiveContainer, Dot, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { formatHeader, updateAIAssistantGraph } from './../../../../../BaseModels/utility';
import st from './graphs.module.css';

const colors = ['#01234a', '#077a9d', '#6B318A', '#6C5EB7', '#5a8db1', '#3372aa', '#00338d', '#490b3e', '#91300a', '#289d7c', '#4682B4', '#00CED1', '#2E8B57', '#6A5ACD', '#5F9EA0', '#48D1CC', '#B8860B', '#00BFFF', '#DAA520', '#8FBC8F', '#556B2F'];

const ScatterGraph = ({ ChartData }) => {
    const chartRef = useRef(null);
    const [data, setData] = useState([]);
    const [IsGraphChartVisible, setIsGraphChartVisible] = useState(false);

    useEffect(() => {
        if (ChartData) {
            if (ChartData.IsAggregate) {
                let groupBy_XAxisData = _.mapValues(_.groupBy(ChartData.RowData, ChartData.XAxisSelectedValue.value), clist => clist.map(car => _.omit(car, ChartData.XAxisSelectedValue.value)));
                let groupBy_XAxisKeysList = Object.keys(groupBy_XAxisData);
                let _data = [];
                groupBy_XAxisKeysList.forEach(xKey => {
                    let obj = {};
                    obj[ChartData.XAxisSelectedValue.value] = xKey;
                    let xValueList = groupBy_XAxisData[xKey];
                    ChartData.YAxisSelectedList.forEach(yAxisObj => {
                        let yKey = yAxisObj.value;
                        let yVal = _.sumBy(xValueList, yKey);
                        switch (ChartData.AggregateType) {
                            case 'sum':
                                yVal = _.sumBy(xValueList, yKey);
                                break;
                            case 'avg':
                                yVal = _.meanBy(xValueList, yKey);
                                break;
                            case 'min':
                                yVal = _.minBy(xValueList, yKey)[yKey];
                                break;
                            case 'max':
                                yVal = _.maxBy(xValueList, yKey)[yKey];
                                break;
                            case 'count':
                                yVal = xValueList.length;
                                break;
                            default:
                                yVal = _.sumBy(xValueList, yKey);
                                break;
                        }
                        obj[yKey] = (yVal !== '' && yVal !== null && yVal !== undefined) ?
                            (typeof yVal === 'number' ?
                                (parseFloat(yVal.toFixed(2)) % 1 === 0 ? Math.round(yVal) : parseFloat(yVal.toFixed(2)))
                                : null)
                            : null;
                    });
                    _data.push(obj);
                });
                setData(_data);
                setIsGraphChartVisible(true);
            } else {
                let _data = ChartData.RowData;
                setData(_data);
                setIsGraphChartVisible(true);
            }
        }
    }, [ChartData]);

    const CustomTooltipTable = (label) => {
        if (ChartData.IsAggregate) {
            let array = ChartData.RowData.map(item => item[ChartData.XAxisSelectedValue.value]);
            let uniqueValuesList = [...new Set(array)];
            if (array.length !== uniqueValuesList.length) {
                let groupBy_XAxisData = _.mapValues(_.groupBy(ChartData.RowData, ChartData.XAxisSelectedValue.value), clist => clist.map(car => _.omit(car, ChartData.XAxisSelectedValue.value)));
                let groupBy_XAxisKeysList = Object.keys(groupBy_XAxisData);
                let tableData = groupBy_XAxisData[label];
                if (tableData && tableData.length > 0) {
                    return (
                        <div className={st.tableContainer}>
                            <table>
                                <thead>
                                    <tr>
                                        {Object.keys(tableData[0]).map((key, index) => (
                                            <th key={index}>{formatHeader(key)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {Object.values(row).map((value, cellIndex) => (
                                                <td key={cellIndex}>{value !== null && typeof value === 'object' ? value.value : value}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                }
            }
        }
        return null;
    }

    const CustomTooltip = (props) => {
        const { active, payload } = props;
        if (active && payload && payload.length) {
            let label = payload[0].payload[ChartData.XAxisSelectedValue.value];
            let newPayload = payload.filter(item => item.dataKey !== ChartData.XAxisSelectedValue.value);

            return (
                <div className={st.ttrContainer}>
                    <div className={st.ttXAxisLabelName}>{label}</div>
                    {
                        newPayload?.length > 0 && newPayload.map((item, index) => {
                            return <div key={index} className={st.ttYAxisPayload}>{formatHeader(item.dataKey)}: <strong>{item.value}</strong> </div>
                        })
                    }
                    {CustomTooltipTable(label)}
                </div>
            );
        }
        return null;
    };

    const renderBrush = () => {
        if (data.length > ChartData.ScreenCellLength) {
            let endIndex = ChartData.ScreenCellLength;
            if (endIndex > (data.length - 1)) {
                endIndex = data.length - 1;
            }
            endIndex = Math.floor(endIndex);
            return <Brush dataKey={ChartData.XAxisSelectedValue.value} height={10} stroke="#01234a" startIndex={0} endIndex={endIndex} />;
        }
        return null;
    }

    const CustomAxisTick = ({ x, y, payload }) => {
        const value = payload.value.length > 15 ? `${payload.value.slice(0, 15)}...` : payload.value;
        let xPotion = x + value.length * 2;
        return (
            <g transform={`translate(${xPotion},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="black" fontSize="10px">
                    {value}
                </text>
            </g>
        );
    };

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        const radius = 10;

        return (
            <g>
                <text x={x + width / 2} y={y - radius} textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    const renderXAxis = () => {
        return <XAxis
            interval={0}
            dataKey={ChartData.XAxisSelectedValue.value}
            label={{
                value: formatHeader(ChartData.XAxisSelectedValue.value),
                position: 'insideBottom',
                offset: -10,
                style: { fontWeight: 'bold', fill: '#01234a' }
            }}
            tick={<CustomAxisTick />} />
    }

    const btnDownloadGraph_onClick = async () => {
        if (chartRef) {
            if (chartRef.current) {
                const canvas = await html2canvas(chartRef.current);
                const base64Image = canvas.toDataURL('image/png');
                var a = document.createElement("a");
                a.href = base64Image;
                a.download = "Image" + ChartData.msg_id + ".png";
                a.click();
            }
        }
    }

    return (
        IsGraphChartVisible &&
        <>
            <IconButton color="primary" onClick={btnDownloadGraph_onClick} size="small"
                style={{ position: 'absolute', right: '16px', marginTop: '-20px' }}>
                <FaDownload />
            </IconButton>
            <ResponsiveContainer width="100%" height="100%" ref={chartRef}>
                <ScatterChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 35,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 1" />
                    {renderXAxis()}
                    <YAxis tick={false} label={{
                        value: ChartData.YAxisSelectedList.map((x) => formatHeader(x.value)).join(" & "),
                        position: 'start',
                        angle: -90,
                        offset: -10,
                        style: { fontWeight: 'bold', fill: '#01234a' }
                    }} />
                    <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                    {
                        ChartData.YAxisSelectedList.map((item, index) => {
                            return (
                                <Scatter dataKey={item.value} fill={colors[index]} key={index} shape={
                                    ({ cx, cy }) => {
                                        return (
                                            <Dot cx={cx} cy={cy} fill={colors[index]} r={8} />
                                        )
                                    }}>
                                    <LabelList dataKey={item.value} content={renderCustomizedLabel} />
                                </Scatter>);
                        })
                    }
                    {renderBrush()}
                </ScatterChart>
            </ResponsiveContainer>
        </>
    );
};

export default ScatterGraph;