import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload } from "react-icons/fa";
import { RiRefreshLine } from "react-icons/ri";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';
import { formatHeader, updateAIAssistantGraph } from '../../../../BaseModels/utility';
import st from './graphs.module.css';

const colors = ['#01234a', '#077a9d', '#6B318A', '#6C5EB7', '#5a8db1', '#3372aa', '#00338d', '#490b3e', '#91300a', '#289d7c', '#4682B4', '#00CED1', '#2E8B57', '#6A5ACD', '#5F9EA0', '#48D1CC', '#B8860B', '#00BFFF', '#DAA520', '#8FBC8F', '#556B2F'];

const RadarGraph = ({ ChartData }) => {
    const chartRef = useRef(null);
    const [data, setData] = useState([]);
    const [IsGraphChartVisible, setIsGraphChartVisible] = useState(false);
    const [IsDownloadLoading, setIsDownloadLoading] = useState(false);

    useEffect(() => {
        try {
            if (ChartData) {
                if (ChartData.IsAggregate) {
                    let groupBy_XAxisData = _.mapValues(_.groupBy(ChartData.RowData, ChartData.XAxisSelectedValue.value), clist => clist.map(car => _.omit(car, ChartData.XAxisSelectedValue.value)));
                    let groupBy_XAxisKeysList = Object.keys(groupBy_XAxisData);
                    let _data = [];
                    groupBy_XAxisKeysList.forEach(xKey => {
                        let obj = {};
                        obj[ChartData.XAxisSelectedValue.value] = xKey;
                        let xValueList = groupBy_XAxisData[xKey];
                        ChartData.YAxisSelectedList.forEach(yAxisObj => {
                            let yKey = yAxisObj.value;
                            let yVal = _.sumBy(xValueList, yKey);
                            switch (ChartData.AggregateType) {
                                case 'sum':
                                    yVal = _.sumBy(xValueList, yKey);
                                    break;
                                case 'avg':
                                    yVal = _.meanBy(xValueList, yKey);
                                    break;
                                case 'min':
                                    yVal = _.minBy(xValueList, yKey)[yKey];
                                    break;
                                case 'max':
                                    yVal = _.maxBy(xValueList, yKey)[yKey];
                                    break;
                                case 'count':
                                    yVal = xValueList.length;
                                    break;
                                default:
                                    yVal = _.sumBy(xValueList, yKey);
                                    break;
                            }
                            obj[yKey] = (yVal !== '' && yVal !== null && yVal !== undefined) ?
                                (typeof yVal === 'number' ?
                                    (parseFloat(yVal.toFixed(2)) % 1 === 0 ? Math.round(yVal) : parseFloat(yVal.toFixed(2)))
                                    : null)
                                : null;
                        });
                        _data.push(obj);
                    });
                    setData(_data);
                    setIsGraphChartVisible(true);
                } else {
                    let _data = ChartData.RowData;
                    setData(_data);
                    setIsGraphChartVisible(true);
                }
                setTimeout(() => {
                    downloadChart();
                }, 2000);
            }
        } catch (error) { }

    }, [ChartData]);

    const downloadChart = async () => {
        try {
            if (chartRef) {
                if (chartRef.current) {
                    const canvas = await html2canvas(chartRef.current);
                    const base64Image = canvas.toDataURL('image/png');
                    updateAIAssistantGraph(ChartData.msg_id, base64Image);
                }
            }
        } catch (error) { }

    }

    const CustomTooltip = (props) => {
        try {
            const { active, payload, label } = props;
            if (active && payload && payload.length) {
                return (
                    <div className={st.ttrContainer}>
                        <div className={st.ttXAxisLabelName}>{label}</div>
                        {
                            payload.map((item, index) => {
                                return <div key={index} className={st.ttYAxisPayload}>{formatHeader(item.dataKey)}: <strong>{item.value}</strong> </div>
                            })
                        }
                    </div>
                );
            }
        } catch (error) { }

        return null;
    };

    const truncateLabel = (label) => {
        return label.length > 10 ? `${label.slice(0, 10)}...` : label;
    };

    const btnDownloadGraph_onClick = async () => {
        try {
            if (chartRef) {
                setIsDownloadLoading(true);
                if (chartRef.current) {
                    const canvas = await html2canvas(chartRef.current);
                    const base64Image = canvas.toDataURL('image/png');
                    var a = document.createElement("a");
                    a.href = base64Image;
                    a.download = "Image" + ChartData.msg_id + ".png";
                    a.click();
                }
                setTimeout(() => { setIsDownloadLoading(false); }, 3000);
            }
        } catch (error) { setIsDownloadLoading(false); }
    }

    return (
        IsGraphChartVisible &&
        <>
            <IconButton color="primary" onClick={btnDownloadGraph_onClick} size="small"
                style={{ position: 'absolute', right: '16px', marginTop: '-20px' }} disabled={IsDownloadLoading}>
                {IsDownloadLoading ? <RiRefreshLine className='rotate-center' /> : <FaDownload />}
            </IconButton>
            <ResponsiveContainer width="100%" height="100%" ref={chartRef}>
                <RadarChart
                    cx="50%"
                    cy="50%"
                    outerRadius="80%"
                    data={data}
                >
                    <PolarGrid />
                    <PolarAngleAxis
                        dataKey={ChartData.XAxisSelectedValue.value}
                        tickFormatter={truncateLabel}
                        tick={{ fontSize: 10 }}
                    />
                    <PolarRadiusAxis />
                    <Tooltip content={<CustomTooltip />} />
                    {
                        ChartData.YAxisSelectedList.map((item, index) => {
                            return <Radar name={formatHeader(item.value)} dataKey={item.value} stroke={colors[index]} fill={colors[index]} fillOpacity={0.6} key={index} />;
                        })
                    }
                </RadarChart>
            </ResponsiveContainer>
        </>
    );
};

export default RadarGraph;