import { createContext, useState } from "react";

const DEFAULT_STATE = {
    aiChats: {
        conversations: {},
        selectedSessionId: null,
        recentQuestions: [],
    },
    aiDashboardReports: [],
}

export const GlobalContext = createContext({
    storeData: DEFAULT_STATE,
    setStoreData: () => null
});

export const GlobalContextWrapper = ({ children }) => {
    const [storeData, setStoreData] = useState(DEFAULT_STATE);
    return (
        <GlobalContext.Provider value={{ storeData, setStoreData }}>
            {children}
        </GlobalContext.Provider>
    );
};
