import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    postAIAssistant, getAIAssistantChatById, AIDashboardSave, getAIDashboard
} from './../../../BaseModels/MasterData';
import botBlueImage from './../HumBot.png';
import { cloneDeep } from 'lodash';
import { UDContext } from '../../../../App';
import UserMessage from '../UserMessage/UserMessage';
import BotAnswer from '../BotAnswer/BotAnswer';
import Tooltip from '@mui/material/Tooltip';
import { MdCreateNewFolder, MdDashboardCustomize } from "react-icons/md";
import { FaListCheck } from "react-icons/fa6";
import FeedbackForm from '../FeedbackForm/FeedbackFormSingle';
import { FaCheckCircle } from "react-icons/fa";
import { ImRadioUnchecked } from "react-icons/im";
import { v4 as uuid4 } from 'uuid';
import { GlobalContext } from '../../store';
import { delay, clearAIAssistantGraphs } from '../../../BaseModels/utility';
import st from './SingleWindow.module.css';

/**
 Message object:
 Structured:
 {
    "messageId",
    "sessionId",
    "createdTimestamp",
    "question": "string",
    "answer": "string",
    "sources": Array<Object>,
    "sqlQuery": "string",
    "tableData": Array<Object>,
    "shareFlag": boolean,
    "feedbackFlag": "Neutral|Like|Dislike",
    "feedbackMessage": "string",
    "status": "string",
    "animate": boolean,
    "completed": boolean,
}
 */



const SingleWindow = () => {
    const { storeData, setStoreData } = useContext(GlobalContext);
    const UserDetail = useContext(UDContext);

    const chatBoxRef = useRef(null);
    const promptRef = useRef(null);
    const [textMessage, setTextMessage] = useState('');
    const [IsLoading, setIsLoading] = useState(false);
    const [IsHistory, setIsHistory] = useState(false);
    const [queryType, setQueryTpe] = useState('unstructured');
    const [ChatPanelHeight, setChatPanelHeight] = useState(0);

    const { conversations, selectedSessionId, recentQuestions } = storeData.aiChats;
    const chatMessages = conversations[selectedSessionId] ? conversations[selectedSessionId].messages : [];
    const userName = String(UserDetail?.user?.name).trim().split(',').reverse()[0].trim();
    const userId = UserDetail?.user?.user_id;

    const location = useLocation();
    const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const useManualClassifier = location.pathname.toLocaleLowerCase() === '/ai-assistant';
    const useLgDataInsight = location.pathname.toLocaleLowerCase() === '/ai-assistant-lg-insight';
    const classifier = queryParams.get('classifier');


    const updateChatObj = (obj) => {
        setStoreData(_state => {
            return {
                ..._state,
                aiChats: {
                    ..._state.aiChats,
                    ...obj,
                }
            }
        })
    }

    const scrollChatToBottom = (ms = 500) => {
        setTimeout(() => {
            const el = chatBoxRef.current;
            if (!el) return;
            el.scrollTo({
                behavior: 'smooth',
                top: el.scrollHeight - el.clientHeight,
            });
        }, ms);
    }

    const scrollChatToTop = (ms = 500) => {
        setTimeout(() => {
            const el = chatBoxRef.current;
            if (!el) return;
            el.scrollTo({
                behavior: 'smooth',
                top: 0,
            });
        }, ms);
    }

    const focusPromptInput = () => {
        const el = promptRef.current;
        if (!el) return;
        el.focus();
    }

    useEffect(() => {
        // focus on first render
        focusPromptInput();
    }, []);

    useEffect(() => {
        // focus when loading changes
        if (!IsLoading)
            focusPromptInput();
    }, [IsLoading]);


    const btnSendMessage_onClick = async (defaultQuery = '') => {
        if (IsLoading) return;
        if (!defaultQuery && (!textMessage || !textMessage.trim())) {
            return;
        }
        setIsLoading(true);
        setIsHistory(false);
        const query = defaultQuery || textMessage.trim();
        let classifyEngine = 'manual';
        let runtimeEngine = 'langgraph';
        if (useLgDataInsight) {
            classifyEngine = 'manual';
            runtimeEngine = 'data-insight';
        }
        else if (useManualClassifier) {
            classifyEngine = 'manual';
            runtimeEngine = queryType;
        }
        else {
            if (classifier === '1')
                classifyEngine = 'bert';
            else if (classifier === '2')
                classifyEngine = 'openai';
        }

        const response = await postAIAssistant(query, classifyEngine, runtimeEngine, selectedSessionId || '');
        if (!response.status) {
            toast.error("Invalid response from API!");
            setIsLoading(false);
            return;
        }
        const messageId = response.data.message_id;
        const sessionId = response.data.session_id;
        const createdTimestamp = response.data.created_at;
        setStoreData((_state) => {
            const _aiChats = cloneDeep(_state.aiChats);
            if (!_aiChats.conversations[sessionId])
                _aiChats.conversations[sessionId] = {
                    "sessionId": sessionId,
                    "createdTimestamp": createdTimestamp,
                    "title": query,
                    "messages": []
                };
            _aiChats.conversations[sessionId].messages.push({
                "messageId": messageId,
                "sessionId": sessionId,
                "createdTimestamp": createdTimestamp,
                "shareFlag": false,
                "question": query,
                "feedbackFlag": "Neutral",
                "feedbackMessage": "",
                "completed": false,
                "animate": true,
            });
            _aiChats.selectedSessionId = sessionId;
            return { ..._state, aiChats: _aiChats };
        })
        setTextMessage('');
        const getChatUpdate = async (frequency, count = 0) => {
            await delay(frequency);
            const response = await getAIAssistantChatById(messageId);
            if (!response.status) {
                toast.error("Invalid response from API!");
                setIsLoading(false);
                setStoreData((_state) => {
                    const _aiChats = cloneDeep(_state.aiChats);
                    const conversationObj = _aiChats.conversations[sessionId];
                    const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
                    const messageObj = conversationObj.messages[messageIndex];
                    messageObj.completed = true;
                    return { ..._state, aiChats: _aiChats };
                })
                return;
            }
            const resObj = response.data;
            const completed = ['completed', 'errored'].includes(resObj.status) || count >= 75;
            setStoreData((_state) => {
                const _aiChats = cloneDeep(_state.aiChats);
                const conversationObj = _aiChats.conversations[sessionId];
                const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
                const messageObj = conversationObj.messages[messageIndex];
                messageObj.answer = resObj.response_text;
                messageObj.sqlQuery = resObj.response_query;
                messageObj.sources = resObj.response_sources ? JSON.parse(resObj.response_sources) : null;
                messageObj.tableData = resObj.response_table ? JSON.parse(resObj.response_table) : null;
                messageObj.completed = completed;
                messageObj.shareFlag = resObj.status === 'completed';
                return { ..._state, aiChats: _aiChats };
            })
            if (completed) {
                setIsLoading(false);
                focusPromptInput();
            }
            else
                getChatUpdate(frequency, count + 1);
        }
        getChatUpdate(2000); // every 2 seconds
    }

    const handleNewChat = () => {
        updateChatObj({ selectedSessionId: null });
        setTextMessage('');
        setIsHistory(false);
        clearAIAssistantGraphs();
    }

    const handleShowHistory = () => {
        if (Object.keys(conversations).length > 0) {
            setIsHistory(val => !val);
            scrollChatToTop(200);
        } else {
            toast.warn('No chat history found!', { autoClose: 2500 });
        }
    }

    const handleConversationSelect = (conversation) => {
        updateChatObj({ selectedSessionId: conversation.sessionId });
        setTextMessage('');
        setIsHistory(false);
        scrollChatToTop(200);
        clearAIAssistantGraphs();
    }

    const toggleShare = (sessionId, messageId) => {
        setStoreData((_state) => {
            const _aiChats = cloneDeep(_state.aiChats);
            const conversationObj = _aiChats.conversations[sessionId];
            const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
            const _msgObj = conversationObj.messages[messageIndex];
            _msgObj.shareFlag = !_msgObj.shareFlag;
            return { ..._state, aiChats: _aiChats };
        })
    }

    const aiDashboard = async (sessionId, messageId) => {
        const _aiChats = cloneDeep(storeData.aiChats);
        const conversationObj = _aiChats.conversations[sessionId];
        const messageIndex = conversationObj.messages.findIndex(x => x.messageId === messageId);
        const messageObj = conversationObj.messages[messageIndex];
        let content = messageObj?.tableData;
        if (content) {
            let _ColumnsList = [];
            Object.keys(content[0]).forEach((element, index) => {
                let objCol = {};
                objCol.name = element;
                objCol.type_name = "STRING";
                objCol.id = index;
                _ColumnsList.push(objCol);
            });
            let obj = {};
            obj["unique_id"] = uuid4();
            obj["conference_id"] = sessionId;
            obj["message_id"] = messageId;
            obj["title"] = messageObj?.question;
            obj["sub_title"] = messageObj?.question;
            obj["description"] = messageObj?.question;
            obj["columns_names"] = _ColumnsList;
            obj["graphType"] = -1;
            obj["yAxis"] = [];
            obj["xAxis"] = -1;
            let response = await AIDashboardSave(obj);
            if (response) {
                toast.success("AI Dashboard created successfully!");
                getAIDashboard()
                    .then((res) => {
                        if (res.status && Array.isArray(res.data) && res.data.length)
                            setStoreData(_state => ({ ..._state, aiDashboardReports: res.data }));
                    });
            }
        } else {
            toast.error("No data found to create AI Dashboard!");
        }
    }

    const historyPanel = (
        <div className={st.historyContainer}>
            <div className={st.historyBodyContainer}>
                {
                    Object.values(conversations)
                        .sort((a, b) => b.createdTimestamp.localeCompare(a.createdTimestamp))
                        .map((conversation) => {
                            return (
                                <div key={conversation.sessionId}
                                    className={`${st.historyItemContainer} ${conversation.sessionId === selectedSessionId ? st.historyItemContainerSelected : ''}`}
                                    onClick={() => handleConversationSelect(conversation)}
                                >
                                    <div className={st.historyItemMessage}>
                                        <span>{conversation.title}</span>
                                    </div>
                                </div>
                            );
                        })
                }
            </div>
        </div>
    )

    const chatPanel = (<>
        {!selectedSessionId &&
            <>
                <br />
                <div className={st.chatMesgRootContainer}>
                    <div className={st.cbmBotImageContainer}>
                        <img src={botBlueImage} alt="bot" />
                    </div>
                    <div className={`${st.cbmMessage} ${st.cbmMessageBot}`}>
                        Hi {userName}! I am your virtual agent.
                        <br />
                        How may I help you?
                    </div>
                </div>
                {
                    recentQuestions.length > 0 && (
                        <div className={`${st.chatMesgRootContainer} ${st.chatMesgRootContainerQuestion}`}>
                            <div className={st.cbmMessageQuestion}>
                                <div>Ask a question</div>
                                {
                                    recentQuestions.map((question, idx) => {
                                        return (
                                            <div key={idx}
                                                className={`${st.cbmMessage} ${st.defaultQuestion} `}
                                                onClick={() => btnSendMessage_onClick(question)}>
                                                {question}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </>
        }
        {
            chatMessages.map((message) => (
                <div key={message.messageId} className={st.cbMsgDetailsContainer}>
                    <div className={st.cbMsgDetailsActionItems}>
                        {
                            message.tableData && message?.tableData?.length > 0 &&
                            <div className={st.shareContainer}
                                onClick={() => aiDashboard(selectedSessionId, message.messageId)}
                            >
                                <div className={st.shareLabel}>
                                    <MdDashboardCustomize className={`${st.shareCheck} ${st.shareCheckYellow} zoomIn`} />
                                </div>
                                <strong>AI Dashboard</strong>
                            </div>
                        }
                        {
                            message.completed && !message.animate &&
                            <div className={st.shareContainer}>
                                <div className={st.shareLabel} onClick={() => toggleShare(selectedSessionId, message.messageId)}>
                                    {
                                        message.shareFlag ?
                                            <FaCheckCircle className={`${st.shareCheck} ${st.shareCheckGreen} zoomIn`} />
                                            :
                                            <ImRadioUnchecked className={`${st.shareCheck} ${st.shareCheckRed} zoomIn`} />
                                    }
                                    <strong>Share</strong>
                                </div>
                            </div>
                        }
                    </div>
                    <UserMessage content={message.question} username={userName} />
                    <BotAnswer message={message} scrollChatToBottom={scrollChatToBottom} />
                    {
                        message.completed && !message.animate &&
                        <FeedbackForm messageObj={message} />
                    }
                </div>
            ))
        }
    </>)

    useEffect(() => {
        const divTopMenu = document.getElementById('divTopMenu');
        if (divTopMenu) {
            setChatPanelHeight(window.innerHeight - (divTopMenu.clientHeight + 110 + 40));
        }
    }, []);

    return (
        <div className={st.rootContainer}>
            <div className={st.chatRootContainer} ref={chatBoxRef} style={{ height: ChatPanelHeight }}>
                {!IsHistory && chatPanel}
                {IsHistory && historyPanel}
            </div>
            <div className={st.AIAssistantFooterFormContainer}>
                <div className={st.chatActionButtonsRootContainer}>
                    <div className={st.actionButtonItem}>
                        <Tooltip title="Create New Chat" placement="top">
                            <IconButton size="small" onClick={handleNewChat} disabled={IsLoading}>
                                <MdCreateNewFolder className={st.actionButton} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={st.actionButtonItem}>
                        <Tooltip title="Chat History" placement="top">
                            <IconButton size="small" onClick={handleShowHistory} disabled={IsLoading}>
                                <FaListCheck className={st.actionButton} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {
                        useManualClassifier &&
                        <div className={st.selectQueryTypeContainer}>
                            <Tooltip title="Select query type" placement="left">
                                <Select
                                    value={queryType}
                                    onChange={(e) => setQueryTpe(e.target.value)}
                                    sx={{ minWidth: 130 }}
                                    variant='standard'
                                    disableUnderline
                                >
                                    <MenuItem value={'unstructured'}>Unstructured</MenuItem>
                                    <MenuItem value={'structured-genie'}>Genie</MenuItem>
                                    <MenuItem value={'structured-openai'}>OpenAI</MenuItem>
                                    <MenuItem value={'langgraph'}>Langgraph</MenuItem>
                                    <MenuItem value={'data-insight'}>Data Insight</MenuItem>
                                </Select>
                            </Tooltip>

                        </div>
                    }
                </div>
                <div className={st.controlRootContainer}>
                    {
                        !IsLoading ? <>
                            <textarea
                                ref={promptRef}
                                className={st.inputControl}
                                placeholder="Type your question here"
                                value={textMessage}
                                onChange={(e) => setTextMessage((e.target.value || '').replace(/(?:\r\n|\r|\n)$/g, ''))}
                                onKeyUp={(e) => { if (e.key === "Enter") btnSendMessage_onClick(); }}
                            ></textarea>
                            <IconButton aria-label="Go" size="large" className={st.buttonSave}
                                style={{
                                    backgroundColor: textMessage ? 'var(--primary-color-2)' : 'var(--gray-color-3)',
                                    cursor: textMessage ? 'pointer' : 'not-allowed',
                                }}
                                onClick={() => btnSendMessage_onClick()}
                            >
                                <SendIcon fontSize="inherit" />
                            </IconButton>
                        </> : <>
                            <span className={st.loader}></span>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default SingleWindow;