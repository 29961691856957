import React, { useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import { IoMdCloseCircle } from "react-icons/io";
import { getAIAssistantChatsAdmin } from '../../../BaseModels/MasterData'
import { toast } from 'react-toastify';
import { format as _formatDate } from 'date-fns';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import st from './AIAssistantFeedback.module.css';


const AIAssistantFeedback = () => {
    const [rowData, setRowData] = useState();
    const [IsDetailVisible, setIsDetailVisible] = useState(false);
    const [DetailData, setDetailData] = useState(null);


    // Column Definitions: Defines & controls grid columns.
    const colDefs = useMemo(() => [
        { field: 'runtime_engine', headerName: 'Type', sortable: true, filter: true, width: 110 },
        { field: 'query', headerName: 'Message', sortable: true, filter: true, width: 300, },
        {
            field: 'feedback_flag', headerName: 'Feedback Type', sortable: true, filter: true, width: 150,
            cellStyle: params => {
                if (params.value === 'Dislike') {
                    return { color: 'red' };
                } else if (params.value === 'Like') {
                    return { color: 'green' };
                } else {
                    return { color: 'blue' };
                }
            }
        },
        { field: 'feedback_message', headerName: 'Feedback', sortable: true, filter: true, width: 130, },
        { field: 'response_text', headerName: 'AI Response', sortable: true, filter: true, width: 400, },
        { field: 'created_by', headerName: 'User Id', sortable: true, filter: true, width: 110, },
        {
            headerName: 'Date',
            filter: 'agDateColumnFilter',
            sortable: true,
            width: 110,
            valueGetter: ({ data }) => new Date(data.created_at),
            valueFormatter: ({ value }) => _formatDate(value, 'MM/dd/yyyy'),
        },
        {
            headerName: 'Action', field: 'rawData',
            cellRenderer:
                function (params) {
                    return (
                        <>
                            <strong className={st.showDetail}
                                onClick={() => btnShowDetail_onClick(params.value)}
                            >Show</strong>
                        </>
                    )
                },
            sortable: false,
            filter: false,
            width: 110,
            pinned: 'right'
        }
    ], []);

    const defaultColDef = useMemo(() => {
        return {
            width: 170,
            filter: true,
            editable: false,
            sortable: true
        }
    }, []);

    useEffect(() => {
        getAIAssistantChatsAdmin()
            .then((response) => {
                if (!response.status) {
                    toast.error('Could not fetch chat history from API');
                    setRowData([]);
                    return;
                }
                let _rowData = [];
                response.data.forEach(element => {
                    let obj = {};
                    obj = Object.assign({}, element);
                    obj.rawData = Object.assign({}, element);
                    _rowData.push(obj);
                });
                setRowData(_rowData);
            })
    }, [])

    const btnShowDetail_onClick = (data) => {
        try {
            let obj = {};
            obj = Object.assign({}, data);
            obj["response_table"] = obj.response_table ? JSON.parse(obj.response_table) : [];
            setDetailData(obj);
            setIsDetailVisible(true);
        } catch (error) {
            toast.error(error.message);
        }
    }

    return (
        <>
            <div className={"ag-theme-quartz"} style={{ width: '100%', minHeight: '300px', height: 'calc(100vh - 200px)' }}>
                <AgGridReact rowData={rowData} columnDefs={colDefs} defaultColDef={defaultColDef} />
            </div>
            {
                DetailData && <ModalPopup IsVisible={IsDetailVisible} onClose={() => { setIsDetailVisible(false); setDetailData(null) }}>
                    <div className={st.chatDetailRootContainer}>
                        <div className={st.chatDetailHeader}>
                            <div className={st.userName}>
                                {DetailData?.created_by}
                            </div>
                            <IoMdCloseCircle className={st.closeIcon} onClick={() => { setIsDetailVisible(false); setDetailData(null) }} />
                        </div>
                        <div className={st.chatDetailBody}>
                            {DetailData.query ? <h4>{DetailData?.query}</h4> : ''}
                            {DetailData.response_text ? <p>{DetailData.response_text}</p> : ''}
                            {DetailData?.response_query ?
                                <details>
                                    <summary>Query</summary>
                                    <p>{DetailData?.response_query}</p>
                                </details>
                                : ''}
                            {
                                DetailData?.response_table?.length > 0 && (
                                    <>
                                        <br />
                                        <table className="table table-bordered table-striped table-hover table-sm">
                                            <thead>
                                                <tr>
                                                    {Object.keys(DetailData.response_table[0]).map((key, index) => (
                                                        <th key={index}>{key}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {DetailData?.response_table?.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {Object.values(row).map((value, cellIndex) => (
                                                            <td key={cellIndex}>{value}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>
                                )
                            }
                            <div style={
                                DetailData?.feedback_flag === "Like" ?
                                    { color: 'green' } :
                                    DetailData?.feedback_flag === "Dislike" ?
                                        { color: 'red' } :
                                        { color: 'blue' }
                            }>
                                <h5>Feedback: {DetailData?.feedback_flag}</h5>
                                <strong>{DetailData?.feedback_message}</strong>
                            </div>
                        </div>
                        <div className={st.chatDetailFooter}></div>
                    </div>
                </ModalPopup>
            }

        </>
    );
};

export default AIAssistantFeedback;