import { parse as _parseDate, format as _formatDate } from 'date-fns';
import { toast } from 'react-toastify';

/**
 * Returns a promise that resolves after given milliseconds 
 * @param {number} ms
 * @returns 
 */
export const delay = ms => new Promise(res => setTimeout(res, ms));


export const testURL = (str) => {
    const urlRegex = new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/is);
    return Boolean(str.match(urlRegex));
}


export const formatHeader = (name) => {
    let newName = "";
    if (name) {
        name = String(name);
        let nameArray = name.split('_');
        nameArray.forEach(word => {
            if (word) {
                if (word === word.toUpperCase()) {
                    newName += word + " ";
                } else {
                    newName += word.charAt(0).toUpperCase() + word.slice(1) + " ";
                }
            }
        });
        newName = String(newName).trim();
    }
    if (newName === "") {
        newName = name;
        return newName;
    } else {
        return newName;
    }
};


export const formatDate = (dateString, inputFormat, outputFormat) => {
    if (!dateString) return '';
    const date = _parseDate(dateString, inputFormat, new Date());
    if (isNaN(date)) return '';
    return _formatDate(date, outputFormat);
}


export const formatNumber = (number, reference=undefined) => {
    if (!reference)
        reference = number;
    if (reference < 1)
        return (number).toLocaleString('en-US', {maximumFractionDigits:3});
    if (reference < 10)
        return (number).toLocaleString('en-US', {maximumFractionDigits:2});
    if (reference < 100)
        return (number).toLocaleString('en-US', {maximumFractionDigits:1});
    if (reference < 1000)
        return (number).toLocaleString('en-US', {maximumFractionDigits:0});
    if (reference < 1000_000)
        return (number / 1000).toLocaleString('en-US', {maximumFractionDigits:1}) + 'K';
    if (reference < 1000_000_000)
        return (number / 1000_000).toLocaleString('en-US', {maximumFractionDigits:1}) + 'M';
    return (number / 1000_000_000).toLocaleString('en-US', {maximumFractionDigits:1}) + 'B';
}


export const updateAIAssistantGraph = (message_id, image_data) => {
    const imageKey = `ai_assistant_graph_image_data_${message_id}`;
    try {
        sessionStorage.setItem(imageKey, image_data);
    }
    catch {
        toast.error('Internal error! Chat share feature may be impacted. Please inform admin.');
    }
}


export const getAIAssistantGraph = (message_id) => {
    const imageKey = `ai_assistant_graph_image_data_${message_id}`;
    return sessionStorage.getItem(imageKey);
}


export const clearAIAssistantGraphs = () => {
    Array.from(Object.keys(sessionStorage)).forEach((_key) => {
        if (_key.startsWith('ai_assistant_graph_image_data_'))
            sessionStorage.removeItem(_key);
    })
}
