import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { MultiSelect } from 'react-multi-select-component';
import Tooltip from '@mui/material/Tooltip';
import * as _ from 'lodash';
import { getReportAccessInfo, getUserAccessInfo, setReportAccessInfo } from '../../BaseModels/MasterData';
import { useContainerDimensions } from '../../BaseModels/ResizeWindow';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import st from './ReportAccess.module.css';


const CustomCheckBox = ({ checkedState, onClick }) => {
    let icon = null;
    if (checkedState === 'all')
        icon = <i className='fa-solid fa-square-check' style={{ color: '#2196F3' }}></i>
    else if (checkedState === 'some')
        icon = <i className='fa-solid fa-square-minus' style={{ color: '#2196F3' }}></i>
    else if (checkedState === 'none')
        icon = <i className='fa-regular fa-square' style={{ color: '#2196F3' }}></i>
    return (
        <div className={st.checkbox_container} onClick={onClick} >
            {icon}
        </div>
    )
}

const getCheckBoxState = (count, total) => {
    let cbState = '';
    if (count === 0) cbState = 'none';
    else if (count < total) cbState = 'some';
    else if (count === total) cbState = 'all';
    return cbState;
}

const getCheckBoxStateRow = (accessById, visibleReportIds) => {
    let _count = 0;
    let _total = 0;
    visibleReportIds.forEach((reportId) => {
        _total++;
        if (accessById[reportId])
            _count++;
    });
    return getCheckBoxState(_count, _total);
}

const getReportIds = (api) =>
    api.getColumnDefs().map(x => x.field)
        .filter(x => x.startsWith('accessById.')).map(x => Number(x.split('.')[1]));

const getVisibleReportIds = (api) =>
    api.getColumnDefs().filter(x => x.field.startsWith('accessById.') && !x.hide)
        .map(x => x.field).map(x => Number(x.split('.')[1]));

const selectedReportsValueRenderer = (selected, options) => {
    let label = '';
    if (selected.length === 0)
        label = 'Select reports';
    else if (selected.length === options.length)
        label = 'All reports selected';
    else
        label = `${selected.length} report(s) selected`;
    return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <span> {label} </span>
        </div>
    )
};


const ReportAccess = () => {
    const { height } = useContainerDimensions();
    const [gridApi, setGridApi] = useState(null);
    const [gridContext, setGridContext] = useState({});
    const [reportAccessList, setReportAccessList] = useState([]);
    const [reportAccessColumns, setReportAccessColumns] = useState([]);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [quickFilter, setQuickFilter] = useState('');
    const [selectedReports, setSelectedReports] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);

    const CustomHeader = ({ displayName, showColumnMenu, enableMenu, column, api, context }) => {
        const refButton = useRef(null);
        const [isHovering, setIsHovering] = useState(false);
        const reportId = column.colId.split('.')[1];
        const [title, tooltip] = displayName.split('<[|]>');

        const onClick = () => {
            setSaveEnabled(true);
            const newRows = [];
            const checkedState = context.colChecked[reportId].checkedState;
            const newValue = checkedState === 'all' ? false : true;
            const visibleReportIds = new Set(getVisibleReportIds(api));
            api.forEachNodeAfterFilter((node) => {
                const _data = _.cloneDeep(node.data);
                _data.accessById[reportId] = newValue;
                _data.accessState = getCheckBoxStateRow(_data.accessById, visibleReportIds);
                newRows.push(_data);
            });
            api.applyTransaction({ update: newRows });
            const newContext = _.cloneDeep(context);
            newContext.colChecked[reportId] = {
                checkedState: newValue ? 'all' : 'none',
                checkedCount: newValue ? context.rowCount : 0,
            };
            api.setGridOption('context', newContext);
            api.refreshHeader();
        }

        return (
            <div className={st.custom_header_container}
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
            >
                <div className={st.custom_header_toolbar} >
                    <CustomCheckBox checkedState={context.colChecked[reportId].checkedState} onClick={onClick} />
                    {
                        enableMenu &&
                        <div ref={refButton}
                            className={st.custom_header_filter}
                            onClick={() => showColumnMenu(refButton.current)}
                        >
                            <i className='fa fa-bars'
                                style={{ visibility: isHovering ? 'visible' : 'hidden' }}
                            ></i>
                        </div>
                    }
                </div>
                <Tooltip title={<b>{tooltip}</b>}>
                    <div className={st.custom_header_label} onClick={onClick} >{title}</div>
                </Tooltip>
            </div>
        );
    };

    const onCellClicked = useCallback(({ context, colDef, api, node }) => {
        if (colDef.field.startsWith('accessById.')) {
            setSaveEnabled(true);
            const reportId = colDef.field.split('.')[1];
            const newValue = !node.data.accessById[reportId];
            const newRow = _.cloneDeep(node.data);
            newRow.accessById[reportId] = newValue;
            const visibleReportIds = new Set(getVisibleReportIds(api));
            newRow.accessState = getCheckBoxStateRow(newRow.accessById, visibleReportIds);
            const countChange = newValue ? 1 : -1;
            const _count = context.colChecked[reportId].checkedCount + countChange;
            const _state = getCheckBoxState(_count, context.rowCount);
            const newContext = _.cloneDeep(context);
            newContext.colChecked[reportId] = {
                checkedState: _state,
                checkedCount: _count,
            };
            api.applyTransaction({ update: [newRow] });
            api.setGridOption('context', newContext);
            api.refreshHeader();
        }
        else if (colDef.field === 'accessState') {
            setSaveEnabled(true);
            const newValue = node.data.accessState === 'all' ? false : true;
            const newRow = _.cloneDeep(node.data);
            newRow.accessState = newValue ? 'all' : 'none';
            const newContext = _.cloneDeep(context);;
            const changeCount = newValue ? 1 : -1;
            const visibleReportIds = new Set(getVisibleReportIds(api));
            context.reportIds.forEach((reportId) => {
                if (!visibleReportIds.has(reportId)) return;
                newRow.accessById[reportId] = newValue;
                const _count = context.colChecked[reportId].checkedCount
                    + (newValue === node.data.accessById[reportId] ? 0 : changeCount);
                const _state = getCheckBoxState(_count, context.rowCount);
                newContext.colChecked[reportId] = {
                    checkedState: _state,
                    checkedCount: _count,
                };
            });
            api.applyTransaction({ update: [newRow] });
            api.setGridOption('context', newContext);
            api.refreshHeader();
        }
    }, [setSaveEnabled]);

    const handleSave = useCallback(async () => {
        if (!gridApi) return;
        setIsRequestPending(true);
        const reportIds = getReportIds(gridApi);
        const diffData = [];
        const newAccessData = [];
        const colAccessCount = {};
        reportAccessList.forEach((item) => {
            const userId = item.userId;
            const node = gridApi.getRowNode(userId);
            const newNodeData = _.cloneDeep(node.data);
            const restrictedReportIds = [];
            let hasValueChanged = false;
            let _rowAccessCount = 0;
            reportIds.forEach((reportId) => {
                const newAccess = newNodeData.accessById[reportId];
                const oldAccess = item.accessById[reportId];
                if (newAccess) {
                    _rowAccessCount++;
                    restrictedReportIds.push(reportId);
                }
                if (oldAccess !== newAccess)
                    hasValueChanged = true;
                colAccessCount[reportId] = (newAccess ? 1 : 0) + (colAccessCount[reportId] || 0);
            });
            newNodeData.accessState = getCheckBoxState(_rowAccessCount, reportIds.length);
            if (hasValueChanged)
                diffData.push({
                    'user_id': userId,
                    'restricted_access': restrictedReportIds,
                });
            newAccessData.push(newNodeData);
        });
        const rowCount = reportAccessList.length;
        const colChecked = {};
        reportIds.forEach((reportId) => {
            const _count = colAccessCount[reportId];
            const _state = getCheckBoxState(_count, rowCount);
            colChecked[reportId] = {
                checkedState: _state,
                checkedCount: _count,
            };
        });
        const newContext = {
            rowCount,
            reportIds,
            colChecked,
        };
        const columnDefs = _.cloneDeep(gridApi.getColumnDefs());
        columnDefs.forEach((x) => {
            x.hide = false;
        });
        if (diffData.length) {
            const response = await setReportAccessInfo(diffData);
            if (response.status) {
                setReportAccessList(newAccessData);
                setQuickFilter('');
                setSelectedReports([]);
                gridApi.setFilterModel(null);
                gridApi.setGridOption("columnDefs", columnDefs);
                gridApi.setGridOption('quickFilterText', '');
                gridApi.setGridOption('context', newContext);
                gridApi.refreshHeader();
                toast.success('Changes were saved to the database');
                setSaveEnabled(false);
            }
            else {
                toast.error('Error while saving changes to the database');
            }
        }
        else {
            toast.info('No changes were made');
            setSaveEnabled(false);
        }
        setIsRequestPending(false);

    }, [gridApi, setSaveEnabled, setIsRequestPending, reportAccessList, setReportAccessList]);

    const handleCancel = useCallback(() => {
        if (!gridApi) return;
        setReportAccessList(_.cloneDeep(reportAccessList));
        gridApi.setFilterModel(null);
        const reportIds = getReportIds(gridApi);
        const colAccessCount = {};
        reportAccessList.forEach((item) => {
            reportIds.forEach((reportId) => {
                const access = item.accessById[reportId];
                colAccessCount[reportId] = (access ? 1 : 0) + (colAccessCount[reportId] || 0);
            });
        });
        const rowCount = reportAccessList.length;
        const colChecked = {};
        reportIds.forEach((reportId) => {
            const _count = colAccessCount[reportId];
            const _state = getCheckBoxState(_count, rowCount);
            colChecked[reportId] = {
                checkedState: _state,
                checkedCount: _count,
            };
        });
        const newContext = {
            rowCount,
            reportIds,
            colChecked,
        };
        const columnDefs = _.cloneDeep(gridApi.getColumnDefs());
        columnDefs.forEach((x) => {
            x.hide = false;
        });
        setQuickFilter('');
        setSelectedReports([]);
        gridApi.setGridOption("columnDefs", columnDefs);
        gridApi.setGridOption('quickFilterText', '');
        gridApi.setGridOption('context', newContext);
        gridApi.refreshHeader();
        toast.success('Changes have been discarded');
        setSaveEnabled(false);
    }, [gridApi, setSaveEnabled, reportAccessList, setReportAccessList, setQuickFilter]);

    const onGridReady = useCallback(({ api }) => {
        setGridApi(api)
    }, []);

    const onQuickFilterChange = useCallback((e) => {
        if (!gridApi) return;
        const searchKey = e.target.value;
        setQuickFilter(searchKey);
        gridApi.setGridOption('quickFilterText', searchKey);
    }, [gridApi, setQuickFilter]);

    const onFilterChanged = useCallback(({ api, context }) => {
        const reportIds = context.reportIds.slice();
        const colAccessCount = {};
        let visibleRowCount = 0;
        api.forEachNodeAfterFilter((node) => {
            visibleRowCount++;
            reportIds.forEach((reportId) => {
                const access = node.data.accessById[reportId];
                colAccessCount[reportId] = (access ? 1 : 0) + (colAccessCount[reportId] || 0);
            });
        });
        const colChecked = {};
        reportIds.forEach((reportId) => {
            const _count = colAccessCount[reportId];
            const _state = getCheckBoxState(_count, visibleRowCount);
            colChecked[reportId] = {
                checkedState: _state,
                checkedCount: _count,
            }
        });
        const newContext = {
            rowCount: visibleRowCount,
            reportIds,
            colChecked,
        };
        api.setGridOption('context', newContext);
        api.refreshHeader();
    }, []);

    const onSelectedReportsChanged = useCallback((newValue) => {
        if (!gridApi) return;
        const visibleReportIds = newValue.length ?
            new Set(newValue.map(x => x.value)) : new Set(getReportIds(gridApi));
        const columnDefs = _.cloneDeep(gridApi.getColumnDefs());
        columnDefs.forEach((x) => {
            if (!x.field.startsWith('accessById.')) return;
            const reportId = Number(x.field.split('.')[1]);
            x.hide = !visibleReportIds.has(reportId);
        });
        const newRows = [];
        gridApi.forEachNodeAfterFilter((node) => {
            const _data = _.cloneDeep(node.data);
            _data.accessState = getCheckBoxStateRow(_data.accessById, visibleReportIds);
            newRows.push(_data);
        });
        gridApi.applyTransaction({ update: newRows });
        gridApi.setGridOption("columnDefs", columnDefs);
        setSelectedReports(newValue);
    }, [gridApi]);

    useEffect(() => {
        const ac = new AbortController();
        Promise.all([getUserAccessInfo(), getReportAccessInfo(ac.signal)])
            .then((values) => {
                if (!values.every(x => x.status)) return;
                const userData = values[0]['data'];
                const reportsInfo = values[1]['data']['reports_info'];
                const userAccessData = values[1]['data']['user_reports_mapping'];
                const reportIds = reportsInfo.map(x => x['report_id']);
                const accessTemplate = {};
                const colAccessCount = {};
                reportIds.forEach((reportId) => {
                    accessTemplate[reportId] = false;
                    colAccessCount[reportId] = 0;
                })
                const rowData = userData.map((x) => {
                    const userId = x['user_id'];
                    const userName = x['user_name'];
                    const userAccessReports = userAccessData[userId] || [];
                    const accessById = _.clone(accessTemplate);
                    userAccessReports.forEach((reportId) => {
                        colAccessCount[reportId] += 1;
                        accessById[reportId] = true;
                    });
                    const accessState = getCheckBoxState(userAccessReports.length, reportIds.length);
                    return {
                        userId,
                        userName,
                        accessById,
                        accessState,
                    };
                });
                const colChecked = {};
                const colDefs = [{
                    headerName: '',
                    field: 'accessState',
                    pinned: 'left',
                    initialWidth: 50,
                    cellDataType: 'text',
                    cellRenderer: (({ value }) => {
                        return (
                            <CustomCheckBox checkedState={value} />
                        )
                    }),
                    sortable: false,
                    filter: false,
                    editable: false,
                    resizable: false,
                },
                {
                    headerName: 'User ID',
                    field: 'userId',
                    pinned: 'left',
                    initialWidth: 120,
                    cellDataType: 'text',
                    sortable: true,
                    filter: true,
                    editable: false,
                },
                {
                    headerName: 'User Name',
                    field: 'userName',
                    initialWidth: 200,
                    cellDataType: 'text',
                    sortable: true,
                    filter: true,
                    editable: false,
                }];
                reportsInfo.forEach((report) => {
                    const reportId = report['report_id'];
                    const _count = colAccessCount[reportId];
                    const _state = getCheckBoxState(_count, rowData.length);
                    colChecked[reportId] = {
                        checkedState: _state,
                        checkedCount: _count,
                    };
                    colDefs.push({
                        headerName: `${report['title']}<[|]>${report['page_name']} > ${report['section_name']}`,
                        field: `accessById.${reportId}`,
                        initialWidth: 110,
                        cellDataType: 'boolean',
                        cellRenderer: (({ value }) => {
                            return (
                                <CustomCheckBox checkedState={value ? 'all' : 'none'} />
                            )
                        }),
                        sortable: false,
                        filter: true,
                        editable: false,
                        headerComponent: CustomHeader,
                    });
                });
                const newContext = {
                    rowCount: rowData.length,
                    reportIds,
                    colChecked,
                };
                const newReportOptions = reportsInfo.map(x => ({ value: x['report_id'], label: x['title'] }));
                setReportOptions(newReportOptions);
                setGridContext(newContext);
                setReportAccessColumns(colDefs);
                setReportAccessList(rowData);
            });
        return () => ac.abort();
    }, []);

    return (
        <>
            <BackendTopMenu />
            <div className='mainContainer'>
                <div className='row mt-2 mb-2'>
                    <div className='col-lg-6'>
                        <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
                            <Link className={st.navigate_item} to={'/backend'}>Admin Home</Link>
                            <Link className={st.navigate_item} to={'/backend/reportAccess'}>Report Access</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`${st.page_title} col-12`}>Report Access</div>
                </div>
                <div className='row mt-2 mb-1' >
                    <div className={`col-md-4 mb-2`}>
                        <TextField
                            className={`${st.table_header_user_search} col-6`}
                            variant='outlined'
                            placeholder='Search user ID / name'
                            value={quickFilter}
                            onChange={onQuickFilterChange}
                            disabled={!reportAccessList.length}
                            size='small'
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='off'
                            spellCheck={false}
                        />
                    </div>
                    <div className={`col-md-4 mb-2`}>
                        <MultiSelect
                            className={`${st.table_header_report_search} col-6`}
                            options={reportOptions}
                            value={selectedReports}
                            onChange={onSelectedReportsChanged}
                            labelledBy='Select reports'
                            disabled={!reportOptions.length}
                            valueRenderer={selectedReportsValueRenderer}
                        />
                    </div>
                    <div className={`col-md-4 mb-2`}>
                        <div className={st.table_button_container}>
                            <Button variant='outlined'
                                sx={{ minWidth: '74px', height: '31px!important' }}
                                onClick={handleCancel}
                                disabled={!saveEnabled || isRequestPending}
                                endIcon={<i className='fa-solid fa-cancel' />}
                                size='small'
                                color='primary'
                            >
                                <b>Cancel</b>
                            </Button>
                            <Button variant='contained'
                                sx={{ minWidth: '74px', height: '31px!important' }}
                                onClick={handleSave}
                                disabled={!saveEnabled || isRequestPending}
                                endIcon={isRequestPending ?
                                    <CircularProgress size='18px' sx={{ mr: '8px' }} /> :
                                    <i className='fa-solid fa-save' />
                                }
                                size='small'
                                color='primary'
                            >
                                {isRequestPending ? null : <b>Save</b>}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className={`aggrid-header ${st.table_wrapper}`}>
                            <div
                                style={{ height: (height - 220) + 'px', maxHeight: '70vh', minHeight: '300px', initialWidth: '100%' }}
                                className='ag-theme-quartz'>
                                <AgGridReact
                                    rowData={reportAccessColumns.length ? reportAccessList : null}
                                    columnDefs={reportAccessColumns.length ? reportAccessColumns : null}
                                    context={gridContext}
                                    onGridReady={onGridReady}
                                    onCellClicked={onCellClicked}
                                    onFilterChanged={onFilterChanged}
                                    getRowId={x => x.data.userId}
                                    headerHeight={150}
                                    maintainColumnOrder={true}
                                    suppressRowHoverHighlight
                                    suppressRowClickSelection
                                    defaultColDef={{ suppressMovable: true }}
                                />
                            </div>
                            {isRequestPending && <div className={st.table_overlay}></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportAccess;
