import IconButton from '@mui/material/IconButton';
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FaDownload } from "react-icons/fa";
import { RiRefreshLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { updateAIAssistantGraph } from '../../../../BaseModels/utility';

const colors = ['#01234a', '#077a9d', '#6B318A', '#6C5EB7', '#5a8db1', '#3372aa', '#00338d', '#490b3e', '#91300a', '#289d7c', '#4682B4', '#00CED1', '#2E8B57', '#6A5ACD', '#5F9EA0', '#48D1CC', '#B8860B', '#00BFFF', '#DAA520', '#8FBC8F', '#556B2F'];

const PieGraph = ({ ChartData }) => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, ArcElement, ChartDataLabels, Title, Tooltip, Legend);
    const [options, setOptions] = useState();
    const [data, setData] = useState([]);
    const [IsGraphChartVisible, setIsGraphChartVisible] = useState(false);
    const [IsDownloadLoading, setIsDownloadLoading] = useState(false);

    useEffect(() => {
        try {
            if (ChartData) {
                let _data = [];
                if (ChartData.RowData.length > 0) {
                    if (ChartData.IsAggregate && Object.keys(ChartData.RowData[0]).length > 1) {
                        let groupBy_XAxisData = _.mapValues(_.groupBy(ChartData.RowData, ChartData.XAxisSelectedValue.value), clist => clist.map(car => _.omit(car, ChartData.XAxisSelectedValue.value)));
                        let groupBy_XAxisKeysList = Object.keys(groupBy_XAxisData);
                        groupBy_XAxisKeysList.forEach(xKey => {
                            let obj = {};
                            obj[ChartData.XAxisSelectedValue.value] = xKey;
                            let xValueList = groupBy_XAxisData[xKey];
                            ChartData.YAxisSelectedList.forEach(yAxisObj => {
                                let yKey = yAxisObj.value;
                                let yVal = _.sumBy(xValueList, yKey);
                                switch (ChartData.AggregateType) {
                                    case 'sum':
                                        yVal = _.sumBy(xValueList, yKey);
                                        break;
                                    case 'avg':
                                        yVal = _.meanBy(xValueList, yKey);
                                        break;
                                    case 'min':
                                        yVal = _.minBy(xValueList, yKey)[yKey];
                                        break;
                                    case 'max':
                                        yVal = _.maxBy(xValueList, yKey)[yKey];
                                        break;
                                    case 'count':
                                        yVal = xValueList.length;
                                        break;
                                    default:
                                        yVal = _.sumBy(xValueList, yKey);
                                        break;
                                }
                                obj[yKey] = (yVal !== '' && yVal !== null && yVal !== undefined) ?
                                    (typeof yVal === 'number' ?
                                        (parseFloat(yVal.toFixed(2)) % 1 === 0 ? Math.round(yVal) : parseFloat(yVal.toFixed(2)))
                                        : null)
                                    : null;
                            });
                            _data.push(obj);
                        });
                    } else {
                        _data = ChartData.RowData;
                    }
                    let _options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                backgroundColor: function (context) {
                                    return context.dataset.backgroundColor;
                                },
                                borderRadius: 1,
                                color: 'white',
                                font: {
                                    weight: 'bold',
                                    size: 11
                                },
                                padding: 1
                            },
                            legend: {
                                display: false,
                                position: 'right',
                                labels: {
                                    font: {
                                        size: 11,
                                        weight: 'bold'
                                    }
                                }
                            },
                        },
                        scales: {
                            x: {
                                display: false
                            }, y: {
                                display: false
                            }
                        },
                        layout: {
                            padding: {
                                top: 10,
                                right: 10,
                                bottom: 50,
                                left: 10
                            }
                        }
                    };
                    setOptions(_options);
                    let _datasets = [];
                    ChartData.YAxisSelectedList.forEach(element => {
                        let obj = {};
                        obj.label = element.name;
                        obj.data = DataRowsFormatting(_data, element.value);
                        obj.borderColor = "#ffffff";
                        if (ChartData.YAxisSelectedList.length > 1) {
                            obj.datalabels = {
                                align: 'center',
                                anchor: 'center'
                            }
                        } else {
                            obj.datalabels = {
                                anchor: 'end'
                            }
                        }
                        obj.backgroundColor = _data.map((item, index) => { return colors[index % colors.length]; });
                        obj.hoverBackgroundColor = _data.map((item, index) => { return (colors[index % colors.length]) + "95"; });
                        _datasets.push(obj);
                    });
                    let _ChartXAxis = _data.map((item) => item[ChartData.XAxisSelectedValue.value]);
                    setData({
                        labels: _ChartXAxis,
                        datasets: _datasets,
                    });
                    setIsGraphChartVisible(true);
                    downloadChart();
                }
            }
        } catch (error) {

        }
    }, [ChartData]);

    const downloadChart = () => {
        try {
            setTimeout(() => {
                const canvasSave = document.getElementById('stackD' + ChartData.msg_id);
                if (!canvasSave) return;
                canvasSave.toBlob(function (blob) {
                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        updateAIAssistantGraph(ChartData.msg_id, base64data);
                    }
                })
            }, 500);
        } catch (error) {

        }
    }

    const DataRowsFormatting = (_dataRow, ColumnName) => {
        let _data = [];
        try {
            let _dataRowMap = _dataRow.map((item, index) => { return item[ColumnName]; });
            _dataRowMap.forEach(element => {
                if (element) {
                    let newValue = String(element).replace(/[^0-9\.-]/g, "");
                    if (newValue === "") _data.push(null);
                    else _data.push(Number(newValue));
                } else {
                    _data.push(element);
                }
            });
        } catch (error) {
            toast.error("Error in ScatterGraph DataRowsFormatting: ");
        }
        return _data;
    }

    const btnDownloadGraph_onClick = async () => {
        try {
            setIsDownloadLoading(true);
            const canvasSave = document.getElementById('stackD' + ChartData.msg_id);
            if (!canvasSave) return;
            canvasSave.toBlob(function (blob) {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    var a = document.createElement("a");
                    a.href = base64data;
                    a.download = "Image" + ChartData.msg_id + ".png";
                    a.click();
                }
            })
            setTimeout(() => { setIsDownloadLoading(false); }, 3000);
        } catch (error) {
            setIsDownloadLoading(false);
        }
    }

    return (
        IsGraphChartVisible &&
        <>
            <IconButton color="primary" onClick={btnDownloadGraph_onClick} size="small"
                style={{ position: 'absolute', right: '16px', marginTop: '-20px' }} disabled={IsDownloadLoading}>
                {IsDownloadLoading ? <RiRefreshLine className='rotate-center' /> : <FaDownload />}
            </IconButton>
            <div style={{ height: '100%', width: 'auto', aspectRatio: '1/1', display: 'flex', justifyContent: 'center', alignItems: 'center', resize: 'both' }}>
                <Doughnut id={'stackD' + ChartData.msg_id} data={data} plugins={[ChartDataLabels]} options={options} width={"100%"} height={"100%"} />
            </div>
        </>
    );
};

export default PieGraph;