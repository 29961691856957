import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload } from "react-icons/fa";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatHeader } from './../../../../../BaseModels/utility';
import { exportToExcel } from 'react-json-to-excel';
import st from './graphs.module.css';

const TableGraph = ({ ChartData }) => {
    const gridRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [IsGraphChartVisible, setIsGraphChartVisible] = useState(false);

    useEffect(() => {
        if (ChartData) {
            let _rowData = [];
            let _columnDefs = [];
            _rowData = ChartData.RowData;
            if(ChartData.RowData.length > 0){
                Object.keys(ChartData.RowData[0]).forEach(key => {
                    _columnDefs.push({
                        headerName: formatHeader(key),
                        field: key
                    });
                });
            }
            setRowData(_rowData);
            setColumnDefs(_columnDefs);
            setIsGraphChartVisible(true);
        }
    }, [ChartData]);

    const btnDownloadGraph_onClick = async () => {
        if (gridRef) {
            if (gridRef.current) {
                exportToExcel(ChartData.RowData, ChartData.msg_id);
            }
        }
    }

    return (
        IsGraphChartVisible &&
        <>
            <IconButton color="primary" onClick={btnDownloadGraph_onClick} size="small"
                style={{ position: 'absolute', right: '16px', marginTop: '-22px' }}>
                <FaDownload />
            </IconButton>
            <div className="ag-theme-alpine" style={{ height: '95%', width: '100%' }} ref={gridRef}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={{ sortable: true, filter: true }}
                />
            </div>
        </>
    );
};

export default TableGraph;