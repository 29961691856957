import React, { createContext, useEffect, useState, useContext } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import AdminAccess from "./Component/Backend/AdminAccess/AdminAccess";
import Backend from "./Component/Backend/Backend";
import Dashboards from "./Component/Backend/Dashboards/Dashboards";
import SaveDashboard from "./Component/Backend/Dashboards/SaveDashboard/SaveDashboard";
import NavigationOrder from './Component/Backend/NavigationOrder/NavigationOrder';
import News from './Component/Backend/News/News';
import SaveNews from "./Component/Backend/News/SaveNews/SaveNews";
import LandingPageCreation from "./Component/Backend/PageCreations/LandingPageCreation/LandingPageCreation";
import PageDesigner from "./Component/Backend/PageCreations/PageDesigner/PageDesigner";
import PageList from "./Component/Backend/PageCreations/PageList/PageList";
import { BaseUrlAPI, Matomo_SiteId, getTokenByPublicKey, getUserDataByUser, getUserInfo, getValidateToken, getAIDashboard } from './Component/BaseModels/MasterData';
import Dashboard from './Component/CommonComponents/Dashboard/Dashboard';
import LandingPage from './Component/CommonComponents/LandingPage/LandingPage';
import Authentication from "./Component/CommonComponents/PageNotFound/Authentication/Authentication";
import PageNotFound from './Component/CommonComponents/PageNotFound/PageNotFound';
import NewsLetter from './Component/CommonComponents/TopMenu/NewsLetter/NewsLetter';
import LoadingImage from "./Component/CustomElements/LoadingPage/LoadingImage";
import './amination.css';
import MatomoGraphs from "./Component/Backend/MatomoGraphs/MatomoGraphs";
import AIDashboard from "./Component/CommonComponents/Dashboard/AIDashboard/AIDashboard";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReportAccess from "./Component/Backend/ReportAccess/ReportAccess";
import ChatBot from "./Component/CommonComponents/ChatBot/ChatBot";
import TableauAlert from "./Component/Backend/TableauAlert/TableauAlert";
import TableauAlertOne from "./Component/Backend/TableauAlert/TableauAlertOne";
import AIAssistantAdmin from "./Component/Backend/AIAssistantAdmin/AIAssistantAdmin";
import ExportReportBuilderDataList from "./Component/CommonComponents/Dashboard/ExportReportBuilderDataList/ExportReportBuilderDataList";

import { GlobalContext } from "./Component/CommonComponents/store";

const MUI_THEME = createTheme({
  typography: {
    "fontFamily": `"Quicksand", "Roboto", "Helvetica", "Arial", "sans-serif"`,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "extraSmall" },
          style: { fontSize: 11, padding: "3px 6px" }
        }
      ]
    }
  }
});

export const UDContext = createContext();

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [UserDetails, setUserDetails] = useState(null);
  const [IsUserValid, setIsUserValid] = useState(false);
  const [AIAssistantWindowWidth, setAIAssistantWindowWidth] = useState(0);

  const { setStoreData } = useContext(GlobalContext);

  useEffect(() => {
    if (location.pathname !== "/error") {
      AuthenticateUser();
    } else {
      setIsUserValid(true)
    }
  }, []);

  const AuthenticateUser = () => {
    try {
      setIsUserValid(false);
      const public_key = localStorage.getItem('public_key');
      if (public_key) {
        //public_key available
        const token = localStorage.getItem('token');
        if (token) {
          //token available
          ValidateTokenAndGetUserDetail(token);
        } else {
          //token not found
          getTokenByPublicKey(public_key).then((response) => {
            if (response.status) {
              if (response.data.status === 200) {
                ValidateTokenAndGetUserDetail(response.data.token);
              } else if (response.data.status === 401) {
                localStorage.removeItem('public_key');
                GeneratePublicKey();
              }
            }
          });
        }
      } else {
        //public_key not found
        GeneratePublicKey()
      }
    } catch (error) {
      setIsUserValid(true)
      toast.error("Error: Authenticate User", { autoClose: 1000 });
    }
  }

  const GeneratePublicKey = () => {
    try {
      let public_key = window.btoa(((Math.random()) + uuidv4() + (new Date())));
      let public_key_url = BaseUrlAPI + "login?public_key=" + public_key;
      localStorage.setItem('public_key', public_key);
      window.open(public_key_url, '_self');
    } catch (error) {
      setIsUserValid(true)
      toast.error("Error: Generate Public Key", { autoClose: 1000 });
    }
  }

  const ValidateTokenAndGetUserDetail = async (token) => {
    try {
      getValidateToken(token).then((response) => {
        if (response.status) {
          if (response.data.hasOwnProperty('code')) {
            localStorage.removeItem('token');
            localStorage.removeItem('public_key');
            // toast.error("Error: "+response.data.message, { autoClose: 1000 });
            switch (response.data.code) {
              case 401:
                GeneratePublicKey();
                break;
              default:
                navigate("/error");
                break;
            }
          } else {
            localStorage.setItem('token', token);
            getUserDetailsByToken(response.data)
          }
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('public_key');
        }
      });
    } catch (error) {
      setIsUserValid(true)
      toast.error("Error: Validate Token", { autoClose: 1000 });
    }
  }

  const getUserDetailsByToken = async (token) => {
    try {
      getUserInfo(token).then((response) => {
        if (response.status) {
          if (response?.data?.email === "Guest") {
            // toast.info("Please reload the page", { autoClose: 4000 });
            setTimeout(() => {
              localStorage.removeItem('token');
              localStorage.removeItem('public_key');
              GeneratePublicKey();
            }, 5000);
          } else {
            getAllNavigationDataByUser(response.data)
          }
        }
      });
    } catch (error) {
      toast.error("Error: Loading user detail", { autoClose: 1000 });
    }
  }

  const getAllNavigationDataByUser = async (user) => {
    try {
      getUserDataByUser(user).then((response) => {
        if (response.status) {
          setUserDetails(response.data);
          setIsUserValid(true);
        } else {
          toast.error("Error: " + response.message);
        }
      });
    } catch (error) {
      toast("Error: Loading View", { type: 'error', autoClose: 1000 });
    }
  }


  useEffect(() => {
    if (UserDetails && UserDetails.user) {
      getAIDashboard()
        .then((res) => {
          if (res.status && Array.isArray(res.data))
            setStoreData(_state => ({ ..._state, aiDashboardReports: res.data }));
        })
    }
  }, [UserDetails]);


  useEffect(() => {
    if (UserDetails !== null) {
      Matomo_Loading(UserDetails);
    }
  }, [location, UserDetails]);


  const Matomo_Loading = (userDtl) => {
    let hostName = window.location.hostname;
    let PageName = userDtl?.AllUrls?.filter(x => x.url === window.location.origin + location.pathname);
    let DocumentTitle = window.location.href;
    let DocumentURL = window.location.href;
    if (PageName.length > 0) {
      DocumentTitle = PageName[0].name;
      DocumentURL = PageName[0].url;
    }
    // matomoScriptRun(Matomo_SiteId, userDtl?.user?.user_id, DocumentTitle, DocumentURL)
    if (hostName !== "localhost") {
      // matomoScriptRun(Matomo_SiteId, userDtl?.user?.user_id, DocumentTitle)
      matomoScriptRun(Matomo_SiteId, userDtl?.user?.user_id, DocumentTitle, DocumentURL)
    }
  }
  const matomoScriptRun = (SiteId, userId, DocumentTitle, DocumentURL) => {
    var _paq = window._paq = window._paq || [];
    _paq.push(["setDocumentTitle", DocumentTitle]);
    _paq.push(['setCustomUrl', DocumentURL]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = "https://matomo.devops.amgen.com/";
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', `${SiteId}`]);
      _paq.push(['setUserId', `${userId}`]);
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript'; g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
    })();
  }

  const resize_AIAssistantWindow = (_width) => {
    try {
      if (localStorage.getItem('BotPinned')) {
        localStorage.getItem('BotPinned') === "Y" ? setTimeout(() => { setAIAssistantWindowWidth(_width); }, 500) : setAIAssistantWindowWidth(0);
      } else {
        setAIAssistantWindowWidth(0);
      }
    } catch (error) {
      setAIAssistantWindowWidth(0);
    }
  }

  const AppRoutes = (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/page/:slug" element={<LandingPage />} />
      <Route path="/error" element={<Authentication />} />
      <Route path="/dashboard/:slug" element={<Dashboard />} />
      <Route path="/ReportBuilderDataList" element={<ExportReportBuilderDataList />} />
      <Route path="/newsletter/:news_uid" element={<NewsLetter />} />
      <Route path="/AIDashboard" element={<AIDashboard />} />
      <Route path="/ai-assistant" element={<LandingPage />} />
      <Route path="/ai-assistant-lg-insight" element={<LandingPage />} />

      <Route path="/backend" element={<Backend />} />
      <Route path="/backend/dashboards" element={<Dashboards />} />
      <Route path="/backend/dashboards/:dashboardid" element={<SaveDashboard />} />
      <Route path="/backend/navigationOrder" element={<NavigationOrder />} />
      <Route path="/backend/news" element={<News />} />
      <Route path="/backend/news/:news_id" element={<SaveNews />} />
      <Route path="/backend/adminUser" element={<AdminAccess />} />
      <Route path="/backend/reportAccess" element={<ReportAccess />} />
      <Route path="/backend/landingPageCreation" element={<LandingPageCreation />} />
      <Route path="/backend/pageList" element={<PageList />} />
      <Route path="/backend/pageList/:page_id" element={<PageDesigner />} />
      <Route path="/backend/trafficView" element={<MatomoGraphs />} />
      <Route path="/backend/alert" element={<TableauAlert />} />
      <Route path="/backend/alert1" element={<TableauAlertOne />} />
      <Route path="/backend/ai-assistant" element={<AIAssistantAdmin />} />

      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )

  return (
    <>
      <ThemeProvider theme={MUI_THEME}>
        {
          IsUserValid ? (
            <UDContext.Provider value={UserDetails}>
              <div style={AIAssistantWindowWidth > 0 ? { width: 'calc(100vw - ' + AIAssistantWindowWidth + 'px)' } : {}}>
                {AppRoutes}
              </div>
              <ChatBot ChatBot_onResize={resize_AIAssistantWindow} />
            </UDContext.Provider>
          ) : (
            <>
              <LoadingImage visible={!IsUserValid} />
            </>
          )
        }
        <ToastContainer autoClose={1000} />
        <ScrollToTop smooth component={<FaArrowAltCircleUp />} />
      </ThemeProvider >
    </>
  );
}

export default App;
