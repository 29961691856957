import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { FaPlus, FaRegSave } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { getAIAssistantSamplePrompts, updateAIAssistantSamplePrompts } from '../../../BaseModels/MasterData';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../../CommonComponents/store';
import st from './AIAssistantPromt.module.css';


const AIAssistantPrompt = () => {
    const { setStoreData } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);
    const [newPrompt, setNewPrompt] = useState('');
    const [gridApi, setGridApi] = useState(null);
    const [queries, setQueries] = useState([]);
    const [promptList, setPromptList] = useState(null);

    const [colDefs, ] = useState([
        {
            field: 'prompt',
            headerName: 'Prompt',
            rowDrag: true,
            flex: 1,
        },
        {
            headerName: 'Actions',
            field: 'id',
            cellRenderer: ({ api, value }) => {
                return (
                    <>
                        <Tooltip title="Delete" placement="right">
                            <IconButton onClick={() => { handlePromptDelete(api, value) }}>
                                <IoMdTrash />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            },
            width: 80,
            pinned: 'right'
        },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: false,
            editable: false,
            sortable: false,
            resizable: false,
        };
    }, []);

    useEffect(() => {
        getAIAssistantSamplePrompts()
            .then((resData) => {
                if (!resData.status) {
                    toast.error(resData.message, { autoClose: 3000 });
                    return;
                }
                const rowData = resData.data.map((item, index) => ({ id: index, prompt: item }));
                setQueries(resData.data);
                setPromptList(rowData);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    const getGridData = (_api) => {
        const prompts = [];
        const rowData = [];
        let maxId = -1;
        _api.forEachLeafNode((rowNode, index) => {
            rowData.push({ ...rowNode.data });
            prompts.push(rowNode.data.prompt);
            if (maxId < rowNode.data.id) maxId = rowNode.data.id;
        });
        return { prompts, rowData, maxId };
    }

    const handleAddPrompt = () => {
        if (!gridApi) {
            toast.error('Something went wrong!', { autoClose: 3000 });
            return;
        }
        if (!newPrompt || !newPrompt.trim()) return;
        let { rowData, maxId } = getGridData(gridApi);
        rowData.push({ id: maxId + 1, prompt: newPrompt.trim() });
        gridApi.setGridOption('rowData', rowData);
        setNewPrompt('');
    }

    const handlePromptDelete = (_api, idToDelete) => {
        if (!_api) {
            toast.error('Something went wrong!', { autoClose: 3000 });
            return;
        }
        let { rowData } = getGridData(_api);
        rowData = rowData.filter(x => x.id !== idToDelete);
        _api.setGridOption('rowData', rowData);
    }

    const handleCancel = () => {
        if (!gridApi) {
            toast.error('Something went wrong!', { autoClose: 3000 });
            return;
        }
        setNewPrompt('');
        const rowData = queries.map((item, index) => ({ id: index, prompt: item }));
        setPromptList(rowData);
    }

    const handleSave = () => {
        if (!gridApi) {
            toast.error('Something went wrong!', { autoClose: 3000 });
            return;
        }
        const { prompts } = getGridData(gridApi);
        setLoading(true);
        updateAIAssistantSamplePrompts(prompts)
            .then((resData) => {
                if (!resData.status) {
                    toast.error(resData.message, { autoClose: 3000 });
                    return;
                }
                toast.success('Default prompts updated successfully', { autoClose: 3000 });
                setQueries(prompts);
                setStoreData((_state) => {
                    return {
                        ..._state,
                        aiChats: {
                            ..._state.aiChats,
                            recentQuestions: prompts
                        }
                    }
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onGridReady = ({ api }) => {
        setGridApi(api);
    };

    return (
        <div className='d-flex flex-column gap-3'>
            <div className='d-flex gap-3'>
                <div className='flex-grow-1'>
                    <textarea
                        value={newPrompt}
                        placeholder='Type sample prompt here'
                        onChange={(event) => { setNewPrompt(event.target.value) }}
                        className={st.newPrompt}
                        disabled={loading}
                    />
                </div>
                <Button
                    className={st.newPromptAdd}
                    variant="contained"
                    size="small"
                    onClick={handleAddPrompt}
                    disableElevation
                    disabled={loading || !newPrompt || !newPrompt.trim()}
                >
                    <FaPlus />
                    <span>Add</span>
                </Button>
            </div>
            <div className={"ag-theme-quartz w-100"}>
                <AgGridReact
                    rowData={promptList}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    rowDragManaged={true}
                    onGridReady={onGridReady}
                    domLayout='autoHeight'
                />
            </div>
            <div className='d-flex gap-3'>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleSave}
                    disableElevation
                    disabled={loading}
                    endIcon={<FaRegSave />}
                > Save </Button>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleCancel}
                    disableElevation
                    disabled={loading}
                    endIcon={<IoClose />}
                > Cancel </Button>
            </div>
        </div>
    );
};

export default AIAssistantPrompt;