import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import { RiRefreshLine } from "react-icons/ri";
import React, { useEffect, useRef, useState } from 'react';
import { FaDownload } from "react-icons/fa";
import { Bar, BarChart, Brush, Legend, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatHeader, updateAIAssistantGraph } from '../../../../BaseModels/utility';
import st from './graphs.module.css';

const colors = ['#01234a', '#077a9d', '#6B318A', '#6C5EB7', '#5a8db1', '#3372aa', '#00338d', '#490b3e', '#91300a', '#289d7c', '#4682B4', '#00CED1', '#2E8B57', '#6A5ACD', '#5F9EA0', '#48D1CC', '#B8860B', '#00BFFF', '#DAA520', '#8FBC8F', '#556B2F'];

const BarGraph = ({ ChartData }) => {
    const chartRef = useRef(null);
    const [data, setData] = useState([]);
    const [IsGraphChartVisible, setIsGraphChartVisible] = useState(false);
    const [IsDownloadLoading, setIsDownloadLoading] = useState(false);

    useEffect(() => {
        try {
            if (ChartData) {
                if (ChartData.IsAggregate) {
                    let groupBy_XAxisData = _.mapValues(_.groupBy(ChartData.RowData, ChartData.XAxisSelectedValue.value), clist => clist.map(car => _.omit(car, ChartData.XAxisSelectedValue.value)));
                    let groupBy_XAxisKeysList = Object.keys(groupBy_XAxisData);
                    let _data = [];
                    groupBy_XAxisKeysList.forEach(xKey => {
                        let obj = {};
                        obj[ChartData.XAxisSelectedValue.value] = xKey;
                        let xValueList = groupBy_XAxisData[xKey];
                        ChartData.YAxisSelectedList.forEach(yAxisObj => {
                            let yKey = yAxisObj.value;
                            let yVal = _.sumBy(xValueList, yKey);
                            switch (ChartData.AggregateType) {
                                case 'sum':
                                    yVal = _.sumBy(xValueList, yKey);
                                    break;
                                case 'avg':
                                    yVal = _.meanBy(xValueList, yKey);
                                    break;
                                case 'min':
                                    yVal = _.minBy(xValueList, yKey)[yKey];
                                    break;
                                case 'max':
                                    yVal = _.maxBy(xValueList, yKey)[yKey];
                                    break;
                                case 'count':
                                    yVal = xValueList.length;
                                    break;
                                default:
                                    yVal = _.sumBy(xValueList, yKey);
                                    break;
                            }
                            obj[yKey] = (yVal !== '' && yVal !== null && yVal !== undefined) ?
                                (typeof yVal === 'number' ?
                                    (parseFloat(yVal.toFixed(2)) % 1 === 0 ? Math.round(yVal) : parseFloat(yVal.toFixed(2)))
                                    : null)
                                : null;
                        });
                        _data.push(obj);
                    });
                    setData(_data);
                    setIsGraphChartVisible(true);
                } else {
                    let _data = ChartData.RowData;
                    setData(_data);
                    setIsGraphChartVisible(true);
                }
                setTimeout(() => {
                    downloadChart();
                }, 2000);
            }
        } catch (error) {

        }
    }, [ChartData]);

    const downloadChart = async () => {
        if (chartRef) {
            if (chartRef.current) {
                const canvas = await html2canvas(chartRef.current);
                const base64Image = canvas.toDataURL('image/png');
                updateAIAssistantGraph(ChartData.msg_id, base64Image);
            }
        }
    }


    const CustomTooltipTable = (label) => {
        try {
            if (ChartData.IsAggregate) {
                let array = ChartData.RowData.map(item => item[ChartData.XAxisSelectedValue.value]);
                let uniqueValuesList = [...new Set(array)];
                if (array.length !== uniqueValuesList.length) {
                    let groupBy_XAxisData = _.mapValues(_.groupBy(ChartData.RowData, ChartData.XAxisSelectedValue.value), clist => clist.map(car => _.omit(car, ChartData.XAxisSelectedValue.value)));
                    let groupBy_XAxisKeysList = Object.keys(groupBy_XAxisData);
                    let tableData = groupBy_XAxisData[label];
                    return (
                        <div className={st.tableRootContainer}>
                            <div className={st.tableContainer}>
                                <table>
                                    <thead>
                                        <tr>
                                            {Object.keys(tableData[0]).map((key, index) => (
                                                <th key={index}>{formatHeader(key)}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {Object.values(row).map((value, cellIndex) => (
                                                    <td key={cellIndex}>{value !== null && typeof value === 'object' ? value.value : value}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                }
            }
        } catch (error) {

        }
        return null;
    }

    function CustomTooltipTableFromPayload(payloadList) {
        try {
            if (payloadList && payloadList.length > 0) {
                let payload_Otherkeys = payloadList.map((item) => item.dataKey);
                return (
                    <div className={st.tableContainer}>
                        <table>
                            <tbody>
                                {Object.entries(payloadList[0]?.payload)
                                .filter(([key, value]) => !payload_Otherkeys.includes(key))
                                .map(([key, value], index) => (
                                    <tr key={`${index}-${key}`}>
                                        <td style={{ fontWeight: 'bold' }}>{formatHeader(key)}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            }
        } catch (error) {

        }
        return null;
    }


    const CustomTooltip = (props) => {
        try {
            const { active, payload, label } = props;
            if (active && payload && payload.length) {
                return (
                    <div className={st.ttrContainer}>
                        <div className={st.ttXAxisLabelName}>{label}</div>
                        {
                            payload.map((item, index) => {
                                return <div key={index} className={st.ttYAxisPayload}>{formatHeader(item.dataKey)}: <strong>{item.value}</strong> </div>
                            })
                        }
                        {ChartData.IsAggregate && CustomTooltipTable(label)}
                        {!ChartData.IsAggregate && CustomTooltipTableFromPayload(payload)}
                    </div>
                );
            }
        } catch (error) {

        }
        return null;
    };

    const renderBrush = () => {
        try {
            if (data.length * ChartData.YAxisSelectedList.length > ChartData.ScreenCellLength) {
                let endIndex = ChartData.ScreenCellLength / ChartData.YAxisSelectedList.length;
                if (endIndex > (data.length - 1)) {
                    endIndex = data.length - 1;
                }
                endIndex = Math.floor(endIndex);
                return <Brush dataKey={ChartData.XAxisSelectedValue.value} height={10} stroke="#01234a" startIndex={0} endIndex={endIndex} />;
            }
        } catch (error) {

        }
        return null;
    }

    const CustomAxisTick = (props) => {
        try {
            let { x, y, payload, index } = props;
            let value = payload.value;
            if (value) {
                value = value.toString();
                value = value.length > 15 ? `${value.slice(0, 15)}...` : value
            }
            let xPotion = (x + value.length * 2) + 3;
            let yPotion = y;
            if (index % 2 === 0) {
                yPotion = y - 5;
            } else {
                yPotion = y + 5;
            }
            yPotion = yPotion - 3;

            return (
                <g transform={`translate(${xPotion},${yPotion})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="black" fontSize="10px">
                        {value}
                    </text>
                </g>
            );
        } catch (error) {

        }
        return null;
    };

    const renderXAxis = () => {
        try {
            let offset = -10;
            if (data.length * ChartData.YAxisSelectedList.length > ChartData.ScreenCellLength) {
                offset = -30;
            }
            return <XAxis
                interval={0}
                dataKey={ChartData.XAxisSelectedValue.value}
                label={{
                    value: formatHeader(ChartData.XAxisSelectedValue.value),
                    position: 'insideBottom',
                    offset: offset,
                    style: { fontWeight: 'bold', fill: '#01234a' }
                }}
                tick={<CustomAxisTick />} />
        } catch (error) {

        }
    }

    const btnDownloadGraph_onClick = async () => {
        try {
            if (chartRef) {
                setIsDownloadLoading(true);
                if (chartRef.current) {
                    const canvas = await html2canvas(chartRef.current);
                    const base64Image = canvas.toDataURL('image/png');
                    var a = document.createElement("a");
                    a.href = base64Image;
                    a.download = "Image" + ChartData.msg_id + ".png";
                    a.click();
                }
                setTimeout(() => { setIsDownloadLoading(false); }, 3000);
            }
        } catch (error) {
            setIsDownloadLoading(false);
        }
    }

    return (
        IsGraphChartVisible &&
        <>
            <IconButton color="primary" onClick={btnDownloadGraph_onClick} size="small"
                style={{ position: 'absolute', right: '16px', marginTop: '-20px' }} disabled={IsDownloadLoading}>
                {IsDownloadLoading ? <RiRefreshLine className='rotate-center' /> : <FaDownload />}
            </IconButton>
            <ResponsiveContainer width="100%" height="100%" ref={chartRef}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 35,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                    {renderXAxis()}
                    <YAxis tick={false} label={{
                        value: ChartData.YAxisSelectedList.map((x) => formatHeader(x.value)).join(" & "),
                        position: 'start',
                        angle: -90,
                        offset: -10,
                        style: { fontWeight: 'bold', fill: '#01234a' }
                    }} />
                    <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
                    {
                        ChartData.YAxisSelectedList.map((item, index) => {
                            return (<Bar dataKey={item.value} name={item.label} fill={colors[index]} key={index}>
                                <LabelList dataKey={item.value} position="top" />
                            </Bar>)
                        })
                    }
                    {renderBrush()}
                    {
                        ChartData.YAxisSelectedList.length > 1 && <Legend verticalAlign="bottom" align="right" wrapperStyle={{ bottom: '10px' }} />
                    }
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default BarGraph;