import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineRadarChart } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChartBar, FaChartPie, FaSave, FaTrash } from "react-icons/fa";
import { FaChartLine, FaTableCells } from "react-icons/fa6";
import { LuScatterChart } from "react-icons/lu";
import { MdCancel } from "react-icons/md";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../../store';
import { formatHeader } from '../../../../BaseModels/utility';
import ModalPopup from '../../../../CustomElements/ModalPopup/ModalPopup';
import { AIDashboardDeactivateReport, AIDashboardUpdate, getAIDashboardData } from './../../../../BaseModels/MasterData';
import st from './DashboardDetail.module.css';
import BarGraph from './Graphs/BarGraph';
import LineGraph from './Graphs/LineGraph';
import PieGraph from './Graphs/PieGraph';
import RadarGraph from './Graphs/RadarGraph';
import ScatterGraph from './Graphs/ScatterGraph';
import TableGraph from './Graphs/TableGraph';

const DashboardDetail = ({ detail }) => {
    const { setStoreData } = useContext(GlobalContext);
    const [IsLoading, setIsLoading] = useState(false);
    const [IsEditGraphPopupVisible, setIsEditGraphPopupVisible] = useState(false);
    const [Title, setTitle] = useState("");
    const [sub_title, setSub_title] = useState("");
    const [Description, setDescription] = useState("");
    const [XAxisList, setXAxisList] = useState([]);
    const [XAxisSelectedValue, setXAxisSelectedValue] = useState(null);
    const [YAxisList, setYAxisList] = useState([]);
    const [YAxisSelectedList, setYAxisSelectedList] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [ChartTypeList, setChartTypeList] = useState([
        { value: 'line', label: 'line', position: 0, disabled: false, icon: <FaChartLine /> },
        { value: 'bar', label: 'bar', position: 1, disabled: false, icon: <FaChartBar /> },
        { value: 'pie', label: 'pie', position: 2, disabled: false, icon: <FaChartPie /> },
        { value: 'scatter', label: 'scatter', position: 3, disabled: false, icon: <LuScatterChart /> },
        { value: 'radar', label: 'radar', position: 4, disabled: false, icon: <AiOutlineRadarChart /> },
        { value: 'table', label: 'table', position: 5, disabled: false, icon: <FaTableCells /> }
    ]);
    const [ChartTypeSelectedValue, setChartTypeSelectedValue] = useState(null);
    const [ChartData, setChartData] = useState({});
    let AggregateTypeList = [
        { value: 'sum', label: 'Sum' },
        { value: 'avg', label: 'Average' },
        { value: 'min', label: 'Minimum' },
        { value: 'max', label: 'Maximum' },
        { value: 'count', label: 'Count' }
    ];
    const [IsAggregate, setIsAggregate] = useState(true);
    const [AggregateType, setAggregateType] = useState({ value: 'sum', label: 'Sum' });

    useEffect(() => {
        BindDetails(detail)
    }, [detail])

    const BindDetails = async (_detail) => {
        try {
            setIsLoading(true);
            setTitle(_detail.title);
            setSub_title(_detail.sub_title);
            setDescription(_detail.description);
            const _columns = JSON.parse(_detail.columns_names);
            const _yaxis = JSON.parse(_detail.yaxis);
            setColumns(_columns);
            if (_columns.length > 0) {
                let response = await getAIDashboardData(_detail.conference_id, _detail.message_id, _detail.unique_id);
                if (response.status) {
                    const _rowData = response.data;
                    setRowData(_rowData);
                    if (_rowData.length > 0) {
                        const keys = Object.keys(_rowData[0]);
                        let _XAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                        setXAxisList(_XAxisList);
                        //checking is user save the graph or not (if yaxis have value then user save the graph)
                        if (_yaxis.length === 0) {
                            let _XAxisSelectedValueCol = defaultXAxisCalculate(_rowData);
                            let _XAxisSelectedIndex = _XAxisList.findIndex((x) => x.value === _XAxisSelectedValueCol);
                            setXAxisSelectedValue(_XAxisList[_XAxisSelectedIndex]);
                            let _YAxisList = [];
                            let _ChartTypeSelectedValue = defaultChartTypeCalculate(_rowData, _XAxisSelectedValueCol);
                            setChartTypeSelectedValue(_ChartTypeSelectedValue);
                            let _ChartTypeList = [...ChartTypeList];
                            if (_rowData.length === 1) {
                                _ChartTypeList.find(x => x.value === 'line').disabled = true;
                            }
                            if (_rowData.length > 20 && keys.length > 1) {
                                _ChartTypeList.find(x => x.value === 'pie').disabled = true;
                            }
                            if (keys.length === 1) {
                                _YAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                                _ChartTypeList.forEach(_ChartType => {
                                    if (_ChartType.value !== 'pie') {
                                        _ChartType.disabled = true;
                                    }
                                });
                            } else {
                                _YAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                                _YAxisList.splice(_XAxisSelectedIndex, 1);
                            }
                            setChartTypeList(_ChartTypeList);
                            setYAxisList(_YAxisList);
                            let _YAxisSelectedValue = defaultYAxisCalculate(_rowData, _XAxisSelectedValueCol);
                            let _YAxisSelectedList = _YAxisList.filter((x) => x.value === _YAxisSelectedValue);
                            setYAxisSelectedList(_YAxisSelectedList);
                            let _IsAggregate = IsAggregate;
                            let _AggregateType = AggregateType;
                            prepareDataForGraph(_XAxisList, _XAxisList[_XAxisSelectedIndex], _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
                        } else {
                            //if user save the graph
                            let _XAxisSelectedValueCol = _XAxisList[_detail.xaxis].value;
                            let _XAxisSelectedIndex = _XAxisList.findIndex((x) => x.value === _XAxisSelectedValueCol);
                            setXAxisSelectedValue(_XAxisList[_XAxisSelectedIndex]);
                            let _YAxisList = [];
                            let _ChartTypeList = [...ChartTypeList];
                            let _ChartTypeSelectedValue = _ChartTypeList[_detail.graphtype].value;
                            setChartTypeSelectedValue(_ChartTypeSelectedValue);
                            if (_rowData.length === 1) {
                                _ChartTypeList.find(x => x.value === 'line').disabled = true;
                            }
                            if (_rowData.length > 20 && keys.length > 1) {
                                _ChartTypeList.find(x => x.value === 'pie').disabled = true;
                            }
                            if (keys.length === 1) {
                                _YAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                                _ChartTypeList.forEach(_ChartType => {
                                    if (_ChartType.value !== 'pie') {
                                        _ChartType.disabled = true;
                                    }
                                });
                            } else {
                                _YAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                                _YAxisList.splice(_XAxisSelectedIndex, 1);
                            }
                            setChartTypeList(_ChartTypeList);
                            setYAxisList(_YAxisList);
                            let _YAxisSelectedList = [];
                            _yaxis.forEach(yaxisData => {
                                let savedYaxis = _YAxisList.find(x => x.value === yaxisData.value);
                                if (savedYaxis) {
                                    _YAxisSelectedList.push(_YAxisList.find(x => x.value === yaxisData.value));
                                }
                            });
                            setYAxisSelectedList(_YAxisSelectedList);
                            let _IsAggregate = IsAggregate;
                            let _AggregateType = AggregateType;
                            if (_detail.hasOwnProperty('aggregate_type')) {
                                let aggregate = AggregateTypeList.find(x => x.value === _detail.aggregate_type);
                                if (aggregate) {
                                    _IsAggregate = true;
                                    _AggregateType = aggregate;
                                } else {
                                    _IsAggregate = false;
                                    _AggregateType = null;
                                }
                            } else {
                                _IsAggregate = true;
                                _AggregateType = AggregateTypeList[0];
                            }
                            setIsAggregate(_IsAggregate);
                            setAggregateType(_AggregateType);
                            prepareDataForGraph(_XAxisList, _XAxisList[_XAxisSelectedIndex], _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
                        }
                    }
                }
                setIsLoading(false);
            }
        } catch (error) {

        }
    }

    function isNumber(value) {
        return typeof value === 'number';
    }

    const defaultXAxisCalculate = (_DataRows) => {
        let _XAxisSelectedValue = '';
        try {
            let keys = Object.keys(_DataRows[0]);
            keys.forEach(col => {
                if (_XAxisSelectedValue === '') {
                    let array = _DataRows.map(item => item[col]);
                    let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                    let IsNumberArray = _dataRowremovedNull.filter(Boolean).every(isNumber);
                    if (IsNumberArray) {
                        if (String(col).toLowerCase().includes("month") || String(col).toLowerCase().includes("year")) {
                            let lengthOfEachValue = _dataRowremovedNull.map((item, index) => { return String(item).length });
                            let uniqueLength = [...new Set(lengthOfEachValue)];
                            if (uniqueLength.length === 1) {
                                if (_XAxisSelectedValue === '') {
                                    _XAxisSelectedValue = col;
                                }
                            } else if (uniqueLength.length === 2) {
                                if (array.includes(1) && array.includes(2)) {
                                    if (_XAxisSelectedValue === '') {
                                        _XAxisSelectedValue = col;
                                    }
                                }
                            } else if (uniqueLength[0] === 4) {
                                if (_XAxisSelectedValue === '') {
                                    _XAxisSelectedValue = col;
                                }
                            }
                        }
                    } else {
                        let FisrtCharList = _dataRowremovedNull.map((x) => x[0]);
                        let LastCharList = _dataRowremovedNull.map((x) => x[x.length - 1]);
                        if (FisrtCharList.includes('$') ||
                            LastCharList.includes('%') ||
                            LastCharList.includes('B') ||
                            LastCharList.includes('M') ||
                            LastCharList.includes('K')
                        ) {

                        } else {
                            if (_XAxisSelectedValue === '') {
                                _XAxisSelectedValue = col;
                            }
                        }
                    }
                }
            });
            if (_XAxisSelectedValue === '') {
                _XAxisSelectedValue = keys[0];
            }
        } catch (error) {
            toast.error("Error in default x-axis calculate");
        }
        return _XAxisSelectedValue;
    }

    const defaultChartTypeCalculate = (_DataRows, _XAxisSelectedValue) => {
        let _ChartTypeSelectedValue = '';
        try {
            let keys = Object.keys(_DataRows[0]);
            if (keys.length === 1 || _DataRows.length === 1) {
                _ChartTypeSelectedValue = 'pie';
            } else {
                let array = _DataRows.map(item => item[_XAxisSelectedValue]);
                let uniqueValuesList = [...new Set(array)];
                if (array.length === uniqueValuesList.length && _DataRows.length > 1) {
                    _ChartTypeSelectedValue = 'line';
                } else {
                    _ChartTypeSelectedValue = 'bar';
                }
            }
        } catch (error) {
            toast.error("Error in default chart type calculate");
        }
        return _ChartTypeSelectedValue;
    }

    const defaultYAxisCalculate = (_DataRows, _XAxisSelectedValue) => {
        let _YAxisSelectedValue = '';
        let keys = Object.keys(_DataRows[0]);
        try {
            if (keys.length === 1) {
                _YAxisSelectedValue = keys[0];
            } else {
                keys.splice(keys.findIndex(x => x === _XAxisSelectedValue), 1);
                if (keys.length === 1) {
                    _YAxisSelectedValue = keys[0];
                } else {
                    for (let index = keys.length - 1; index > 0; index--) {
                        let col = keys[index];
                        if (_YAxisSelectedValue === '') {
                            let array = _DataRows.map(item => item[col]);
                            let _dataRowremovedNull = array.filter((x) => { if (x) return x });
                            let IsNumberArray = _dataRowremovedNull.filter(Boolean).every(isNumber);
                            if (IsNumberArray) {
                                if (_YAxisSelectedValue === '') {
                                    _YAxisSelectedValue = col;
                                }
                            } else {
                                let FisrtCharList = _dataRowremovedNull.map((x) => x[0]);
                                let LastCharList = _dataRowremovedNull.map((x) => x[x.length - 1]);
                                if (FisrtCharList.includes('$') ||
                                    LastCharList.includes('%') ||
                                    LastCharList.includes('B') ||
                                    LastCharList.includes('M') ||
                                    LastCharList.includes('K')
                                ) {
                                    if (_YAxisSelectedValue === '') {
                                        _YAxisSelectedValue = col;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Error in default y-axis calculate");
        }
        if (_YAxisSelectedValue === '') {
            _YAxisSelectedValue = keys[keys.length - 1];
        }
        return _YAxisSelectedValue;
    }

    const ddlChartTypeList_onChange = (item) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = ChartTypeSelectedValue;
            let _rowData = [...rowData];
            _ChartTypeSelectedValue = item.value;
            setChartTypeSelectedValue(_ChartTypeSelectedValue);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
        } catch (error) {
            toast.error("Error in ddl Chart Type List");
        }
    }

    const ddlXAxisList_onChange = (item) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = ChartTypeSelectedValue;
            let _rowData = [...rowData];
            _XAxisSelectedValue = item;
            let _XAxisSelectedIndex = _XAxisList.findIndex((x) => x.value === item.value);
            const keys = Object.keys(rowData[0]);
            if (keys.length === 1) {
                _YAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
            } else {
                _YAxisList = keys.map((obj, index) => ({ value: obj, label: formatHeader(obj), position: index }));
                _YAxisList.splice(_XAxisSelectedIndex, 1);
            }
            setYAxisList(_YAxisList);
            let _YAxisSelectedValue = defaultYAxisCalculate(rowData, item.value);
            _YAxisSelectedList = _YAxisList.filter((x) => x.value === _YAxisSelectedValue)
            setYAxisSelectedList(_YAxisSelectedList);

            setXAxisSelectedValue(_XAxisSelectedValue);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
        } catch (error) {
            toast.error("Error in ddl x-axis selection");
        }
    }

    const ddlYAxisList_onChange = (item) => {
        try {
            if (item.length === 0) {
                toast.info("Please select at least one Y-Axis");
                return;
            }
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = ChartTypeSelectedValue;
            let _rowData = [...rowData];
            _YAxisSelectedList = item;
            setYAxisSelectedList(item);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
        } catch (error) {
            toast.error("Error in y-axis selection");
        }
    }

    const IsAggregate_onChange = (e) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = ChartTypeSelectedValue;
            let _rowData = [...rowData];
            _IsAggregate = e.target.checked;
            if (_IsAggregate) {
                _AggregateType = AggregateTypeList[0];
                setAggregateType(_AggregateType);
            }
            setIsAggregate(_IsAggregate);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
        } catch (error) {
            toast.error("Error in aggregate selection");
        }
    }

    const ddlAggregateList_onChange = (item) => {
        try {
            let _XAxisList = [...XAxisList];
            let _XAxisSelectedValue = { ...XAxisSelectedValue };
            let _YAxisList = [...YAxisList];
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _IsAggregate = IsAggregate;
            let _AggregateType = AggregateType;
            let _ChartTypeList = [...ChartTypeList];
            let _ChartTypeSelectedValue = ChartTypeSelectedValue;
            let _rowData = [...rowData];
            _AggregateType = item;
            setAggregateType(_AggregateType);
            prepareDataForGraph(_XAxisList, _XAxisSelectedValue, _YAxisList, _YAxisSelectedList, _ChartTypeList, _ChartTypeSelectedValue, _IsAggregate, _AggregateType, _rowData);
        } catch (error) {
            toast.error("Error in aggregate selection");
        }
    }

    const updateGraph = async () => {
        try {
            let _YAxisSelectedList = [...YAxisSelectedList];
            let _XAxisSelectedIndex = XAxisList.findIndex((x) => x.value === XAxisSelectedValue.value);
            let _ChartTypeSelectedIndex = ChartTypeList.findIndex((x) => x.value === ChartTypeSelectedValue);
            let _Columns = columns;
            let _Title = Title;
            let _Description = Description;
            let _sub_title = sub_title;
            let _IsAggregate = IsAggregate;
            let _AggregateType = _IsAggregate ? typeof AggregateType === 'object' ? AggregateType.value : AggregateType : ''

            let obj = {
                "unique_id": detail.unique_id,
                "conference_id": detail.conference_id,
                "message_id": detail.message_id,
                "title": _Title,
                "sub_title": _sub_title,
                "description": _Description,
                "columns_names": _Columns,
                "graphtype": _ChartTypeSelectedIndex,
                "xaxis": _XAxisSelectedIndex,
                "yaxis": _YAxisSelectedList,
                "aggregate_type": _AggregateType,
            };
            let response = await AIDashboardUpdate(obj);
            if (response) {
                if (response.status) {
                    toast.success("Graph Updated Successfully");
                } else {
                    toast.error(response.message);
                }
            }
            setIsEditGraphPopupVisible(false);
        } catch (error) {
            toast.error("Error in save Local Storage");
        }
    }

    const btnDelete_onClick = async () => {
        try {
            if (!window.confirm("Are you sure you want to delete this graph?")) return;
            const response = await AIDashboardDeactivateReport(detail.unique_id);
            if (response.status) {
                toast.success("Graph Deleted Successfully");
                setStoreData(_state => {
                    return {
                        ..._state,
                        aiDashboardReports: _state.aiDashboardReports.filter(x => x.unique_id !== detail.unique_id)
                    }
                });
                setIsEditGraphPopupVisible(false);
            }
        } catch (error) {
            toast.error("Error in btn Delete on Click");
        }
    }

    const prepareDataForGraph = (
        _XAxisList,
        _XAxisSelectedValue,
        _YAxisList,
        _YAxisSelectedList,
        _ChartTypeList,
        _ChartTypeSelectedValue,
        _IsAggregate,
        _AggregateType,
        _rowData,
    ) => {
        try {
            // let _DataRows = _rowData;
            // _YAxisSelectedList.forEach(element => {
            //     _DataRows = DataRowsFormatting(_DataRows, element.value);
            // });
            let obj = {
                XAxisList: _XAxisList,
                XAxisSelectedValue: _XAxisSelectedValue,
                YAxisList: _YAxisList,
                YAxisSelectedList: _YAxisSelectedList,
                IsAggregate: _IsAggregate,
                AggregateType: _AggregateType?.value,
                ChartTypeList: _ChartTypeList,
                ChartTypeSelectedValue: _ChartTypeSelectedValue,
                RowData: _rowData,
                ScreenCellLength: 10,
                msg_id: detail.message_id
            }
            setChartData(obj);
        } catch (error) {
            toast.error("Error in binding all details");
        }
    }

    const DataRowsFormatting = (_dataRow, ColumnName) => {
        let _data = JSON.parse(JSON.stringify(_dataRow));
        try {
            _data.forEach(element => {
                let newVal = element[ColumnName];
                if (newVal !== '' && newVal !== null && newVal !== undefined) {
                    newVal = String(newVal).replace(/[^0-9\.-]/g, "");
                    if (typeof yVal === 'number') {

                    } else {
                        newVal = null;
                    }
                } else {
                    newVal = null;
                }
                element[ColumnName] = newVal;
            });
        } catch (error) {
            toast.error("Error in Data Rows Formatting");
        }
        return _data;
    }

    const randeringGraph = (_ChartTypeSelectedValue, _ChartData) => {
        try {
            switch (_ChartTypeSelectedValue) {
                case 'bar':
                    return <BarGraph ChartData={_ChartData} />;
                case 'line':
                    return <LineGraph ChartData={_ChartData} />;
                case 'pie':
                    return <PieGraph ChartData={_ChartData} />;
                case 'scatter':
                    return <ScatterGraph ChartData={_ChartData} />;
                case 'radar':
                    return <RadarGraph ChartData={_ChartData} />;
                case 'table':
                    return <TableGraph ChartData={_ChartData} />;
                default:
                    return <BarGraph ChartData={_ChartData} />;
            }
        } catch (error) {
            toast.error("Error in rendering graph");
            return <span>Error in rendering graph</span>
        }
    }

    return (
        <>
            {IsLoading && <div className={st.loaderContainer}><span className={st.loader}></span></div>}
            {!IsLoading && <>
                <div className={`${st.itemRootContainer} ${st.dashboardItem}`}>
                    <div className={st.headerContainer}>
                        <Tooltip title={Description}>
                            <div>{Title}</div>
                        </Tooltip>
                        <BsThreeDotsVertical onClick={() => setIsEditGraphPopupVisible(true)} />
                    </div>
                    <small>{sub_title}</small>
                    <div className={st.graphRootContainer}>
                        {
                            ChartData &&
                            ChartTypeList.length > 0 &&
                            XAxisList.length > 0 && XAxisSelectedValue !== null &&
                            YAxisList.length > 0 && YAxisSelectedList.length > 0 &&
                            <div className={st.graphContainer}>
                                {randeringGraph(ChartTypeSelectedValue, ChartData)}
                            </div>
                        }
                    </div>
                </div>
                <ModalPopup IsVisible={IsEditGraphPopupVisible} onClose={() => setIsEditGraphPopupVisible(false)}>
                    <div className={st.graphModifyDetail}>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Title</div>
                                <input type="text" placeholder="Title" value={Title}
                                    onChange={(e) => { setTitle(e.target.value); }}
                                    className={`${'form-control form-control-sm'}`} />
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Sub-Title</div>
                                <input type="text" placeholder="Sub-Title" value={sub_title}
                                    onChange={(e) => { setSub_title(e.target.value); }}
                                    className={`${'form-control form-control-sm'}`} />
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Description</div>
                                <input type="text" placeholder="Description"
                                    onChange={(e) => { setDescription(e.target.value) }}
                                    value={Description} className={`${'form-control form-control-sm'}`} />
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Axis</div>
                                <div className={st.graphActionItemsRootContainer}>
                                    {
                                        XAxisList.length > 0 && XAxisSelectedValue !== null &&
                                        <div className={st.graphActionItemContainer}>
                                            <strong>X-Axis</strong>
                                            <Select
                                                className="Select-X-Axis"
                                                classNamePrefix="select"
                                                value={XAxisSelectedValue}
                                                isClearable={false}
                                                isSearchable={false}
                                                name="Select-X-Axis"
                                                options={XAxisList}
                                                onChange={ddlXAxisList_onChange}
                                            />
                                        </div>
                                    }
                                    {
                                        YAxisList.length > 0 && YAxisSelectedList.length > 0 &&
                                        <div className={st.graphActionItemContainer}>
                                            <strong>Y-Axis</strong>
                                            <Select
                                                className="Select-Y-Axis"
                                                classNamePrefix="select"
                                                isMulti
                                                value={YAxisSelectedList}
                                                isClearable={false}
                                                isSearchable={false}
                                                name="Select-Y-Axis"
                                                options={YAxisList}
                                                onChange={ddlYAxisList_onChange}
                                            />
                                        </div>
                                    }
                                    {
                                        YAxisSelectedList.length &&
                                        <div>
                                            <strong>
                                                <label>
                                                    Aggregate &nbsp;
                                                    <input type="checkbox" checked={IsAggregate} onChange={IsAggregate_onChange} />
                                                </label>
                                            </strong>
                                            {
                                                IsAggregate && <Select
                                                    className="Select-Aggregate"
                                                    classNamePrefix="select aggregate"
                                                    value={AggregateType}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    name="Select-Aggregate"
                                                    options={AggregateTypeList}
                                                    onChange={ddlAggregateList_onChange}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={st.editGraphRootContainer}>
                            <div className={st.editContainer}>
                                <div className={st.editName}>Graph Type</div>
                                <div className={st.chartTypeContainer}>
                                    {
                                        ChartTypeList.length > 0 &&
                                        ChartTypeList.map((item, index) => {
                                            return (
                                                <button key={index}
                                                    className={item.value === ChartTypeSelectedValue ? st.btnChartTypeActive : !item.disabled ? st.btnChartType : st.btnChartTypeDisabled}
                                                    onClick={() => ddlChartTypeList_onChange(item)}>
                                                    {item.icon} {item.label}
                                                </button>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={st.graphModifyFooter}>
                            <Button variant="outlined" size="medium" startIcon={<MdCancel />} onClick={() => setIsEditGraphPopupVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" size="medium" color="error" startIcon={<FaTrash />} onClick={btnDelete_onClick}>
                                Delete
                            </Button>
                            <Button variant="contained" size="medium" startIcon={<FaSave />} onClick={updateGraph}>
                                Save
                            </Button>
                        </div>
                    </div>
                </ModalPopup>
            </>
            }
        </>
    );
};

export default DashboardDetail;