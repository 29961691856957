import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AiFillFileExcel, AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { FaCamera, FaDownload, FaEye, FaFilePdf, FaRegSave, FaRegStar, FaShareSquare, FaStar } from "react-icons/fa";
import { FaFileCsv, FaImage } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { IoReloadCircle, IoShareSocialSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { PiProjectorScreenChartFill } from "react-icons/pi";
import { RiCamera2Fill, RiFilePpt2Fill } from "react-icons/ri";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { BaseUrlAPI, deleteReportBuilderImages, getReportBuilderImages, getReportBuilder_PPT_PDF, postFavReportDelete, postFavReportInsert, uploadReportBuilderImageTableau } from '../../../BaseModels/MasterData';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import TopMenu from '../../TopMenu/TopMenu';
import DashboardComment from '../DashboardComment/DashboardComment';
import { UDContext } from './../../../../App';
import ImageLogo from './../../../../images/ImageLogo.svg';
import e2eVizLoading from './../../../../images/e2eVizLoading.gif';
import st from './TableauDashboard.module.css';
const eventBus = document;

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>
    );
};


const { tableau } = window;

const TableauDashboard = () => {
    const UserDetail = useContext(UDContext);
    const { slug } = useParams();
    const { hash } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();


    const refDashboard = useRef(null);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [UserData, setUserData] = useState({});
    const [AllDashboardData, setAllDashboardData] = useState([]);
    const [TableauDashboardDetail, setTableauDashboardDetail] = useState({
        "dashboardid": 0,
        "page": "",
        "section": "",
        "title": "",
        "slug": "",
        "description": "",
        "type": "Tableau",
        "url": "",
        "height": "800",
        "top_tool_bar_visible": false,
        "bottom_tool_bar_visible": false,
        "share_tableau_viz_visible": false,
        "custom_views_visible": false,
        "download_visible": false,
        "download_csv_visible": false,
        "download_image_visible": false,
        "download_pdf_visible": false,
        "download_excel_visible": false,
        "download_ppt_visible": false,
        "report_builder_visible": false,
        "user_id": ""
    });
    const [viz, setViz] = useState(null);
    const [vizOption, setVizOption] = useState({
        hideTabs: true,
        width: "100%",
        height: "100%",
        hideToolbar: true,
        device: windowSize[0] > 992 ? "desktop" : "phone",
    });
    const [Workbook, setWorkbook] = useState(null);
    const [TableauTabs, setTableauTabs] = useState([]);
    const [TableauHeight, setTableauHeight] = useState("80vh");
    const [TableauTabIndex, setTableauTabIndex] = useState(-1);
    const [TabsDDLVisible, setTabsDDLVisible] = useState(false);

    const screen1 = useFullScreenHandle();
    const [IsFullScreen, setIsFullScreen] = useState(false);
    const reportChange = useCallback((state, handle) => {
        setIsFullScreen(state)

    }, [screen1]);

    const [IsLoadingVisible, setIsLoadingVisible] = useState(false);
    const [IsFavorite, setIsFavorite] = useState(false);
    const [IsDownloadFileOpen, setIsDownloadFileOpen] = useState(false);
    const [DownloadFileDataList, setDownloadFileDataList] = useState([]);
    const [IsReportBuilderOpen, setIsReportBuilderOpen] = useState(false);
    const [IsReportBuilderCaptureLoadingOpen, setIsReportBuilderCaptureLoadingOpen] = useState(false);
    const [ReportBuilderDataList, setReportBuilderDataList] = useState([]);
    const [IsDownloadOptionOpen, setIsDownloadOptionOpen] = useState(false);

    const [IsCustomViewOpen, setIsCustomViewOpen] = useState(false);
    const [CustomViewList, setCustomViewList] = useState([]);
    const [SelectedExistingCustomViews, setSelectedExistingCustomViews] = useState('');
    const [newCustomViewName, setNewCustomViewName] = useState('');

    useEffect(() => {
        if (UserDetail !== null) {
            setUserData(UserDetail.user);
            setAllDashboardData(UserDetail.AllDashboardData);
            let DashboardsDetail = UserDetail.AllDashboardData.filter((item) => item.slug === slug);
            if (DashboardsDetail.length > 0) {
                let _TableauDashboardDetail = DashboardsDetail[0];
                setTableauDashboardDetail(prevData => ({ ...prevData, ..._TableauDashboardDetail }));
                BindTableauDashboardWithAllData(_TableauDashboardDetail);
                if (UserDetail.AllFavorites.length > 0) {
                    let FavoriteDashboards = UserDetail.AllFavorites;
                    let check = FavoriteDashboards.filter((item) => item.dashboardid === _TableauDashboardDetail.dashboardid);
                    if (check.length > 0) {
                        setIsFavorite(true);
                    } else {
                        setIsFavorite(false);
                    }
                }
            } else {
                navigate("/404");
            }
        }
    }, [UserDetail, slug, hash]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const btnDownloadOptionOpen = () => {
        setIsDownloadOptionOpen(true);
    }
    const btnDownloadOptionClose = () => {
        setIsDownloadOptionOpen(false);
    }
    const btnDownloadOptionToggle = () => {
        setIsDownloadOptionOpen(!IsDownloadOptionOpen);
    }


    const BindTableauDashboard = (url) => {
        try {
            let options = vizOption;
            options.hideTabs = TableauDashboardDetail.top_tool_bar_visible;
            options.hideToolbar = UserDetail.user.admin ? false : !TableauDashboardDetail.bottom_tool_bar_visible;
            // options.hideToolbar = !TableauDashboardDetail.bottom_tool_bar_visible;
            options.device = windowSize[0] > 992 ? "desktop" : "phone";
            options.onFirstInteractive = async function () {
                setIsLoadingVisible(true);
                setWorkbook(newviz.getWorkbook())
                GetCustomViews(newviz);
            };
            setVizOption(options);
            if (viz) {
                viz.dispose();
            }
            let newviz = new tableau.Viz(refDashboard.current, url, options);
            setViz(newviz);
            setTimeout(() => { setIsLoadingVisible(true); }, 1000 * 10);
        } catch (error) {
            setIsLoadingVisible(true);
            toast("Error: Loading Tableau Dashboard", { type: 'error', autoClose: 1000 });
        }
    }

    const BindTableauDashboardWithAllData = (tableauData) => {
        try {
            setIsLoadingVisible(false);
            let options = vizOption;
            options.hideTabs = tableauData.top_tool_bar_visible;
            options.hideToolbar = UserDetail.user.admin ? false : !tableauData.bottom_tool_bar_visible;
            // options.hideToolbar = !tableauData.bottom_tool_bar_visible;
            options.device = windowSize[0] > 992 ? "desktop" : "phone";
            options.onFirstInteractive = async function () {
                setIsLoadingVisible(true);
                setWorkbook(newviz.getWorkbook())
                GetCustomViews(newviz);
            };
            if (viz) {
                viz.dispose();
            }
            let newviz = new tableau.Viz(refDashboard.current, tableauData.url, options);
            setViz(newviz);
            setTimeout(() => { setIsLoadingVisible(true); }, 1000 * 40);
        } catch (error) {

            toast("Error: Loading Tableau Dashboard", { type: 'error', autoClose: 1000 });
        }
    }


    useEffect(() => {
        if (TableauDashboardDetail !== null && Workbook !== null) {
            let tabs = Workbook.getPublishedSheetsInfo();
            setTableauTabs(tabs);
            let _ActiveSheetName = Workbook.getActiveSheet().getName();
            if (hash === "") {
                tabs.forEach((tab, index) => {
                    let name = tab.getName();
                    let url = tab.getUrl();
                    if (_ActiveSheetName === name) {
                        setTableauTabIndex(index);
                    }
                });
            } else {
                let _hash = hash.substring(1).trim();
                tabs.forEach((tab, index) => {
                    let name = tab.getName();
                    let url = tab.getUrl();
                    let _name = encodeURIComponent(name.trim());
                    if (_hash.trim().toLowerCase() === _name.trim().toLowerCase()) {
                        if (TableauTabIndex !== index) {
                            setTableauTabIndex(index);
                            BindTableauDashboard(url);
                        }
                    }
                });
            }

        }
    }, [TableauDashboardDetail, Workbook]);


    useEffect(() => {
        if (TableauTabs.length > 0 && TableauTabIndex !== -1) {
            let _TableauDashboardDetail = { ...TableauDashboardDetail };
            let _height = "80vh";
            let tab = TableauTabs[TableauTabIndex];
            let size = tab.getSize();
            if (size.behavior === "exactly") {
                _height = size.maxSize.height + 50 + "px";
                setTableauHeight(_height);
            } else {
                _height = _TableauDashboardDetail?.height + "px";
                setTableauHeight(_height);
            }
        }
    }, [TableauDashboardDetail, TableauTabs, TableauTabIndex, TableauHeight]);

    const GetCustomViews = async (tableauViz) => {
        try {
            let _CustomViews = [];
            tableauViz.getWorkbook().getCustomViewsAsync().then(function (views) {
                views.forEach(view => {
                    let name = view.getName();
                    let url_name = view.getUrl();
                    url_name = url_name.substring(url_name.lastIndexOf('/') + 1);
                    let isPublic = view.getAdvertised();
                    let def = view.getDefault();
                    let obj = {};
                    obj["name"] = name;
                    obj["url_name"] = url_name;
                    obj["isPublic"] = isPublic;
                    obj["def"] = def;
                    if (name.length > 5) {
                        if (name.substring(0, 5) === "RBSS_") {

                        } else {
                            _CustomViews.push(obj)
                        }
                    } else {
                        _CustomViews.push(obj)
                    }
                });
                setCustomViewList(_CustomViews)
            });
        } catch (error) {
            toast("Error: Loading Custome View", { type: 'error', autoClose: 1000 });
        }
    }

    const tableau_onTabChange = (e, index_new) => {
        let tabs = TableauTabs;
        tabs.forEach((tab, index) => {
            let name = tab.getName();
            let url = tab.getUrl();
            if (index_new === index) {
                let _name = encodeURIComponent(name.trim())
                navigate("/dashboard/" + slug + "#" + _name);
            }
        });
    }

    const btnIsFavorite_onClick = async () => {
        try {
            setIsFavorite(!IsFavorite);
            let favoriteObj = { user_id: UserData.user_id, dashboardid: TableauDashboardDetail.dashboardid };
            if (!IsFavorite) {
                postFavReportInsert(TableauDashboardDetail?.dashboardid, UserData.user_id).then((response) => {
                    if (response.status) {
                        toast.success(response.data);
                    }
                });
            } else {
                postFavReportDelete(TableauDashboardDetail?.dashboardid, UserData.user_id).then((response) => {
                    if (response.status) {
                        toast.success(response.data);
                    }
                });
            }
        } catch (error) {
            toast.error("Error: Favorite Dashboard", { type: 'error', autoClose: 1000 });
        }
    }

    const btnDownload_file_onClick = (name) => {
        let _Workbook = Workbook;
        if (_Workbook !== null) {
            let Worksheets = _Workbook.getActiveSheet().getWorksheets()
            Worksheets.forEach(Worksheet => {
                if (Worksheet.getName() === name) {
                    viz.showExportCrossTabDialog(Worksheet);
                    setIsDownloadFileOpen(false);
                }
            });
        }
    }

    const btnDownload_csv_onClick = async () => {
        setIsDownloadFileOpen(true)
        // Approach 1 start
        let _Workbook = Workbook;
        if (_Workbook !== null) {
            let _DownloadFileDataList = [];
            let Worksheets = _Workbook.getActiveSheet().getWorksheets()
            Worksheets.forEach(Worksheet => {
                _DownloadFileDataList.push(Worksheet.getName());
                setDownloadFileDataList(_DownloadFileDataList);
            });
        }
        // Approach 1 end

        // Approach 2 start
        // viz.showDownloadDialog();
        // Approach 2 end

        // Approach 3 start
        // viz.showExportCrossTabDialog();
        // Approach 3 end

        btnDownloadOptionClose();
    }
    const btnDownload_excel_onClick = async () => {
        viz.exportCrossTabToExcel();
        btnDownloadOptionClose();
    }
    const btnDownload_image_onClick = async () => {
        viz.showExportImageDialog();
        btnDownloadOptionClose();
    }
    const btnDownload_pdf_onClick = async () => {
        viz.showExportPDFDialog();
        btnDownloadOptionClose();
    }
    const btnDownload_ppt_onClick = async () => {
        viz.showExportPowerPointDialog();
        btnDownloadOptionClose();
    }

    const btnReloadTableau_onClick = () => {
        let tableauViz = viz;
        if (tableauViz) {
            tableauViz.revertAllAsync().then(function () {
                tableauViz.refreshDataAsync();
            });
        }
    }



    const btnApplyCustomView = () => {
        let tableauViz = viz;
        let name = SelectedExistingCustomViews;
        if (name) {
            if (name === "__Revert__") {
                RevertToOriginalCustomView(tableauViz);
            } else {
                var workbook = tableauViz.getWorkbook();
                workbook.showCustomViewAsync(name).then(function (CustomView) {
                    workbook = CustomView.getWorkbook();
                    var baseUrl = CustomView.getUrl();
                    setIsCustomViewOpen(false);
                })
            }
        }
    }

    const RevertToOriginalCustomView = (tableauViz) => {
        const vizWrapper = tableauViz.getParentElement();
        const workbook = tableauViz.getWorkbook();
        workbook.showCustomViewAsync('').then(function (CustomView) {
            toast.success("Reverted to Original Custom View");
            setIsCustomViewOpen(false)
        });
    }

    const btnDeleteCustomView = () => {
        if (window.confirm("Are you sure you want to delete?")) {
            let tableauViz = viz;
            var customViewName = SelectedExistingCustomViews;
            if (customViewName) {
                var workbook = tableauViz.getWorkbook();
                workbook.removeCustomViewAsync(customViewName).then(function () {
                    GetCustomViews(tableauViz);
                    toast("Successfully Deleted Custom View");
                    setIsCustomViewOpen(false)
                });
            }
        }
    }

    const btnPublicCustomView = () => {
        let tableauViz = viz;
        var viewName = SelectedExistingCustomViews;
        if (viewName) {
            var workbook = tableauViz.getWorkbook();
            workbook.getCustomViewsAsync().then(function (views) {
                let customViews = views;
                var foundView = null;
                for (var i = 0; i < customViews.length; i++) {
                    var view = customViews[i];
                    if (view.getName() === viewName) {
                        foundView = view;
                        if (foundView !== null) {
                            foundView.setAdvertised(true);
                            foundView.saveAsync().then(function (CustomView) {
                                toast("Successfully Public Custom View");
                                setIsCustomViewOpen(false)
                            });
                        }
                    }
                }

            })
        }
    }


    const btnCurrentCustomViewDefault = () => {
        let tableauViz = viz;
        if (!tableauViz) {
            tableauViz = viz;
        }
        var tableauWorkbook = tableauViz.getWorkbook();
        tableauWorkbook.setActiveCustomViewAsDefaultAsync().then(function () {
            toast("Successfully Added Set As Default View");
            setIsCustomViewOpen(false)
        });
    }


    const btnCreateNewCustomView = () => {
        let tableauViz = viz;
        var name = newCustomViewName;
        if (name) {
            if (!tableauViz) {
                tableauViz = viz;
            }
            var tableauWorkbook = tableauViz.getWorkbook();
            tableauWorkbook.rememberCustomViewAsync(name).then(function (CustomView) {
                CustomView.setAdvertised(true);
                CustomView.saveAsync().then(function (CustomView) {
                    GetCustomViews(tableauViz);
                    toast("Successfully Added Custom View");
                    setIsCustomViewOpen(false)
                });
            });
        }
    }

    const urlToBase64 = async (url) => {
        const base64 = await fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = () => {
                        res(reader.result);
                    }
                })
            })
    }

    useEffect(() => {
        // getReportBuilderImagesData();
        const storedData = localStorage.getItem('ReportBuilderDataList');
        if (storedData) {
            setReportBuilderDataList(JSON.parse(storedData));
        }
    }, []);

    const btnReportBuilder_onClick = () => {
        try {
            let tableauViz = viz;
            let customViewName = "RBSS_" + window.btoa(uuidv4());
            Workbook.rememberCustomViewAsync(customViewName).then(function (CustomView) {
                var customViewURL = CustomView.getUrl();
                let obj = {
                    "name": customViewName,
                    "url": customViewURL
                }
                let _ReportBuilderDataList = [];
                const storedData = localStorage.getItem('ReportBuilderDataList');
                if (storedData) {
                    _ReportBuilderDataList = JSON.parse(storedData);
                } else {
                    _ReportBuilderDataList = [];
                }
                _ReportBuilderDataList.push(obj);
                localStorage.setItem('ReportBuilderDataList', JSON.stringify(_ReportBuilderDataList));
                setReportBuilderDataList(_ReportBuilderDataList);
            });
        } catch (error) {
            toast("Error: Report Builder", { type: 'error', autoClose: 1000 });
        }
    }

    const getReportBuilderImagesData = async () => {
        getReportBuilderImages().then((response) => {
            if (response.status) {
                setReportBuilderDataList(response.data);
            } else {
                toast.error("Error: Report Builder", { type: 'error', autoClose: 1000 });
            }
        });
    }


    const btnReportBuilderCapture_onClick = () => {
        try {
            setIsReportBuilderCaptureLoadingOpen(true);
            let tableauViz = viz;
            let customViewName = "RBSS_" + window.btoa(uuidv4());
            Workbook.rememberCustomViewAsync(customViewName).then(function (CustomView) {
                var customViewURL = CustomView.getUrl();
                uploadReportBuilderImageTableau(customViewURL).then((response) => {
                    if (response.status) {
                        Workbook.removeCustomViewAsync(customViewName).then(function () {
                            getReportBuilderImagesData();
                            setIsReportBuilderCaptureLoadingOpen(false);
                        });
                    } else {
                        toast.error("Error: Report Builder", { type: 'error', autoClose: 1000 });
                    }
                });
            });
        } catch (error) {
            setIsReportBuilderCaptureLoadingOpen(false);
            toast("Error: Save Report Builder", { type: 'error', autoClose: 1000 });
        }
    }

    const btnExportReportBuilderDataList = () => {
        setIsReportBuilderOpen(false);
        window.open("/ReportBuilderDataList", "_blank");
    }

    // const btnExportReportBuilderDataList = (type) => {
    //     setIsReportBuilderCaptureLoadingOpen(true);
    //     getReportBuilder_PPT_PDF(type).then((response) => {
    //         if (response.status) {
    //             var data = new Blob([response.data], { type: 'application/' + type });
    //             var csvURL = window.URL.createObjectURL(data);
    //             let tempLink = document.createElement('a');
    //             tempLink.href = csvURL;
    //             tempLink.setAttribute('download', 'E2EVizRB' + (new Date().getTime()) + '.' + type);
    //             tempLink.click();
    //             setIsReportBuilderCaptureLoadingOpen(false);
    //         } else {
    //             toast.error("Error: Report Builder", { type: 'error', autoClose: 1000 });
    //             setIsReportBuilderCaptureLoadingOpen(false);
    //         }
    //     });
    // }

    const btnClearExportReportBuilderDataList = () => {
        if (window.confirm("Are you sure you want to clear?")) {
            const storedData = localStorage.getItem('ReportBuilderDataList');
            if (storedData) {
                let _ReportBuilderDataList = JSON.parse(storedData);
                if (_ReportBuilderDataList.length > 0) {
                    let tableauViz = viz;
                    var workbook = tableauViz.getWorkbook();
                    tableauViz.getWorkbook().getCustomViewsAsync().then(function (views) {
                        views.forEach(view => {
                            let name = view.getName();
                            if (name.length > 5) {
                                if (name.substring(0, 5) === "RBSS_") {
                                    let _ReportBuilderData = _ReportBuilderDataList.find(x => x.name === name);
                                    if (_ReportBuilderData) {
                                        workbook.removeCustomViewAsync(_ReportBuilderData.name).then(function () {
                                            GetCustomViews(tableauViz);
                                            toast.success("Report builder image " + name + " successfully deleted...");
                                            localStorage.removeItem('ReportBuilderDataList');
                                            setReportBuilderDataList([]);
                                            setIsReportBuilderOpen(false);
                                        });
                                    }
                                }
                            }
                        });
                    });
                } else {
                    toast.error("No Data Found");
                }
            }
        }
    }

    // const btnClearExportReportBuilderDataList = () => {
    //     if (window.confirm("Are you sure you want to clear?")) {
    //         setIsReportBuilderCaptureLoadingOpen(true);
    //         let _ReportBuilderDataList = [...ReportBuilderDataList];
    //         if (_ReportBuilderDataList.length > 0) {
    //             let _ReportBuilderIds = _ReportBuilderDataList.map(x => x.id);
    //             deleteReportBuilderImages({ "img_id": _ReportBuilderIds }).then((response) => {
    //                 if (response.status) {
    //                     getReportBuilderImagesData();
    //                 } else {
    //                     toast.error("Error: Clear Report Builder", { type: 'error', autoClose: 1000 });
    //                 }
    //                 setIsReportBuilderCaptureLoadingOpen(false);
    //             });
    //         }
    //     }
    // }

    return (
        <>
            <FullScreen handle={screen1} onChange={reportChange} style={
                !IsFullScreen ? { overflowY: 'auto' } : { backgoundColor: "black" }
            }>
                <TopMenu />
                <div className='mt-3'>
                    {
                        !IsLoadingVisible ?
                            <div className={st.loadingImageContainer}>
                                <img src={e2eVizLoading} alt="Loading" className={st.loadingImage} />
                            </div>
                            : <></>

                    }
                    <div style={
                        !IsLoadingVisible ? { position: 'absolute', zIndex: -1000000000000000000, width: '100%' } : {}
                    }>
                        <div className="mainContainer">
                            <div className="row mb-1">
                                <div className="col-lg-6">
                                    <span className={st.tableauDashboardName}>
                                        {TableauDashboardDetail !== null ? TableauDashboardDetail.title : ""}
                                        {/* {TableauDashboardDetail?.dashboardid} */}
                                    </span>
                                </div>
                                <div className="col-lg-6">
                                    <div className={st.tableauTopActionButtonContainer}>
                                        <div className={st.tableauTopActionButtonIconContainer}>
                                            <Tooltip placement="top" title={!IsFavorite ? "Click to Favorite" : "Click to Unfavorite"}>
                                                <IconButton onClick={btnIsFavorite_onClick}>
                                                    <FaStar style={{ display: !IsFavorite ? 'none' : 'block' }} className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} />
                                                    <FaRegStar style={{ display: IsFavorite ? 'none' : 'block' }} className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className={TableauDashboardDetail.report_builder_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                            <Tooltip placement="top" title="Report Builder">
                                                <IconButton onClick={() => setIsReportBuilderOpen(true)}>
                                                    <PiProjectorScreenChartFill className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                            <ModalPopup IsVisible={IsReportBuilderOpen} onClose={() => setIsReportBuilderOpen(false)}>
                                                <div className={st.reportBuilderRootContainer}>
                                                    <div className={st.reportBuilderContainer}>
                                                        <div className={st.reportBuilderHeader}>
                                                            <div className={st.reportBuilderHeaderTitle}><PiProjectorScreenChartFill /> Report Builder</div>
                                                            <IoIosCloseCircle onClick={() => setIsReportBuilderOpen(false)} className={st.reportBuilderHeaderCloseButton} />
                                                        </div>
                                                        <div className={st.reportBuilderBody}>
                                                            <div className={st.reportBuilderImageContainer}>
                                                                {
                                                                    ReportBuilderDataList.map((item, index) => (
                                                                        <Image key={index} src={item.url + ".png"} alt={item.url} className={st.reportBuilderImage} />
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={st.reportBuilderFooter}>
                                                            <Button variant="contained" size='small' onClick={btnReportBuilder_onClick} endIcon={<RiCamera2Fill />}>
                                                                Capture
                                                            </Button>

                                                            {ReportBuilderDataList.length > 0 ?
                                                                <div className={st.reportBuilderExportContainer}>
                                                                    <span onClick={btnExportReportBuilderDataList} className={st.btnExportReportBuilderDataList}>Export</span>
                                                                    <span onClick={btnClearExportReportBuilderDataList} className={st.btnExportReportBuilderDataList}>Clear</span>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalPopup>
                                        </div>
                                        <div className={TableauDashboardDetail.download_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                            <Tooltip placement="top" title="Download">
                                                <IconButton onClick={btnDownloadOptionToggle}>
                                                    <FaDownload className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                            <div className={st.downloadItemRootContainer} style={{ display: IsDownloadOptionOpen ? 'block' : 'none' }} onMouseLeave={() => setIsDownloadOptionOpen(false)}>
                                                <div className={st.downloadItemContainer} onClick={btnDownload_csv_onClick} style={{ display: TableauDashboardDetail.download_csv_visible ? 'flex' : 'none' }}>
                                                    <FaFileCsv className={st.downloadItemIcon} />
                                                    <div className={st.downloadItemTitle}>CSV</div>
                                                </div>
                                                <div className={st.downloadItemContainer} onClick={btnDownload_excel_onClick} style={{ display: TableauDashboardDetail.download_excel_visible ? 'flex' : 'none' }}>
                                                    <AiFillFileExcel className={st.downloadItemIcon} />
                                                    <div className={st.downloadItemTitle}>Excel</div>
                                                </div>
                                                <div className={st.downloadItemContainer} onClick={btnDownload_image_onClick} style={{ display: TableauDashboardDetail.download_image_visible ? 'flex' : 'none' }}>
                                                    <FaImage className={st.downloadItemIcon} />
                                                    <div className={st.downloadItemTitle}>Image</div>
                                                </div>
                                                <div className={st.downloadItemContainer} onClick={btnDownload_pdf_onClick} style={{ display: TableauDashboardDetail.download_pdf_visible ? 'flex' : 'none' }}>
                                                    <FaFilePdf className={st.downloadItemIcon} />
                                                    <div className={st.downloadItemTitle}>PDF</div>
                                                </div>
                                                <div className={st.downloadItemContainer} onClick={btnDownload_ppt_onClick} style={{ display: TableauDashboardDetail.download_ppt_visible ? 'flex' : 'none' }}>
                                                    <RiFilePpt2Fill className={st.downloadItemIcon} />
                                                    <div className={st.downloadItemTitle}>PPT</div>
                                                </div>
                                            </div>
                                            <ModalPopup IsVisible={IsDownloadFileOpen} onClose={() => setIsDownloadFileOpen(false)}>
                                                <div className={st.dfRootContainer}>
                                                    <div className={st.dfContainer}>
                                                        <div className={st.dfHeader}>
                                                            <div className={st.dfHeaderTitle}><FaDownload /> Download</div>
                                                            <IoIosCloseCircle onClick={() => setIsDownloadFileOpen(false)} className={st.dfHeaderCloseButton} />
                                                        </div>
                                                        <span className={st.dfnote}>Please click below item to download worksheet</span>
                                                        <div className={st.dfBody}>
                                                            <div className={st.dfDownloadContainer}>
                                                                {
                                                                    DownloadFileDataList.map((item, index) => (
                                                                        <div key={index} onClick={() => btnDownload_file_onClick(item)} className={st.dfItem}>
                                                                            <span className={st.dfItemName}>{item}</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalPopup>
                                        </div>
                                        <div className={st.tableauTopActionButtonIconContainer} >
                                            <Tooltip placement="top" title="Full Screen">
                                                <IconButton>
                                                    <AiOutlineFullscreen className={st.tableauTopActionButtonIcon} style={{ display: IsFullScreen ? 'none' : 'block' }} onClick={screen1.enter} />
                                                    <AiOutlineFullscreenExit className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} style={{ display: !IsFullScreen ? 'none' : 'block' }} onClick={screen1.exit} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        {/* <div className={st.tableauTopActionButtonIconContainer}>
                                            <Tooltip title="Reload">
                                                <IconButton onClick={() => window.location.reload(false)}>
                                                    <IoReloadCircle className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div> */}
                                        <div className={st.tableauTopActionButtonIconContainer}>
                                            <Tooltip placement="top" title="Reset">
                                                <IconButton onClick={btnReloadTableau_onClick}>
                                                    <IoReloadCircle className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className={TableauDashboardDetail.custom_views_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                            <Tooltip placement="top" title="Custom Views">
                                                <IconButton onClick={() => setIsCustomViewOpen(true)}>
                                                    <FaCamera className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                            <ModalPopup
                                                IsVisible={IsCustomViewOpen}
                                                onClose={() => setIsCustomViewOpen(false)}
                                            >
                                                <div className={st.customViewRootContainer}>
                                                    <div className={st.customViewContainer}>
                                                        <div className={st.customViewHeader}>
                                                            <div className={st.customViewHeaderTitle}><FaCamera /> Custom View</div>
                                                            <IoIosCloseCircle onClick={() => setIsCustomViewOpen(false)} className={st.customViewHeaderCloseButton} />
                                                        </div>
                                                        <div className={st.customViewBody}>
                                                            <div className={st.customViewExistingCustomViewsContainer}>
                                                                <div className={st.customViewExistingCustomViewsTitle}>Existing Custom Views</div>
                                                                <div className={st.customViewExistingCustomViewsSelectContainer}>
                                                                    <select onChange={(e) => { setSelectedExistingCustomViews(e.target.value) }} className={st.customViewExistingCustomViewsSelect}>
                                                                        <option value="" disabled selected></option>
                                                                        <option value="__Revert__">Revert to Original Custom View</option>
                                                                        {CustomViewList.map((item, index) => (<option key={index} value={item.name}>{item.name}</option>))}
                                                                    </select>
                                                                </div>
                                                                <div className={st.customViewExistingCustomViewsButtonContainer}>
                                                                    <Button variant="contained" onClick={btnApplyCustomView}
                                                                        endIcon={<FaRegSave />} size="small"
                                                                        disabled={SelectedExistingCustomViews === "" ? true : false}>
                                                                        Apply
                                                                    </Button>
                                                                    <Button variant="contained" onClick={btnDeleteCustomView}
                                                                        endIcon={<MdDelete />} size="small"
                                                                        disabled={SelectedExistingCustomViews === "" ? true : SelectedExistingCustomViews === "__Revert__" ? true : false}>
                                                                        Delete
                                                                    </Button>
                                                                    <Button variant="contained" onClick={btnPublicCustomView}
                                                                        endIcon={<FaEye />} size="small"
                                                                        disabled={SelectedExistingCustomViews === "" ? true : SelectedExistingCustomViews === "__Revert__" ? true : false}>
                                                                        Public
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className={st.customViewSetCurrentCustomViewAsDefaultContainer}>
                                                                <div className={st.customViewSetCurrentCustomViewAsDefaultTitle}>Set current custom view as default</div>
                                                                <div className={st.customViewSetCurrentCustomViewAsDefaultButtonContainer}>
                                                                    <Button variant="contained" onClick={btnCurrentCustomViewDefault}
                                                                        endIcon={<FaRegSave />} size="small">
                                                                        Set as Default
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className={st.customViewNewCustomViewNameContainer}>
                                                                <div className={st.customViewNewCustomViewNameTitle}>New Custom View Name</div>
                                                                <div className={st.customViewNewCustomViewNameInputContainer}>
                                                                    <input type="text" onChange={(e) => { setNewCustomViewName(e.target.value) }} className={st.customViewNewCustomViewNameInput} />
                                                                </div>
                                                                <div className={st.customViewNewCustomViewNameButtonContainer}>
                                                                    <Button variant="contained" onClick={btnCreateNewCustomView}
                                                                        endIcon={<FaRegSave />} size="small"
                                                                        disabled={newCustomViewName === "" ? true : false}>
                                                                        Create
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalPopup>
                                        </div>
                                        <div className={TableauDashboardDetail.share_tableau_viz_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                            <Tooltip placement="top" title="Share Tableau Viz">
                                                <IconButton onClick={() => { viz.showShareDialog(); }}>
                                                    <FaShareSquare className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className={st.tableauTopActionButtonIconContainer}>
                                            <Tooltip placement="top" title="Share Page">
                                                <IconButton onClick={() => { navigator.clipboard.writeText(window.location.href); toast("Dashboard url copied"); }}>
                                                    <IoShareSocialSharp className={st.tableauTopActionButtonIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: !TableauDashboardDetail.top_tool_bar_visible ? 'none' : 'block' }}>
                                <div className="col-lg-12">
                                    <div className={st.tabsDdlTabRootContainer}>
                                        <div className={st.ddlTabsRootContainer}>
                                            <ArrowDropDownCircleIcon className={st.ddlTabsIcon} onClick={() => setTabsDDLVisible(!TabsDDLVisible)} />
                                            {
                                                TabsDDLVisible ?
                                                    <div className={st.ddlTabsContainer} onMouseLeave={() => setTabsDDLVisible(false)}>
                                                        {TableauTabs.map((tab, index) => (
                                                            <div key={index}>
                                                                {/* <Link to={`#` + tab.getName()} className={st.ddlTabLink} onClick={() => setTabsDDLVisible(false)}>{tab.getName()}</Link> */}
                                                                <Link to={`#` + encodeURIComponent(tab.getName().trim())} className={st.ddlTabLink} onClick={() => setTabsDDLVisible(false)}>{tab.getName()}</Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className={`${st.tabsRootContainer} ${'tableTab'}`}>
                                            <Tabs activeTab={TableauTabIndex} onTabClick={(e, index) => { tableau_onTabChange(e, index); }} navBtnClassName={st.tableauTabs} hideNavBtns={false} hideNavBtnsOnMobile={false}>
                                                {TableauTabs.map((tab, index) => (
                                                    <Tab key={index} className={`${TableauTabIndex !== index ? '' : st.tableauTabItemActive} ${st.tableauTabItem}`}>
                                                        {/* <Link to={`#` + tab.getName()} className={st.tableauTabItemLink}>{tab.getName()}</Link> */}
                                                        <Link to={`#` + encodeURIComponent(tab.getName().trim())} className={st.tableauTabItemLink}>{tab.getName()}</Link>
                                                    </Tab>
                                                ))}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{ height: TableauHeight, width: '100%' }}>
                                        <div ref={refDashboard} style={{ height: '100%', width: '100%' }}></div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br />
                        </div>
                        {/* <div style={
                            !IsLoadingVisible ? { display: 'none' } : {}
                        }>
                            <DashboardComment DashboardDetail={TableauDashboardDetail} />
                        </div> */}
                    </div>
                </div>
                <ToastContainer autoClose={1000} />
            </FullScreen>
        </>
    );
};

export default TableauDashboard;
