import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { FaShareAltSquare } from 'react-icons/fa';
import { IoIosCloseCircle } from 'react-icons/io';
import { useNavigate } from "react-router-dom";
import { components } from 'react-select';
import Async from "react-select/async";
import { toast } from 'react-toastify';
import {
    getUserSearch, shareAIAssistantChats, getAIAssistantChats,
    openAIRefresh, getAIAssistantSamplePrompts
} from '../../BaseModels/MasterData';
import { delay } from '../../BaseModels/utility';
import ModalPopup from '../../CustomElements/ModalPopup/ModalPopup';
import { UDContext } from './../../../App';
import { default as botImage } from './HumBot.png';
import st from './ChatBot.module.css';
import SingleWindow from './SingleWindow/SingleWindow';
import Draggable from 'react-draggable';
import { MdDragIndicator } from "react-icons/md";
import { MdPushPin } from "react-icons/md";
import { TiPin } from "react-icons/ti";
import { GlobalContext } from '../store';


let defaultWidthOfChartBot = 450;


const optionCache = {};

const ShareModal = ({ IsShareModelOpen, setIsShareModelOpen, selectedMessages }) => {
    const [SearchUserMail, setSearchUserMail] = useState('');
    const [SelectedUserList, setSelectedUserList] = useState([]);
    const [messageSubject, setMessageSubject] = useState('');
    const [messageBody, setMessageBody] = useState('');

    const DEBOUNCE_TIMER = 350; // ms
    const fetchRef = useRef(null);

    // It debounces user input and cache it for later use
    const loadOptions = async (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        if (name.length < 2) return [];
        if (fetchRef.current) fetchRef.current.abort();
        const ac = new AbortController();
        fetchRef.current = ac;
        await delay(DEBOUNCE_TIMER);
        if (ac.signal.aborted) return [];
        if (optionCache[name]) return optionCache[name];
        const response = await getUserSearch(name, ac.signal);
        if (ac.signal.aborted) return [];
        if (!response.status && !Array.isArray(response.data)) return [];
        const options = response.data.slice(0, 15);
        options.forEach((item) => {
            item.fullName = item.givenName + ' ' + item.surname;
        });
        optionCache[name] = options;
        return options;
    }

    const onFilterTextChange = (str) => {
        const name = str.replace(/[/:?#[\]!~$&''()*,; ]/g, '');
        setSearchUserMail(name);
    };

    const [mailSending, setMailSending] = useState(false);

    const handleShareSend = () => {
        if (selectedMessages.length < 1) {
            toast.warn('Please select at least one message to share!', { autoClose: 3000 });
            return;
        }
        if (SelectedUserList.length < 1) {
            toast.warn('Please select at least one user to share!', { autoClose: 3000 });
            return;
        }
        setMailSending(true)
        const selectedUsers = SelectedUserList.map(x => x.mail);
        shareAIAssistantChats(selectedUsers, selectedMessages, messageSubject, messageBody)
            .then((response) => {
                if (response.status) {
                    toast.success('Chats shared successfully', { autoClose: 2000 });
                    setIsShareModelOpen(false);
                    setSelectedUserList([]);
                } else {
                    toast.error(response.message);
                }
            }).catch((error) => {
                toast.error('Error: Something went wrong!', { autoClose: 3000 });
            }).finally(() => {
                setMailSending(false);
            });
    }

    const CustomOption = ({ innerProps, innerRef, isDisabled, data }) =>
        isDisabled ? null : (
            <div ref={innerRef} {...innerProps} className={st.userSearchItem} >
                <span className={st.username}>{data.fullName}</span>
                <span className={st.usermail}>{data.mail}</span>
            </div>
        );

    const CustomMultiValue = (props) => (
        <Tooltip title={props.data.mail} placement='top'>
            <span>
                <components.MultiValueContainer {...props} />
            </span>
        </Tooltip>
    );

    const handleShareModalClose = () => {
        setIsShareModelOpen(false);
        setSelectedUserList([]);
    }

    return (
        <ModalPopup IsVisible={IsShareModelOpen} onClose={handleShareModalClose}>
            <div className={st.shareRootContainer}>
                <div className={st.shareHeaderContainer}>
                    <div className={st.shareImageContainer}>
                        <img src={botImage} alt='bot' className={st.shareImage} />
                    </div>
                    <div className={st.shareNameContainerTitle}>
                        {"Smart Supply Chain"}
                    </div>
                    <div className={st.shareHeaderButtonContainer} onClick={(e) => setIsShareModelOpen(false)}>
                        <IoIosCloseCircle />
                    </div>
                </div>
                <div className={st.shareBodyMailFormContainer}>
                    <div className={st.shareBodyMailFormLabel}>Share with others</div>
                    <div>
                        <div>Recipients</div>
                        <Async
                            loadOptions={loadOptions}
                            inputValue={SearchUserMail}
                            onInputChange={onFilterTextChange}
                            value={SelectedUserList}
                            onChange={setSelectedUserList}
                            getOptionValue={x => x["mail"]}
                            getOptionLabel={x => x["fullName"]}
                            isMulti
                            components={{ Option: CustomOption, MultiValueContainer: CustomMultiValue }}
                            hideSelectedOptions
                            autoFocus
                            isClearable={false}
                            captureMenuScroll
                            disabled={mailSending}
                            placeholder='e.g. John'
                        />
                    </div>
                    <div>
                        <div>Email Subject (optional)</div>
                        <TextField
                            type='text'
                            placeholder='e.g. XYZ KPI on 11/12'
                            value={messageSubject}
                            onChange={(e) => { setMessageSubject(e.target.value || '') }}
                            fullWidth
                            variant='outlined'
                            size='small'
                        />
                    </div>
                    <div>
                        <div>Email Body (optional)</div>
                        <TextField
                            type='text'
                            placeholder='e.g. Please look into the following ...'
                            value={messageBody}
                            onChange={(e) => { setMessageBody(e.target.value || '') }}
                            fullWidth
                            variant='outlined'
                            size='small'
                            multiline
                            minRows={3}
                            maxRows={3}
                        />
                    </div>
                </div>
                {/* <div className={st.searchBoxBody}>
                    <div className={st.searchBoxBodyContainer}>
                        <div className={st.searchBoxBodyContainerSearchBox}>
                        <input
                            type="text"
                            placeholder="e.g. Please look into the following ..."
                            value={messageBody}
                            onChange={(e) => { setMessageBody(e.target.value) }}
                            className={st.inputSearchBox} />
                            {
                                messageBody ? <IoIosCloseCircle className={st.cancelSearch} onClick={() => setMessageBody('')} /> : null
                            }
                        </div>
                    </div>
                </div> */}
                <div className={st.shareBodyButtonFormContainer}>
                    <Button variant='contained'
                        onClick={handleShareSend}
                        size='small'
                        color='primary'
                        disabled={mailSending}
                    >Send</Button>
                </div>
            </div>
        </ModalPopup>
    )
}

const ChatBot = ({ ChatBot_onResize }) => {
    const UserDetail = useContext(UDContext);
    const { storeData, setStoreData } = useContext(GlobalContext);

    const [chatBotOpen, setChatBotOpen] = useState(false);
    const [chatLoading, setChatLoading] = useState(false);


    const [FilteredData, setFilteredData] = useState([]);
    const [InputSearchText, setInputSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const resize_nodeRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [ChatPannelWidth, setChatPannelWidth] = useState(defaultWidthOfChartBot);
    const [IsPinned, setIsPinned] = useState(false);
    const navigate = useNavigate();


    const { AllDashboardData } = UserDetail;

    const btnOpenModel = () => {
        setShowModal(true);
    }
    const btnCloseModel = () => {
        setShowModal(false);
    }

    const inputSearchBox_onChange = (e) => {
        setInputSearchText(e.target.value);
    }

    useEffect(() => {
        setInterval(openAIRefresh, Math.floor(10 + Math.random() * 10) * 60 * 1000) // call every 10-20 minutes
        setChatLoading(true);
        Promise.all([getAIAssistantChats(), getAIAssistantSamplePrompts()])
            .then((responses) => {
                if (!responses.every(x => x.status)) {
                    toast.error('Could not fetch AI assistant data from API', { autoClose: 3000 })
                    return;
                }
                // messages are already sorted from DB by created_at, earliest first
                const chatSessions = {};
                const chatHistoryData = responses[0].data;
                chatHistoryData.forEach((item) => {
                    if (!item.status) return;
                    if (!chatSessions[item.session_id])
                        chatSessions[item.session_id] = {
                            "sessionId": item.session_id,
                            "title": item.query,
                            "createdTimestamp": item.session_created_at,
                            "messages": [],
                        }
                    chatSessions[item.session_id].messages.push({
                        "messageId": item.message_id,
                        "sessionId": item.session_id,
                        "createdTimestamp": item.created_at,
                        "sessionCreatedTimestamp": item.session_created_at,
                        "question": item.query,
                        "answer": item.response_text,
                        "sources": item.response_sources ? JSON.parse(item.response_sources) : null,
                        "sqlQuery": item.response_query,
                        "tableData": item.response_table ? JSON.parse(item.response_table) : null,
                        "shareFlag": true,
                        "feedbackFlag": item.feedback_flag,
                        "feedbackMessage": item.feedback_message,
                        "status": item.status,
                        "completed": true
                    })
                });
                const recentQuestions = responses[1].data;
                setStoreData((_state) => (
                    {
                        ..._state,
                        aiChats: {
                            ..._state.aiChats,
                            conversations: chatSessions,
                            recentQuestions: recentQuestions,
                        }
                    }
                ));
                setChatLoading(false);
            });
        defaultBotPinned();
    }, []);

    const defaultBotPinned = () => {
        try {
            if (localStorage.getItem('BotPinned')) {
                localStorage.getItem('BotPinned') === "Y" ? setIsPinned(true) : setIsPinned(false);
            } else {
                localStorage.setItem('BotPinned', "N");
                setIsPinned(false);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (Array.isArray(AllDashboardData) && AllDashboardData.length > 0) {
            if (InputSearchText.trim()) {
                const toSearch = InputSearchText.trim().toLowerCase();
                const results = [];
                AllDashboardData.forEach(element => {
                    for (const key in element) {
                        if (element[key]) {
                            if (String(element[key]).toLowerCase().includes(toSearch)) {
                                results.push(element);
                            }
                        }
                    }
                });
                let _FilteredData = results.filter((obj, index) => {
                    return index === results.findIndex(o => obj.dashboardid === o.dashboardid);
                });
                setFilteredData(_FilteredData);
            } else {
                setInputSearchText('');
                setFilteredData([]);
            }
        } else {
            setInputSearchText('');
            setFilteredData([]);
        }
    }, [InputSearchText, AllDashboardData]);

    const btnsearchResultItem_onClick = (slug) => {
        navigate("/dashboard/" + slug);
        setShowModal(false);
        setInputSearchText('');
        setFilteredData([]);
    }


    const btnShowBot_onClick = () => {
        setChatBotOpen(true);
        setPosition({ x: 0, y: 0 });
        setChatPannelWidth(defaultWidthOfChartBot);
        ChatBot_onResize(defaultWidthOfChartBot);
    }
    const btnCloseBot_onClick = () => {
        setChatBotOpen(false);
        setPosition({ x: 0, y: 0 });
        setChatPannelWidth(0);
        ChatBot_onResize(0);
    }

    /* Share modal variables */
    const [IsShareModelOpen, setIsShareModelOpen] = useState(false);
    const [selectedMessages, setSelectedMessages] = useState([]);

    const handleShareClick = () => {
        const messageIds = storeData.aiChats.selectedSessionId ?
            storeData.aiChats.conversations[storeData.aiChats.selectedSessionId].messages
                .filter(x => x.shareFlag).map(x => x.messageId)
            : [];
        if (messageIds.length < 1) {
            toast.warn('Please select at least one message to share!', { autoClose: 3000 });
            return;
        }
        setSelectedMessages(messageIds);
        setIsShareModelOpen(true);
    }


    const handleStart = (e, data) => {
        const parent = document.getElementsByTagName("body")[0];
        var iDiv = document.createElement('div');
        iDiv.id = 'block';
        iDiv.className = 'resizeAIAssistantbg';
        parent.insertBefore(iDiv, parent.firstChild);
    };

    const handleDrag = (e, data) => {
        setPosition({ x: data.x, y: data.y });
        let _ChatPannelWidth = ChatPannelWidth;
        if (data.x < 0) {
            _ChatPannelWidth = defaultWidthOfChartBot + (data.x * -1);
        } else {
            _ChatPannelWidth = defaultWidthOfChartBot;
        }
        setChatPannelWidth(_ChatPannelWidth);
        //ChatBot_onResize(_ChatPannelWidth);
    };

    const handleStop = (e, data) => {
        let _position = { ...position };
        if (data.x > 0) {
            _position.x = 0;
        }
        if (data.y > 0) {
            _position.y = 0;
        }
        setPosition(_position);
        var paras = document.getElementsByClassName('resizeAIAssistantbg');
        while (paras[0]) {
            paras[0].parentNode.removeChild(paras[0]);
        }
        let _ChatPannelWidth = ChatPannelWidth;
        ChatBot_onResize(_ChatPannelWidth);
    };

    const btnPinnedBot_onClick = () => {
        let _IsPinned = IsPinned;
        _IsPinned = !_IsPinned;
        setIsPinned(_IsPinned);
        localStorage.setItem('BotPinned', _IsPinned ? "Y" : "N");
        let _ChatPannelWidth = ChatPannelWidth;
        ChatBot_onResize(_ChatPannelWidth);
    }

    return <>
        {!chatLoading &&
            <>
                {
                    !chatBotOpen ?
                        <div ref={resize_nodeRef} className={`${st.chatBotImageRootContainer} ${st.zoomIn}`} onClick={btnShowBot_onClick}>
                            <div className={`${st.chatBotImageContainer}`} onClick={btnShowBot_onClick}>
                                <img src={botImage} alt='bot' className={st.botImage} />
                            </div>
                        </div>
                        :
                        <Draggable axis="x"
                            handle=".handle_ai_assistant_draggable_container"
                            onStart={handleStart}
                            onDrag={handleDrag}
                            onStop={handleStop}
                            position={position}
                            nodeRef={resize_nodeRef}
                        >
                            <div ref={resize_nodeRef}
                                className={`${st.chatBotMessageRootContainer} ${st.slideInRight} d-flex flex-column`}
                                style={{ width: ChatPannelWidth }}>
                                <div className={`${'handle_ai_assistant_draggable_container'} ${st.resizeWindowIcon}`}>
                                    <MdDragIndicator />
                                </div>
                                <div className={`${st.chatBotHeaderRootContainer}`}>
                                    <img src={botImage} alt='bot' className={st.botHeaderImage} />
                                    <div className={`${st.chatBotHeaderName}`}>
                                        {"Smart Supply Chain"}
                                    </div>
                                    <div className={`${st.chatBotHeaderIconContainer}`}>
                                        <div className={`${st.chatBotHeaderIcon}`}>
                                            <Tooltip title="Report Search" placement="top">
                                                <SearchIcon fontSize="inherit" onClick={btnOpenModel} />
                                            </Tooltip>
                                        </div>
                                        <div className={`${st.chatBotHeaderIcon}`} onClick={handleShareClick}>
                                            <FaShareAltSquare />
                                        </div>
                                        {
                                            IsPinned ?
                                                <div className={`${st.chatBotHeaderIcon}`} onClick={btnPinnedBot_onClick}>
                                                    <MdPushPin />
                                                </div>
                                                :
                                                <div className={`${st.chatBotHeaderIcon}`} onClick={btnPinnedBot_onClick}>
                                                    <TiPin />
                                                </div>
                                        }
                                        <div className={`${st.chatBotHeaderIcon}`} onClick={btnCloseBot_onClick}>
                                            <IoIosCloseCircle />
                                        </div>
                                    </div>

                                </div>
                                <div className={st.chatWindowContainer} >
                                    <SingleWindow />
                                </div>
                            </div>
                        </Draggable>
                }
                <ShareModal IsShareModelOpen={IsShareModelOpen} setIsShareModelOpen={setIsShareModelOpen} selectedMessages={selectedMessages} />
                <ModalPopup IsVisible={showModal} onClose={btnCloseModel}>
                    <div className={st.searchBoxRootContainer} onMouseLeave={() => setInputSearchText("")}>
                        <div className={st.searchBoxContainer}>
                            <div className={st.searchBoxHeader}>
                                <div className={st.searchBoxHeaderTitle}>
                                    <SearchIcon /> Report Search
                                </div>
                                <IoIosCloseCircle onClick={btnCloseModel} className={st.searchBoxHeaderCloseButton} />
                            </div>
                            <div className={st.searchBoxBody}>
                                <div className={st.searchBoxBodyContainer}>
                                    <div className={st.searchBoxBodyContainerSearchBox}>
                                        <input type="text" placeholder="Search"
                                            value={InputSearchText}
                                            onChange={(e) => { inputSearchBox_onChange(e) }}
                                            className={st.inputSearchBox} />
                                        {
                                            InputSearchText !== '' ? <IoIosCloseCircle className={st.cancelSearch} onClick={() => setInputSearchText("")} /> : null
                                        }
                                        <div className={st.inputSearchBoxIconContainer}>
                                            <SearchIcon fontSize="inherit" className={st.inputSearchBoxIcon} />
                                        </div>
                                    </div>
                                </div>
                                <div className={st.searchResultContainer}>
                                    <div className={st.searchResultWrapper}>
                                        {
                                            FilteredData.length > 0 ?
                                                FilteredData.map((data, index) => (
                                                    <div key={index} className={st.searchResultItem} onClick={() => { btnsearchResultItem_onClick(data.slug) }}>
                                                        <div className={st.searchResultItemTitle}>{data.title}</div>
                                                        <div className={st.searchResultItemDetail}>
                                                            <div className={st.searchResultItemDetailText}>{data.page + " > " + data.section}</div>
                                                        </div>
                                                    </div>
                                                ))
                                                : InputSearchText !== '' ?
                                                    (<div className={st.searchResultItem}>
                                                        <div className={st.searchResultItemTitle}>No Result Found</div>
                                                    </div>) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalPopup>
            </>
        }
    </>
};

export default ChatBot;